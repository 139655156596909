import React, { useState } from 'react';

function QuizGroupCard(props) {
    const quiz = props.quiz ? props.quiz : '';
    const result = props.result ? props.result : { corect: 0, wrong: 0, left: 0 };
    const [checkedBox, setCheckedBox] = useState();

    const setValue = (event, index) => {
        if (event.target.checked) {
            setCheckedBox(index);
        } else {
            const newCheckedBox = checkedBox.filter((el) => !el.index);
            setCheckedBox(newCheckedBox);
        }
    };
    return (
        <div className="col-12 col-lg-8">
            {quiz && (
                <>
                    <div className="card-group">
                        <div className="card">
                            <div className="card-body text-center">
                                <h4 className="mb-0">
                                    <strong>{props.total}</strong>
                                </h4>
                                <small className="text-muted-light">TOTAL</small>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body text-center">
                                <h4 className="text-success mb-0">
                                    <strong>{result.corect}</strong>
                                </h4>
                                <small className="text-muted-light">CORRECT</small>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body text-center">
                                <h4 className="text-danger mb-0">
                                    <strong>{result.wrong}</strong>
                                </h4>
                                <small className="text-muted-light">WRONG</small>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body text-center">
                                <h4 className="text-primary mb-0">
                                    <strong>{result.left}</strong>
                                </h4>
                                <small className="text-muted-light">LEFT</small>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <div className="media align-items-center">
                                <div className="media-left">
                                    <h4 className="mb-0">
                                        <strong>#{props.index + 1}</strong>
                                    </h4>
                                </div>
                                <div className="media-body">
                                    <h4 className="card-title">{quiz.title}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {quiz.answers.map((data, index) => {
                                return (
                                    <div className="form-group" key={index}>
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                id={'checkbox' + index}
                                                type="checkbox"
                                                className="custom-control-input"
                                                checked={checkedBox == index ? true : false}
                                                onChange={(e) => setValue(e, index)}
                                            />
                                            <label htmlFor={'checkbox' + index} className="custom-control-label">
                                                {data.title}
                                            </label>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="card-footer">
                            <span className="btn btn-white" onClick={props.handleClickLeft}>
                                Skip
                            </span>
                            <span
                                className="btn btn-success float-right"
                                onClick={() => {
                                    props.handleClickSubmit(checkedBox);
                                    setCheckedBox();
                                }}
                            >
                                Submit <i className="material-icons btn__icon--right">send</i>
                            </span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default QuizGroupCard;

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sidebarData } from '~/assets/data/sidebarData';
import { sidebarInstructorData } from '~/assets/data/sidebarInstructorData';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import 'bootstrap';
import { resizeCustom } from '~/assets/js/sidebar';
import { useAuth } from '~/routes/AuthProvider';

function Sidebar(props) {
    const { register, handleSubmit } = useForm();

    const dataState = useSelector((state) => state.user);

    const pathname = useLocation().pathname;

    const [sidebar, setSidebar] = useState([]);

    const [dataUser, setDataUser] = useState('');

    const auth = useAuth();

    const navigate = useNavigate();

    const [reload, setReload] = useState(false);

    const isDashboard = localStorage.getItem('isDashBoard');

    useEffect(() => {
        setDataUser(dataState.current);
        checkRole();
        resizeCustom('default-drawer', props.showSideBar);
    }, [props, dataState, isDashboard]);

    const onSubmit = (data) => {
        localStorage.setItem('isDashBoard', 'Student');
        navigate('/student-browse-courses?search=' + data.search, { replace: true });
    };

    const handleClick = (e) => {
        if (!e.target.querySelector('.sidebar-menu-toggle-icon').classList.contains('active')) {
            e.target.querySelector('.sidebar-menu-toggle-icon').classList.add('active');
            e.target.parentElement.classList.add('open');
        } else {
            e.target.querySelector('.sidebar-menu-toggle-icon').classList.remove('active');
            e.target.parentElement.classList.remove('open');
        }
    };

    const handleClick2 = (data) => {
        if (data === "Student" || data === "Instructor") {
            localStorage.setItem("isDashBoard", data);
        }
    };

    const checkRole = () => {
        let isStudent = false;
        let isInstructor = false;
        if (auth.user) {
            auth.user.roles.map((item) => {
                if (item.name === "student") isStudent = true;
                else if (item.name === "Instructor") isInstructor = true;

            })
            if (isInstructor) {
                const isDashBoard = localStorage.getItem('isDashBoard');
                if (isDashBoard === null) {
                    setSidebar(sidebarInstructorData.filter((item) => item.label !== "Student"))
                } else if (isDashBoard && isDashBoard === "Instructor") {
                    setSidebar(sidebarInstructorData.filter((item) => item.label !== "Student"))
                } else if (isDashBoard && isDashBoard === "Student") {
                    setSidebar(sidebarInstructorData.filter((item) => item.label !== "Instructor"))
                }
            }
            if (isInstructor && !isStudent) {
                setSidebar(sidebarInstructorData.filter((item) => item.label !== "Student"))
            }
            if (isStudent && !isInstructor) {
                setSidebar(sidebarData)
            }
        }
    }

    return (
        <div
            className="mdk-drawer js-mdk-drawer d-block d-lg-none"
            id="default-drawer"
            data-position="left"
            data-align="start"
            data-persistent
            data-opened
            style={{ zIndex: '1000' }}
        >
            <div className="mdk-drawer__scrim" />
            <div className="mdk-drawer__content">
                <div className="sidebar sidebar-left sidebar-dark bg-dark o-hidden ps ps--active-y">
                    <div className="sidebar-p-y">
                        {/* Search */}
                        <div className="mx-2 mb-2 bg-dark">
                            <form
                                className="search-form d-flex d-md-none rounded-lg bg-dark border-primary"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <input
                                    type="text"
                                    className="form-control text-light"
                                    placeholder="Search"
                                    {...register('search')}
                                />
                                <button className="btn" type="submit">
                                    <i className="bx bx-search font-size-24pt sidebar-menu-icon"></i>
                                </button>
                            </form>
                        </div>
                        {/* // END Search */}
                        {sidebar.map((data, key) => {
                            return (
                                <div key={key}>
                                    <div key={key} className="sidebar-heading">
                                        {data.label}
                                    </div>
                                    <ul className="sidebar-menu sm-active-button-bg">
                                        {data.level1.map((dataLevel1, key) => {
                                            return (
                                                <li
                                                    className={
                                                        'sidebar-menu-item ' +
                                                        (pathname === dataLevel1.url ? 'active' : '')
                                                    }
                                                    key={key}
                                                    onClick={() => handleClick2(dataLevel1.label)}
                                                >
                                                    {dataLevel1.hasOwnProperty('level2') ? (
                                                        <a
                                                            className="sidebar-menu-button"
                                                            data-toggle="collapse"
                                                            href="#account_menu"
                                                            role="button"
                                                            aria-expanded="false"
                                                            aria-controls="account_menu"
                                                            onClick={handleClick}
                                                        >
                                                            <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">
                                                                {dataLevel1.icon}
                                                            </i>

                                                            {dataLevel1.label}
                                                            <span
                                                                className="ml-auto sidebar-menu-toggle-icon"
                                                                style={{ pointerEvents: 'none' }}
                                                            ></span>
                                                        </a>
                                                    ) : (
                                                        <Link
                                                            className={
                                                                'sidebar-menu-button sidebar-js-collapse ' +
                                                                (pathname === dataLevel1.url ? 'active' : '')
                                                            }
                                                            data-toggle="collapse"
                                                            to={dataLevel1.hasOwnProperty('url') ? dataLevel1.url : ''}
                                                            style={{
                                                                pointerEvents: dataLevel1.hasOwnProperty('url')
                                                                    ? ''
                                                                    : 'none',
                                                            }}
                                                        >
                                                            <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">
                                                                {dataLevel1.icon}
                                                            </i>
                                                            {dataLevel1.label}
                                                        </Link>
                                                    )}

                                                    {!dataLevel1.hasOwnProperty('level2') ? (
                                                        ''
                                                    ) : (
                                                        <ul
                                                            className="sidebar-submenu sm-indent collapse"
                                                            id="account_menu"
                                                            style={{ paddingInlineStart: '0' }}
                                                        >
                                                            {dataLevel1.level2.map((dataLevel2, key) => {
                                                                return (
                                                                    <li
                                                                        className={
                                                                            'sidebar-menu-item ' +
                                                                            (pathname === dataLevel2.url
                                                                                ? 'active '
                                                                                : '')
                                                                        }
                                                                        key={key}
                                                                    >
                                                                        <Link
                                                                            className="sidebar-menu-button"
                                                                            to={dataLevel2.url}
                                                                        >
                                                                            <span className="sidebar-menu-text">
                                                                                {dataLevel2.label}
                                                                            </span>
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Sidebar;

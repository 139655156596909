import React from 'react';

function Input({ label, type, name, placeholder, register, required, icon, valid, message }) {
    return (
        <div className="form-group">
            {label ? (
                <label className="form-label" htmlFor={name}>
                    {label}
                </label>
            ) : ''}
            <div className="input-group input-group-merge rounded">
                <input
                    type={type}
                    className={'form-control form-control-prepended ' + valid}
                    placeholder={placeholder}
                    {...register(name, { required })}
                />
                <div className="input-group-prepend">
                    <div className="input-group-text">
                        <span className={icon} />
                    </div>
                </div>
                <div className="invalid-feedback">{message}</div>
            </div>
        </div>
    );
}

export default Input;

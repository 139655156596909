import React, { useEffect, useState } from 'react';
import logo from '~/assets/images/logo/LMS-Icon-white.svg';
import badge from '~/assets/images/logo/Experium.png';
import DropDown from '~/components/header/DropDown';
import Navbar from '~/components/header/Navbar';
import Notifications from '~/components/header/Notifications';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MiniCart from '~/components/header/MiniCart';
import { useAuth } from '~/routes/AuthProvider';
import avatar from '~/assets/images/people/50/no-data.png';
import { useForm } from 'react-hook-form';
import { RedirectHome } from '~/helpers/Hook';

function Header(props) {
    const { register, handleSubmit } = useForm();

    const navigate = useNavigate();

    const dataState = useSelector((state) => state.user);

    const [dataUser, setDataUser] = useState('');

    const auth = useAuth();

    const [isStudent, setIsStudent] = useState(false);

    const [isInstructor, setIsInstructor] = useState(false);

    const [reload, setReload] = useState(false);

    const isDashboard = localStorage.getItem('isDashBoard');

    useEffect(() => {
        setDataUser(dataState.current);
        checkRole();
    }, [dataState, isDashboard]);

    const handleClickRole = (data) => {
        if (data === 'Student' || data === 'Instructor') {
            localStorage.setItem('isDashBoard', data);
            setReload(!reload);
        }
    };

    const onSubmit = (data) => {
        localStorage.setItem('isDashBoard', 'Student');
        navigate('/student-browse-courses?search=' + data.search, { replace: true });
    };

    const checkRole = () => {
        if (auth.user) {
            auth.user.roles.map((item) => {
                if (item.name === 'student') setIsStudent(true);
                else if (item.name === 'Instructor') setIsInstructor(true);
            });
        }
    };

    return (
        <div
            id="header"
            className="mdk-header js-mdk-header mb-0 position-sticky top-0"
            data-retarget-mouse-scroll
            data-domfactory-upgraded="mdk-header"
            style={{ paddingTop: '64px', transform: 'translate3d(0px, 0px, 0px)' }}
        >
            <div id="header" className="mdk-header js-mdk-header mb-0">
                {/* Header */}
                <div className="mdk-header__content z-3">
                    {/* Navbar */}
                    <nav
                        id="default-navbar"
                        className="navbar navbar-expand navbar-dark bg-primary m-0 mdk-header--fixed px-1 px-lg-3"
                    >
                        <div className="container-fluid align-items-center">
                            <div className="navbar-toggler d-block d-lg-none mr-1 mr-lg-2">
                                <i className="bx bx-menu font-size-24pt" onClick={props.handleClick}></i>
                            </div>
                            {/* Brand */}
                            <Link to={RedirectHome()} className="text-decoration-none mr-0 mr-lg-2">
                                <span className="navbar-nav align-items-center">
                                    {/* <img src={logo} className="mr-0 mr-lg-2 avatar-sm d-none d-lg-block" alt="LMS logo" /> */}
                                    <img src={badge} className="avatar-img" alt="LMS badge" style={{ width: '125px' }} />
                                </span>
                            </Link>
                            {/* Navbar Items */}
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item mx-3">
                                    <Link to={RedirectHome()} className="nav-link d-none d-lg-block">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item dropdown mx-3">
                                    {isStudent && isInstructor ? (
                                        <>
                                            <a
                                                className="nav-link btn-flush dropdown-toggle d-none d-lg-block"
                                                type="button"
                                                data-toggle="dropdown"
                                                id="role"
                                            >
                                                {isDashboard ? isDashboard : 'Student'}
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-left" aria-labelledby="role">
                                                {isDashboard === 'Instructor' ? (
                                                    <Link
                                                        to="/student-dashboard"
                                                        className="dropdown-item "
                                                        role="button"
                                                        onClick={() => handleClickRole('Student')}
                                                    >
                                                        Student
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        to="/instructor-dashboard"
                                                        className="dropdown-item"
                                                        role="button"
                                                        onClick={() => handleClickRole('Instructor')}
                                                    >
                                                        Instructor
                                                    </Link>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        ' '
                                    )}
                                </li>
                            </ul>
                            <div className="flex" />
                            {/* Search */}
                            <form
                                className="search-form d-none d-md-flex mr-5 rounded-pill w-25 bg-dark-blue"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    {...register('search')}
                                />
                                <button className="btn" type="submit">
                                    <i className="bx bx-search font-size-24pt"></i>
                                </button>
                            </form>
                            {/* // END Search */}
                            {/* Menu */}
                            <ul className="nav navbar-nav flex-nowrap">
                                <li className="nav-item dropdown dropdown-notifications dropdown-menu-sm-full">
                                    <MiniCart />
                                </li>
                                {/* Notifications dropdown */}
                                <li className="nav-item dropdown dropdown-notifications dropdown-menu-sm-full ml-0">
                                    <button
                                        className="nav-link btn-flush dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        data-dropdown-disable-document-scroll
                                        data-caret="false"
                                    >
                                        <i className="bx bx-bell font-size-24pt"></i>
                                        <span className="badge badge-notifications badge-danger">2</span>
                                    </button>
                                    <Notifications />
                                </li>
                                {/* // END Notifications dropdown */}
                                {/* User dropdown */}
                                <li className="nav-item dropdown ml-0">
                                    <span className="nav-link dropdown" data-toggle="dropdown" role="button">
                                        {dataUser.image ? (
                                            <img
                                                src={dataUser.image}
                                                alt="Avatar"
                                                className="rounded-circle avatar-sm"
                                            />
                                        ) : (
                                            <img
                                                src={avatar}
                                                alt="Avatar"
                                                className="rounded-circle avatar-sm"
                                            />
                                        )}
                                    </span>
                                    <DropDown />
                                </li>
                                {/* // END User dropdown */}
                            </ul>
                            {/* // END Menu */}
                        </div>
                    </nav>
                    {/* // END Navbar */}
                </div>
                <div className="w-100 border-bottom shadow mb-0 d-none d-lg-flex bg-light">
                    <Navbar />
                </div>
                {/* // END Header */}
            </div>
        </div>
    );
}

export default Header;

import Breadcrumb from '~/components/Breadcrumb';
import Helmet from '~/components/Helmet';
import { unwrapResult } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getStudentResult } from '~/features/ManageQuizzes/quizzesManageSlice';
import { getQuizzes } from '~/features/ManageQuizzes/quizzesManageSlice';
import PageTitle from '~/components/page/PageTitle';

export default function QuizReviewDetail(props) {
    const params = useParams();

    const [quiz, setQuiz] = useState();

    const [result, setResult] = useState();

    const dispatch = useDispatch();

    const fetchResult = async (data) => {
        const response = await dispatch(getStudentResult(data));
        const responseData = unwrapResult(response);
        setResult(responseData.data);
    };

    const fetchQuiz = async (data) => {
        const response = await dispatch(getQuizzes(data));
        const responseData = unwrapResult(response);
        setQuiz(responseData.data);
    };

    useEffect(() => {
        if (params.quizid) {
            const data = {
                id: params.quizid,
            };
            fetchResult(data);
        }
        if (params.slug) {
            const data = {
                slug: params.slug,
            };
            fetchQuiz(data);
        }
    }, []);

    const page = [{ title: 'Quiz Review', active: true }];

    return (
        result && (
            <Helmet title="Quiz Review">
                <Breadcrumb page={page} />
                <PageTitle title={'Quiz Review'} />
                <div className="media flex-wrap align-items-center mb-headings">
                    <div className="media-left">
                        <img src={quiz && quiz.quizzes.filter((data) => data.id == params.quizid).map((item) => (item.image_thumbnail))} alt="" className="avatar-img rounded avatar-lg" />
                    </div>
                    <div className="media-body mb-3 mb-sm-0">
                        <h1 className="h2 mb-0">{quiz && quiz.quizzes.filter((data) => data.id == params.quizid).map((item) => (item.title))}</h1>
                    </div>
                </div>

                <h4>Review History</h4>
                <div className="table-responsive">
                    <table className="table table-sm table-middle table-striped">
                        <thead>
                            <tr className='table-secondary'>
                                <th className="pl-2" style={{ width: '120px' }}>Avatar</th>
                                <th className="pl-2">Student</th>
                                <th className="pl-2">Score</th>
                                <th className="pl-2" style={{ width: '220px' }}>Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            {result.length ?
                                result.map((item, index) => {
                                    const result = JSON.parse(item.result)
                                    return (
                                        <tr key={index}>
                                            <td className='pl-2'>
                                                <span className="badge px-0"><img className='avatar avatar-xl' src={item.image_user} /></span>
                                            </td>
                                            <td>
                                                <span>{item.user_name}</span>
                                            </td>
                                            <td className="text-left pl-2">
                                                <span className="text-primary">{result.point}</span>
                                            </td>

                                            <td className='d-flex flex-column justify-content-center' key={index}>
                                                <div className='d-flex align-items-center'><span className="text-success" style={{ width: "70px" }}>Correct:</span><span className="text-success" style={{ width: "20px" }}>{result.corect}</span><i className='bx bxs-check-square text-success font-size-24pt' /></div>
                                                <div className='d-flex align-items-center'><span className="text-danger" style={{ width: "70px" }}>Wrong:</span><span className="text-danger" style={{ width: "20px" }}>{result.wrong}</span><i className='bx bxs-x-square text-danger font-size-24pt' /> </div>
                                                <div className='d-flex align-items-center'><span className="text-warning" style={{ width: "70px" }}>Left:</span><span className="text-warning" style={{ width: "20px" }}>{result.left}</span><i className='bx bxs-error text-warning font-size-24pt' /></div>
                                            </td>

                                        </tr>
                                    );
                                }) :
                                <tr>
                                    <td colSpan={4} className="text-primary text-center">
                                        <span>This quiz doesn't have any results yet!</span>
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
            </Helmet>
        )
    );
}

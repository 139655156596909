import React, { useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { loginWithGoogle } from '~/features/Auth/userSlice';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import 'sweetalert2/src/sweetalert2.scss';
import { useAuth } from '~/routes/AuthProvider';
import { unwrapResult } from '@reduxjs/toolkit';

function LoginGoogle({ isLoginSignUp }) {
    const location = useLocation();
    
    const dispatch = useDispatch();
    
    const navigate = useNavigate();
    
    const auth = useAuth();
    
    const handlerRedirect = () => {
        const params = new URLSearchParams(location.search);
        const redirectTo = params.get('redirect');
        navigate(redirectTo == null ? "/" : `/student-view-course/${redirectTo}`, { replace: true });
    }
    
    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: '',
            });
        }

        gapi.load('client:auth2', start);
    });
    
    const responseGoogle = async (response) => {
        if (!!response.profileObj) {
            try {
                const resp = await dispatch(loginWithGoogle(response.profileObj));
                const responseData = unwrapResult(resp);
                if (responseData.success) {
                    auth.signin(responseData.data);
                    handlerRedirect()
                } else {
                    console.log('error');
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log(response);
        }
    };
    
    return (
        <div className="login-google">
            <div className="mb-0" style={{ top: '5px' }}>
                {isLoginSignUp && (
                    <div className="card-header text-center border-bottom-0">
                        <GoogleLogin
                            className="btn btn-primary btn-block btn-rounded p-1"
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Continue with Google"
                            render={renderProps => (
                                <button onClick={renderProps.onClick} className="btn btn-primary btn-block btn-rounded p-2"><i className='bx bxl-google mr-2 font-size-24pt'></i>Continue with Google</button>
                            )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            plugin_name="streamy"
                        />
                        <div className="page-separator">
                            <div className="page-separator__text">or</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default LoginGoogle;
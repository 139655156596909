import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import logo from '~/assets/images/logo/LMS-Icon.svg';
import badge from '~/assets/images/logo/Experium.png';

function HeaderLanding(props) {
    const { register, handleSubmit } = useForm();

    const navigate = useNavigate();

    const onSubmit = (data) => {
        navigate('/landing?search=' + data.search, { replace: true });
    };
    return (
        <header id="header" className="js-mdk-header mb-0 sticky-top bg-light">
            <div className="mdk-header__content py-2 shadow rounded">
                <div className="container-fluid d-flex flex-row align-items-center justify-content-around justify-content-md-between px-2 px-sm-3 px-md-4 px-lg-4 px-xl-5">
                    <Link to="/" className="text-decoration-none">
                        <span className="navbar-brand mr-1">
                            {/* <img src={logo} className="svg mr-0 mr-lg-1" alt="LMS" style={{ height: '50px' }} /> */}
                            <img src={badge} className="svg mr-1" alt="LMS" style={{ height: '45px' }} />
                        </span>
                    </Link>
                    <form className="search-form d-none d-sm-flex rounded-pill w-25" onSubmit={handleSubmit(onSubmit)}>
                        <input type="text" className="form-control" placeholder="Search" {...register('search')} />
                        <button className="btn" type="submit">
                            <i className="material-icons font-size-24pt">search</i>
                        </button>
                    </form>
                    {/* <div className="search-button d-block d-sm-none ml-2 w-50">
                        <a type="button" className="search-toggle" data-selector="#header">
                            <i className="material-icons font-size-24pt">search</i>
                        </a>
                    </div>
                    <form action="" className="search-box d-block d-sm-none">
                        <input
                            type="text"
                            className="text search-input border-primary rounded w-75 bg-white px-2 d-block mx-auto mt-2"
                            placeholder="Search"
                        />
                    </form> */}
                    <div className="d-flex flex-row flex-sm-row align-items-start align-items-sm-center">
                        <Link
                            to="/signup"
                            className="btn btn-outline-primary rounded-pill mx-1 mr-sm-3 text-decoration-none"
                            style={{ width: '79px' }}
                        >
                            Signup
                        </Link>

                        <Link
                            to="/login"
                            className="btn btn-outline-primary rounded-pill text-decoration-none"
                            style={{ width: '79px' }}
                        >
                            Login
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderLanding;

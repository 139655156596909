import React from 'react';

function SortFilter(props) {

    const data = props.data ? props.data : '';

    const threadClass = props.threadClass ? props.threadClass : '';

    const earningClass = props.earningClass ? props.earningClass : '';

    return (
        <>
            {threadClass ? (
                <thead className={threadClass}>
                    <tr>
                        <th colSpan={2}>
                            {data.map((data, key) => {
                                return (
                                    <span key={key}>
                                        <a href="#" className="sort mr-2" data-sort={data.sort}>{data.text}</a>
                                    </span>
                                )
                            }
                            )}
                        </th>
                    </tr>
                </thead>
            ) : earningClass ? (
                <thead className={earningClass}>
                    <tr className="text-uppercase small">
                        <th>
                            <a href="#"
                                className="sort"
                                data-sort="js-lists-values-course">Course</a>
                        </th>
                        <th className="text-center"
                            style={{ width: "130px" }}>
                            <a href="#"
                                className="sort"
                                data-sort="js-lists-values-fees">Fees</a>
                        </th>
                        <th className="text-center"
                            style={{ width: "130px" }}>
                            <a href="#"
                                className="sort"
                                data-sort="js-lists-values-revenue">Revenue</a>
                        </th>
                    </tr>
                </thead>
            ) : (
                <div className="w-auto ml-sm-auto table d-flex align-items-center mb-0" >
                    <small className="text-muted text-uppercase mr-1 d-none d-sm-block">Sort by</small>
                    {data.map((data, key) => {
                        return (
                            <span key={key}>
                                <a href="#" className="sort small text-uppercase ml-2" data-sort={data.sort}>{data.text}</a>
                            </span>
                        )
                    }
                    )}
                </div>
            )}
        </>
    );
}

export default SortFilter;

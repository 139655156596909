import React from 'react';
import PropTypes from 'prop-types'

function Helmet(props) {
    document.title = 'Learning Management System - ' + props.title

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {props.children}
        </>
    );
    Helmet.propTypes = {
        title: PropTypes.string.isRequired
    }
}

export default Helmet;
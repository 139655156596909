import React from 'react';

function Card(props) {
    const classHeader = props.classHeader ? props.classHeader : '';

    const cardClass = props.cardClass ? props.cardClass : '';

    const classBody = props.classBody ? props.classBody : '';

    const classFooter = props.classFooter ? props.classFooter : '';

    const cardNav = props.cardNav ? props.cardNav : '';

    const classTitle = props.classTitle ? props.classTitle : '';

    return (
        <div className={'card ' + cardClass}>
            {classHeader ? (
                <div className={'card-header ' + classHeader}>
                    {props.bestScore ? <div className="h2 mb-0 mr-3 text-primary">{props.bestScore}</div> : null}

                    {!props.rating ? (
                        <div className="flex">
                            <h4 className={"card-title " + classTitle}>{props.title}</h4>
                            <p className="card-subtitle">{props.subtitle}</p>
                        </div>
                    ) : (
                        <div className="flex">
                            <h4 className={"card-title " + classTitle}>{props.title}</h4>
                            <div className="rating">{props.subtitle}</div>
                        </div>
                    )}
                    {props.rightComponet ? props.rightComponet : ''}
                </div>
            ) : (
                ''
            )}

            {cardNav ? (
                <ul className="nav nav-tabs nav-tabs-card">
                    {cardNav.map((item, key) => {
                        return (
                            <li key={key} className="nav-item">
                                <a
                                    className={'nav-link ' + (item.link == 'first' ? 'active' : '')}
                                    href={'#' + item.link}
                                    data-toggle="tab"
                                >
                                    {item.title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                ''
            )}

            {props.children ? <div className={'tab-content card-body ' + classBody}>{props.children}</div> : ''}
            {classFooter ? <div className={'card-footer ' + classFooter}>{props.footer}</div> : ''}
        </div>
    );
}

export default Card;

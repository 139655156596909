import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Alert from '~/components/Alert';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import Card from '~/components/Card';
import { checkoutCart, destroyItem, fetchCart, storeCart } from '~/features/Cart/cartSlice';

function Cart(props) {
    const dataState = useSelector((state) => state.cart);

    const [dataItems, setDataItems] = useState();

    const [message, setMessage] = useState({ message: '', success: true });

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        setDataItems(dataState.current);
        if (dataState.current && dataState.current.length === 0) {
            setMessage({ message: 'You have no items in your shopping cart', success: false });
        }
    }, [dataState]);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 3000,
    });

    const deleteItem = async (data) => {
        const response = await dispatch(destroyItem({ course_id: data }));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            const response = await dispatch(fetchCart());
            const responseData = unwrapResult(response);
            swalWithBootstrapButtons.fire({
                title: 'Delete Item Successfully!',
                icon: 'success',
            });
        } else {
            swalWithBootstrapButtons.fire({
                title: 'Error! An error occurred. Please try again later!',
                icon: 'error',
            });
        }
    };

    const checkout = async (data) => {
        let courseId = [];
        data.map((val) => {
            courseId.push(val.id);
        });
        if (courseId.length) {
            const response = await dispatch(checkoutCart(courseId));
            const responseData = unwrapResult(response);
            if (responseData.success) {
                const response = await dispatch(storeCart(data));
                const responseDataCart = unwrapResult(response);
                if (responseDataCart.success) {
                    localStorage.setItem('cartData', JSON.stringify(responseDataCart.data));
                    const cartData = await dispatch(fetchCart());
                    swalWithBootstrapButtons.fire({
                        title: 'Thank you. Your order has been received',
                        icon: 'success',
                    })
                    navigate('/student-my-course', { replace: true });
                }
            } else {
                swalWithBootstrapButtons.fire({
                    title: 'Error! An error occurred. Please try again later!',
                    icon: 'error',
                });
            }
        }
    };

    const total = (items) => {
        items.reduce((acc, cur) => {
            return acc + cur.price, 0;
        });
    }

    return (
        <div className="container-fluid page__container">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/student-dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Cart</li>
            </ol>
            <h1 className="h2">Shopping Cart</h1>
            <div className="row">
                <div className="col-lg-8">
                    <div className="card table-responsive overflow-auto scroll">
                        <table className="table table-wrap mb-0">
                            <thead>
                                <tr>
                                    <td className='text-left h4 pl-3'>Item</td>
                                    <td className="text-right h4">Price</td>
                                </tr>
                            </thead>
                            <tbody>
                                {dataItems &&
                                    dataItems.map((data, key) => {
                                        const path = `/student-view-course/${data.slug}`;
                                        return (
                                            <tr key={key}>
                                                <td className='d-flex justify-content-start align-items-center'>
                                                    <div className="d-flex align-items-center">
                                                        <Link to={path} className="avatar avatar-4by3 avatar-xl mr-3">
                                                            <img
                                                                src={data.image_thumbnail}
                                                                alt="Caps Item"
                                                                className="avatar-img rounded border-2" />
                                                        </Link>
                                                        <div className="media-body">
                                                            <Link to={path} className="text-body font-size-20pt">
                                                                <strong>{data.name}</strong>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="d-flex flex-column align-items-end justify-content-between">
                                                        <span className="text-muted pb-4" role="button" onClick={() => deleteItem(data.id)}>
                                                            <i className="material-icons font-size-24pt">close</i>
                                                        </span>
                                                        <span className="font-size-24pt text-primary font-weight-bold">
                                                            ${data.price}USD
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        <Alert
                            display={message.message !== ''}
                            message={message.message}
                            alertClass={message.success ? 'success mb-0' : 'warning mb-0'}
                        />
                    </div>
                </div>
                <div className="col-lg-4">
                    <Card
                        classHeader="cart-summary pr-3 py-0"
                        classBody="cart-summary-body"
                        classTitle="mb-1 py-2"
                        title="Cart"
                    >
                        <div className="table-responsive border-0">
                            <div className="d-flex justify-content-between pr-3">
                                <p className=" font-size-16pt">
                                    Subtotal
                                </p>
                                <p className="text-primary font-size-16pt">
                                    ${dataItems && dataItems.length > 0 ? dataItems.reduce((sum, i) => (
                                        sum += i.price
                                    ), 0) + 'USD' : '0USD'}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between pr-3">
                                <p className=" font-size-16pt">
                                    Discount
                                </p>
                                <p className="text-primary font-size-16pt">
                                    $0USD
                                </p>
                            </div>
                            <table className="table table-wrap mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-left px-0">
                                            <div className="d-flex align-items-start">
                                                <div className="media-body">
                                                    <a href="#" className="text-body font-size-20pt">
                                                        <strong>Total</strong>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            <div className="d-flex flex-column align-items-end justify-content-between">
                                                <span className="font-size-24pt text-primary">
                                                    ${dataItems && dataItems.length > 0 ? dataItems.reduce((sum, i) => (
                                                        sum += i.price
                                                    ), 0) + 'USD' : '0USD'}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Card>
                    <div className="row d-flex justify-content-between justify-content-lg-center align-items-center">
                        <div className="form-group mb-0 w-75 col-6">
                            <Link to="/student-browse-courses" className="btn btn-outline-primary px-0 py-sm-2 w-100 rounded-pill">
                                Back to Courses
                            </Link>
                        </div>
                        <div className="form-group mb-0 w-75 col-6">
                            {dataItems && (
                                <span onClick={() => checkout(dataItems)} className={`btn btn-primary px-1 py-sm-2 w-100 rounded-pill${dataItems.length === 0 ? ' disabled' : ''}`}>
                                    <i className='bx bx-credit-card font-size-24pt'></i>&nbsp;Pay Now
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Cart;

export const RadarOptions = {
    elements: {
        point: {
            pointStyle: 'circle',
            radius: 4,
            hoverRadius: 5,
            borderWidth: 2,
        },
    },
    scale: {
        ticks: {
            display: true,
            beginAtZero: true,
            maxTicksLimit: 4,
        },
        gridLines: {
            color: 'red',
        },
        angleLines: {
            color: 'red',
        },
        pointLabels: {
            fontSize: 20,
            fontWeight: 900,
        },
    },
    plugins: {
        legend: {
            display: false,
            labels: {
                color: 'rgb(255, 99, 132)',
            },
        },
    },
};

export const dataRadar = {
    labels: ['JavaScript', 'HTML', 'Flinto', 'Vue.js', 'Sketch', 'Priciple', 'CSS', 'Angular'],
    datasets: [
        {
            label: 'Experience IQ',
            data: [30, 35, 33, 32, 31, 30, 28, 36],
            pointHoverBorderColor: '#2196f3',
            backgroundColor: '#f5f6f700',
            borderColor: '#2196f3',
            pointBackgroundColor: '#2196f3',
            poingBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            borderJoinStyle: 'bevel',
            lineTension: 0.1,
        },
    ],
};

export const dataLine = {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
        {
            label: 'Experience IQ',
            backgroundColor: 'rgba(34, 202, 236, .2)',
            borderColor: '#2196f3',
            pointBackgroundColor: '#2196f3',
            poingBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#2196f3',
            borderJoinStyle: 'bevel',
            lineTension: 0.2,
            data: [30, 35, 33, 31, 34, 36, 34, 32],
        },
    ],
};

export const lineOptions = {
    scales: {},
    plugins: {
        legend: {
            display: false,
            labels: {
                color: 'rgb(255, 99, 132)',
            },
        },
    },
};

export const dataBar = {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
        {
            label: 'Earnings',
            backgroundColor: '#2196f3',
            borderColor: '#2196f3',
            pointBackgroundColor: '#2196f3',
            pointHoverBorderColor: '#2196f3',
            borderJoinStyle: 'bevel',
            data: [20, 15, 40, 31, 25, 36, 42, 28],
            barPercentage: 0.5,
            barThickness: 20,
            borderRadius: 10,
        },
    ],
};

export const dataEarning = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            label: 'Sales',
            backgroundColor: '#2196f3',
            borderColor: '#2196f3',
            pointBackgroundColor: '#2196f3',
            pointHoverBorderColor: '#2196f3',
            borderJoinStyle: 'bevel',
            data: [20, 15, 40, 31, 25, 36, 42, 28, 20, 15, 40, 31],
            barPercentage: 0.5,
            barThickness: 20,
            borderRadius: 10,
        },
    ],
};

export const EarningsOptions = {
    barRoundness: 1.2,
    scales: {},
    plugins: {
        legend: {
            display: true,
            labels: {
                color: 'rgb(0, 0, 0)',
            },
        },
    },
};

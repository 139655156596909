import React from 'react';

function Alert(props) {
    const message = props.message;

    const alertClass = props.alertClass ? 'alert-' + props.alertClass : 'alert-info';

    const messageClass = props.messageClass ? props.messageClass : '';

    const icon = props.icon ? props.icon : '';

    const display = props.display;

    return (
        display && (
            <div className={'alert ' + alertClass} role="alert">

                <div className={`text-black-70 ${messageClass}`}>
                    {icon && <><i className={`bx ${icon}`} />&nbsp;</>}
                    {message}
                </div>
            </div>
        )
    );
}

export default Alert;

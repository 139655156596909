import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '~/components/Breadcrumb';
import Helmet from '~/components/Helmet';
import PageTitle from '~/components/page/PageTitle';
import { getQuizzes, destroyQuiz } from '~/features/ManageQuizzes/quizzesManageSlice';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

function QuizDetail() {
    const params = useParams();

    const [quiz, setQuiz] = useState('');

    const [fetch, setFetch] = useState(false);

    const dispatch = useDispatch();

    const fetchQuiz = async (data) => {
        const response = await dispatch(getQuizzes(data));
        const responseData = unwrapResult(response);
        setQuiz(responseData.data);
    };

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    });

    const swalCustomButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mr-2',
            denyButton: 'btn btn-danger mr-2',
        },
        buttonsStyling: false,
    });

    useEffect(() => {
        if (params.slug) {
            const data = {
                slug: params.slug,
            };
            fetchQuiz(data);
        }
    }, [fetch, params]);

    const deleteQuiz = (data) => {
        swalCustomButtons
            .fire({
                title: 'Do you want to delete the quiz?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const response = await dispatch(destroyQuiz(data));
                    const responseData = unwrapResult(response);
                    if (responseData.success) {
                        setFetch(!fetch);
                        swalWithBootstrapButtons.fire({
                            title: 'Delete Successfully!',
                            icon: 'success',
                        });
                    } else {
                        swalWithBootstrapButtons.fire({
                            title: responseData.message,
                            icon: 'error',
                        });
                    }
                }
            });
    };

    const timeElapsed = Date.now();

    const today = new Date(timeElapsed);

    const page = [{ title: 'Quiz Detail', active: true }];

    return (
        <Helmet title="Quiz Detail">
            <Breadcrumb page={page} />
            <PageTitle
                title={'Quiz Detail'}
                button={'Add Quiz'}
                buttonLink={'quiz-add'} />

            {quiz && quiz ? (
                <>
                    <div className="media mb-headings align-items-center">
                        <div className="media-left">
                            <img src={quiz.image_thumbnail} alt="" width={80} className="rounded" />
                        </div>
                        <div className="media-body">
                            <h1 className="h2">{quiz.name}</h1>
                            <p className="text-muted">
                                submitted on {quiz.created_at ? quiz.created_at : today.toDateString()}
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header align-items-center d-flex justify-content-between">
                            <h4 className="card-title mb-0 col-4">Quizzes</h4>
                            <div className="text-right col-4 text-center">
                                <h4 className="card-title mb-0">Edit</h4>
                            </div>
                        </div>

                        <ul className="list-group list-group-fit mb-0">
                            {quiz.quizzes && quiz.quizzes.map((data, key) => {
                                return (
                                    <li
                                        className='list-group-item'
                                        key={key}
                                    >
                                        <div className="media">
                                            <div className="media-body col-4 d-flex p-0">
                                                <div className="media-left">
                                                    <div className="text-muted-light">{key + 1}</div>
                                                </div>
                                                <Link to={`${data.id}`} className="text-decoration-none">
                                                    {data.title}
                                                </Link>
                                            </div>
                                            <div className='col-4' />
                                            <div className="media-left col-4 text-center">
                                                <Link to={`${data.id}`} className="text-decoration-none btn py-1 px-2 btn-outline-primary mr-0 mr-md-1 mb-1 mb-md-0" style={{ width: "90px" }}>
                                                    Edit Quiz
                                                </Link>
                                                <span role="button" className="text-decoration-none btn p-1 btn-outline-danger"
                                                    onClick={() => {
                                                        deleteQuiz(data.id);
                                                    }} style={{ width: "90px" }}>
                                                    Delete Quiz
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            ) : ''
            }
        </Helmet>
    );
}
export default QuizDetail;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '~/assets/css/custom.css';
import { RedirectHome } from '~/helpers/Hook';

class CountDown extends Component {
    render() {
        return (
            <section className="page_404">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-sm-offset-1 text-center">
                            <h1 className="text-center font-size-64pt mb-0">404</h1>
                            <div className="four_zero_four_bg" />
                            <div className="contant_box_404">
                                <p className="h2 mb-0">Look like you're lost</p>
                                <p className="">The page you are looking for is not avaible!</p>
                                <Link to={RedirectHome()} className="btn btn-primary text-uppercase">
                                    Go to Home
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default CountDown;

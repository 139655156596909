import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { dataBar, EarningsOptions, } from '~/assets/data/chartData';
import { dataSales, dataMessages, dataSoftFilter } from '~/assets/data/InstructorDashData';
import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import BarChart from '~/components/chart/BarChart';
import FormReply from '~/components/FormReply';
import Helmet from '~/components/Helmet';
import SortFilter from '~/components/filter/SortFilter';
import PageTitle from '~/components/page/PageTitle';
import { useDispatch } from 'react-redux';
import { show } from '~/features/ManageCourses/coursesManageSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAuth } from '~/routes/AuthProvider';

function InstuctorDashboard(props) {

    const dispatch = useDispatch();

    const auth = useAuth();

    const [courses, setCourses] = useState();

    const page = [{ title: 'Instructor Dashboard', active: true }];

    const fetchCourses = async (dataSearch) => {
        if (auth.token) {
            let isInstructor = false;
            auth.user.roles.map((data, key) => {
                if (data.name == 'Instructor') {
                    isInstructor = true;
                }
            });
            if (isInstructor) {
                const response = await dispatch(show(dataSearch));
                const responseData = unwrapResult(response);
                const course = responseData.data;
                if (responseData.success) if (course.data.length > 0) setCourses(course.data.slice(0, 5));
            }
        }
    };

    useEffect(() => {
        if (auth.token) {
            fetchCourses();
        }
    }, [auth.token]);

    return (
        <Helmet title="Instructor Dashboard">
            <Breadcrumb page={page} />
            <PageTitle title={'Instructor Dashboard'} />
            <div className="row">
                <div className="col-lg-6">
                    <Card
                        title={'Earnings'}
                        subtitle={'Last 7 days'}
                        classHeader={'d-flex align-items-center'}
                        rightComponet={
                            <Link to="/instructor-earning" className="btn btn-sm btn-primary">
                                <i className="material-icons">trending_up</i>
                            </Link>
                        }
                    >
                        <BarChart chartData={dataBar} BarOptions={EarningsOptions} />
                    </Card>
                    <Card
                        title={'Transactions'}
                        subtitle={'Latest transactions'}
                        classHeader={'d-flex align-items-center'}
                        rightComponet={
                            <Link to="/instructor-statement" className="btn btn-sm btn-primary">
                                <i className="material-icons">receipt</i>
                            </Link>
                        }
                        classBody={'p-0 table-responsive'}
                    >
                        <table className="table table-nowrap mb-0">
                            <SortFilter threadClass={'thead-light'} data={dataSoftFilter} />
                            <tbody className="list">
                                {courses && courses.map((item, index) => {
                                    const path = '/instructor-courses-manager/' + item.slug;
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="media align-items-center">
                                                    <Link
                                                        to={path}
                                                        className="avatar avatar-4by3 avatar-sm mr-3"
                                                    >
                                                        <img
                                                            src={item.image_thumbnail}
                                                            alt="course"
                                                            className="avatar-img rounded"
                                                        />
                                                    </Link>
                                                    <div className="media-body d-flex flex-column">
                                                        <Link
                                                            to={path}
                                                            className="text-body js-lists-values-course"
                                                        >
                                                            <strong>{item.name}</strong>
                                                        </Link>
                                                        <small className="text-muted mr-1">
                                                            Invoice
                                                            <span
                                                                style={{ color: 'inherit' }}
                                                                className="js-lists-values-document mb-0"
                                                            >
                                                                #8736
                                                            </span>{' '}
                                                            - $
                                                            <span className="js-lists-values-amount">{item.price}</span>{' '}
                                                            USD
                                                        </small>
                                                        <small className="text-muted text-uppercase js-lists-values-date d-block d-sm-none mt-2 mt-lg-0">
                                                            14 Nov 2018
                                                        </small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-right">
                                                <small className="text-muted text-uppercase js-lists-values-date d-none d-sm-block">
                                                    14 Nov 2018
                                                </small>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Card>
                </div>
                <div className="col-lg-6">
                    <Card
                        title={'Sales today'}
                        subtitle={'by course'}
                        classHeader={'d-flex align-items-center'}
                        rightComponet={
                            <Link to="/instructor-earning" className="btn btn-sm btn-primary">
                                Earnings
                            </Link>
                        }
                        classBody={'p-0'}
                    >
                        <ul className="list-group list-group-fit mb-0">
                            {dataSales.map((item, index) => {
                                return (
                                    <li className="list-group-item" key={index}>
                                        <div className="media align-items-center">
                                            <div className="media-body">
                                                <Link to="/instructor-course-edit" className="text-body">
                                                    <strong>{item.courses}</strong>
                                                </Link>
                                            </div>
                                            <div className="media-right">
                                                <div className="text-center">
                                                    <span
                                                        className={
                                                            'badge badge-pill ' +
                                                            (item.earning <= 10
                                                                ? 'badge-danger'
                                                                : item.earning <= 14
                                                                    ? 'badge-warning'
                                                                    : item.earning <= 30
                                                                        ? 'badge-primary'
                                                                        : 'badge-success')
                                                        }
                                                    >
                                                        {item.earning}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </Card>
                    <Card
                        title={'Comments'}
                        subtitle={'latest comments'}
                        classHeader={'d-flex align-items-center'}
                        rightComponet={
                            <>
                                <a href="#" className="btn btn-outline-primary btn-sm mx-1">
                                    <i className="material-icons">keyboard_arrow_left</i>
                                </a>
                                <a href="#" className="btn btn-outline-primary btn-sm">
                                    <i className="material-icons">keyboard_arrow_right</i>
                                </a>
                            </>
                        }
                        classFooter={'m-0'}
                        footer={<FormReply />}
                    >
                        {dataMessages.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className="media">
                                        <div className="media-left">
                                            <a href="#" className="avatar avatar-sm">
                                                <img
                                                    src={item.imageStudent}
                                                    alt="image-user"
                                                    className="avatar-img rounded-circle"
                                                />
                                            </a>
                                        </div>
                                        <div className="media-body d-flex flex-column">
                                            <div className="d-flex align-items-center">
                                                <Link to="/instructor-profile" className="text-body">
                                                    <strong>{item.nameStudent}</strong>
                                                </Link>
                                                <small className="ml-auto text-muted">{item.messageDate}</small>
                                                <br />
                                            </div>
                                            <span className="text-muted">
                                                on{' '}
                                                <Link
                                                    to="/instructor-course-edit"
                                                    className="text-black-50"
                                                    style={{ textDecoration: 'underline' }}
                                                >
                                                    {item.onCourses}
                                                </Link>
                                            </span>
                                            <p className="mt-1 mb-0 text-black-70">{item.message}</p>
                                        </div>
                                    </div>
                                    <div className="media ml-sm-32pt mt-3 mb-0 border rounded p-3 bg-light">
                                        <div className="media-left">
                                            <a href="#" className="avatar avatar-sm">
                                                <img
                                                    src={item.imageInstructor}
                                                    alt="Guy"
                                                    className="avatar-img rounded-circle"
                                                />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <div className="d-flex align-items-center">
                                                <Link to="/instructor-profile" className="text-body">
                                                    <strong>{item.nameInstructor}</strong>
                                                </Link>
                                                <small className="ml-auto text-muted">
                                                    {item.messageDateInstructor}
                                                </small>
                                            </div>
                                            <p className="mt-1 mb-0 text-black-70">{item.messageInstructor}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Card>
                </div>
            </div>
        </Helmet>
    );
}

export default InstuctorDashboard;

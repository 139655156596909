import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import Helmet from '~/components/Helmet';
import Pagination from '~/components/Pagination';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import PageTitle from '~/components/page/PageTitle';
import { dataEarning, EarningsOptions } from '~/assets/data/chartData';
import { useState, useEffect } from 'react';
import { show } from '~/features/ManageCourses/coursesManageSlice';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import BarChart from '~/components/chart/BarChart';
import SortFilter from '~/components/filter/SortFilter';

export default function Earning() {
    const dispatch = useDispatch();

    const page = [{ title: 'Earnings', active: true }];

    const [courses, setCourses] = useState();

    const location = useLocation();

    const navigate = useNavigate();

    const fetchData = async (data) => {
        const response = await dispatch(show(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            setCourses(responseData.data);
        }
    }

    const handleSubmitUrl = (url) => {
        let paramString = url.split('=')[1];

        const params = new URLSearchParams(location.search);

        params.set('page', paramString);

        navigate('?' + params.toString(), { replace: true });
    };

    useEffect(() => {
        fetchData(location.search);
    }, [location.search]);

    return (
        <Helmet title="Earnings">
            <Breadcrumb page={page} />
            <PageTitle
                title={'Earnings'}
            />
            <div className="row">
                <div className="col-lg-9 container-fluid">
                    <Card>
                        <div className="d-flex form-inline mb-4">
                            <div className="form-group mr-12pt">
                                <select className="custom-select">
                                    <option defaultValue>2021</option>
                                    <option value={1}>2020</option>
                                    <option value={2}>2019</option>
                                    <option value={3}>2018</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <div className="chart-legend m-0 justify-content-start" id="earningsChartLegend" />
                            </div>
                        </div>
                        <BarChart chartData={dataEarning} BarOptions={EarningsOptions} />
                    </Card>
                    <Card
                        classHeader="earning"
                        classBody={'p-0'}
                        title={`Total ${'$19,239'}`}
                    >
                        <div className="table-responsive">
                            <table className="table table-nowrap mb-0">
                                <SortFilter earningClass={'thead-light'} />
                                <tbody className="list">
                                    {courses && courses.data.map((item, index) => {
                                        const path = '/instructor-courses-manager/' + item.slug;
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div className="media align-items-center">
                                                        <Link
                                                            to={path}
                                                            className="avatar avatar-4by3 avatar-sm mr-3"
                                                        >
                                                            <img
                                                                src={item.image_thumbnail}
                                                                alt="course"
                                                                className="avatar-img rounded"
                                                            />
                                                        </Link>
                                                        <div className="media-body">
                                                            <Link
                                                                to={path}
                                                                className="text-body js-lists-values-course"
                                                            >
                                                                <strong>{item.name}</strong>
                                                            </Link>
                                                            <div className="text-muted small">34 Sales</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center text-black-70">
                                                    $<span className="js-lists-values-fees">120</span> USD
                                                </td>
                                                <td className="text-center text-black-70">
                                                    $<span className="js-lists-values-revenue">8,737</span> USD
                                                </td>
                                            </tr>

                                        );
                                    })}
                                </tbody>
                            </table>
                        </div >
                    </Card>
                </div >
                <div className="col-lg-9 container-fluid">
                    {courses && (
                        <Pagination
                            links={courses.links}
                            handleSubmitUrl={handleSubmitUrl}
                            currentPage={courses.current_page}
                        />
                    )}
                </div>
            </div >
        </Helmet >
    )
}
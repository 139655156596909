import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import Helmet from '~/components/Helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { getQuiz } from '~/features/Quiz/quizSlice';
import { updateQuiz } from '~/features/ManageQuizzes/quizzesManageSlice';
import { useEffect, useState } from 'react';
import 'react-nestable/dist/styles/index.css';
import PageTitle from '~/components/page/PageTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaAddQuiz } from '~/helpers/validationSchema';
import noData from '~/assets/images/courses/no-data.jpg';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export default function QuizAdd(props) {
    const dataState = useSelector((state) => state.categories);

    const location = useLocation();

    const navigate = useNavigate();

    const params = useParams();

    const [quiz, setQuiz] = useState();

    const [thumbnail, setThumbnail] = useState('');

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schemaAddQuiz),
    });

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    });

    const fetchQuiz = async (data) => {
        const response = await dispatch(getQuiz(data));
        const responseData = unwrapResult(response);
        setQuiz(responseData.data);
    };

    useEffect(() => {
        if (params.slug) {
            const data = {
                slug: params.slug,
            };
            fetchQuiz(data);
        }
    }, []);

    register('image', {
        onChange: (e) => {
            setThumbnail(URL.createObjectURL(e.target.files[0]));
        },
    });

    const onSubmit = async (data) => {
        const response = await dispatch(updateQuiz(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            swalWithBootstrapButtons.fire({
                title: 'Save Successfully!',
                icon: 'success',
            });
            const pathname = location.pathname.replace('quiz-add', responseData.data);
            navigate(pathname, { replace: true });
        } else {
            swalWithBootstrapButtons.fire({
                title: responseData.message,
                icon: 'error',
            });
        }
    };

    const setTimeQuiz = (e) => {
        setValue('time', e.target.value * 60, { shouldValidate: true });
    };

    const page = [{ title: 'Add Quiz', active: true }];

    return (
        <Helmet title="Edit Quiz">
            <Breadcrumb page={page} />
            <PageTitle title={'Add Quiz'} button={'save'} handleSubmit={handleSubmit(onSubmit)} submit={true} />
            <Card classHeader={' '} title={'Basic'}>
                {quiz && (
                    <form action="#">
                        <div className="form-group row">
                            <div className="col-md-2" />
                            <label className="col-sm-3 col-md-2 col-form-label form-label" htmlFor="quiz_title">
                                Title
                            </label>
                            <div className="col-sm-9 col-md-6">
                                <input
                                    type="text"
                                    id="quiz_title"
                                    className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                    placeholder="Title"
                                    {...register('title')}
                                />
                                <div className="invalid-feedback">{errors.title?.message}</div>
                                <input
                                    hidden
                                    type="number"
                                    className="form-control"
                                    defaultValue={quiz.id}
                                    {...register('course')}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-2" />
                            <label htmlFor="quiz_image" className="col-sm-3 col-md-2 col-form-label form-label">
                                Quiz Image:
                            </label>
                            <div className="col-sm-9 col-md-6">
                                <p>
                                    <img
                                        src={thumbnail ? thumbnail : noData}
                                        alt="quiz caps"
                                        width={150}
                                        className="rounded"
                                    />
                                </p>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        id="quiz_image"
                                        className={`custom-file-input ${errors.image ? 'is-invalid' : ''}`}
                                        accept="image/*"
                                        {...register('image')}
                                    />
                                    <label htmlFor="quiz_image" className="custom-file-label">
                                        Choose file
                                    </label>
                                    <div className="invalid-feedback">{errors.image?.message}</div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-2" />
                            <label htmlFor="cmn-toggle" className="col-sm-3 col-md-2 col-form-label form-label">
                                Time
                            </label>
                            <div className="col-sm-9 col-md-6">
                                <div className="form-inline">
                                    <div className="form-group mr-2">
                                        <input
                                            type="number"
                                            className={`form-control text-center ${errors.time ? 'is-invalid' : ''}`}
                                            style={{ width: '90px' }}
                                            onChange={(e) => setTimeQuiz(e)}
                                        />
                                        <span className="ml-3"> Minutes</span>
                                        <div className="invalid-feedback">{errors.time?.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Card>
        </Helmet>
    );
}

import React from 'react';

import { useNavigate, Outlet } from 'react-router-dom';

import { useAuth } from '~/routes/AuthProvider';

const RequiredAuthRole = ({ children }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!auth.user) {
            navigate('/landing', { replace: true });
        }
        let isInstructor = false;
        if (auth.user && auth.user.roles) {
            auth.user.roles.map((item) => {
                if (item.name === 'Instructor') isInstructor = true;
            });
        }
        if (!isInstructor) {
            navigate('/student-dashboard', { replace: true });
        }
    }, []);

    return children ? children : <Outlet />;
};

export default RequiredAuthRole;

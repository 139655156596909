import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import quizzesManageApi from '~/api/quizzesManageApi';


export const getQuizzes = createAsyncThunk('quizzesManage/show', async (payload) => {
    try {
        const response = await quizzesManageApi.getQuizzes(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const showQuiz = createAsyncThunk('quizzesManage/show', async (payload) => {
    try {
        const response = await quizzesManageApi.showQuiz(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const destroyQuiz = createAsyncThunk('quizzesManage/destroyquiz', async (payload) => {
    try {
        const response = await quizzesManageApi.destroyQuiz(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const destroyQuestion = createAsyncThunk('quizzesManage/destroyquestion', async (payload) => {
    try {
        const response = await quizzesManageApi.destroyQuestion(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const reIndexQuestion = createAsyncThunk('quizzesManage/reindexquestion', async (payload) => {
    try {
        const response = await quizzesManageApi.reIndexQuestion(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const getStudentResult = createAsyncThunk('quizzesManage/getstudentresult', async (payload) => {
    try {
        const response = await quizzesManageApi.getStudentResult(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const updateQuestion = createAsyncThunk('quizzesManage/updatequestion', async (payload) => {
    try {
        const response = await quizzesManageApi.updateQuestion(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const updateQuiz = createAsyncThunk('quizzesManage/updatequiz', async (payload) => {
    try {
        const response = await quizzesManageApi.updateQuiz(payload);
        return response;
    } catch (e) {
        return e;
    }
});

const quizzesManageSlice = createSlice({
    name: 'quizzesManage',
    initialState: {
        current: null,
        settings: {},
        loading: false,
    },
    reducers: {},
    extraReducers: {
    },
});

const { actions, reducer } = quizzesManageSlice;

export default reducer;

import React, { Component, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function EditorComponent({ description, setValue }) {
    function uploadPlugin(editor) {
        editor.editing.view.change((writer) => {
            writer.setStyle('height', '200px', editor.editing.view.document.getRoot());
        });
    }
    const configCKEditor = {
        extraPlugins: [uploadPlugin],
    };

    return (
        <div className="edit">
            <CKEditor
                editor={ClassicEditor}
                data={description}
                config={configCKEditor}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('description', data);
                }}
            />
        </div>
    );
}

import React from 'react';
import { Link } from 'react-router-dom';

function Notifications(props) {
    return (
        <div className="dropdown-menu dropdown-menu-right">
            <div data-perfect-scrollbar className="position-relative">
                <div className="dropdown-header">
                    <strong>System notifications</strong>
                </div>
                <div className="list-group list-group-flush mb-0">
                    <Link to="/" className="list-group-item list-group-item-action border-left-3 border-left-danger">
                        <span className="d-flex align-items-center mb-1">
                            <small className="text-muted">3 minutes ago</small>
                        </span>
                        <span className="d-flex">
                            <span className="avatar avatar-xs mr-2">
                                <span className="avatar-title rounded-circle bg-light">
                                    <i className="material-icons font-size-16pt text-danger">account_circle</i>
                                </span>
                            </span>
                            <span className="flex d-flex flex-column">
                                <span className="text-black-70">
                                    Your profile information has not been synced correctly.
                                </span>
                            </span>
                        </span>
                    </Link>
                    <Link to="/" className="list-group-item list-group-item-action">
                        <span className="d-flex align-items-center mb-1">
                            <small className="text-muted">5 hours ago</small>
                        </span>
                        <span className="d-flex">
                            <span className="avatar avatar-xs mr-2">
                                <span className="avatar-title rounded-circle bg-light">
                                    <i className="material-icons font-size-16pt text-success">group_add</i>
                                </span>
                            </span>
                            <span className="flex d-flex flex-column">
                                <strong>Adrian. D</strong>
                                <span className="text-black-70">Wants to join your private group.</span>
                            </span>
                        </span>
                    </Link>
                    <Link to="/" className="list-group-item list-group-item-action">
                        <span className="d-flex align-items-center mb-1">
                            <small className="text-muted">1 day ago</small>
                        </span>
                        <span className="d-flex">
                            <span className="avatar avatar-xs mr-2">
                                <span className="avatar-title rounded-circle bg-light">
                                    <i className="material-icons font-size-16pt text-warning">storage</i>
                                </span>
                            </span>
                            <span className="flex d-flex flex-column">
                                <span className="text-black-70">Your deploy was successful.</span>
                            </span>
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Notifications;

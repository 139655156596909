import * as Yup from 'yup';

export const schemaRegister = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email is invalid').max(255),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(40, 'Password must not exceed 40 characters'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
}).required();

export const schemaLogin = Yup.object({
    email: Yup.string().required('Email is required').email('Please provide a valid Email').max(255),
    password: Yup.string()
        .required('Please provide a valid Password')
        .min(8, 'Password must be at least 8 characters')
        .max(40, 'Password must not exceed 40 characters'),
}).required();

export const schemaForgotPass = Yup.object({
    email: Yup.string().required('Email is required').email('Email is invalid').max(255),
}).required();

export const schemaResetPass = Yup.object({
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(40, 'Password must not exceed 40 characters'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    token: Yup.string(),
}).required();

export const schemaQuiz = Yup.object({
    answer: Yup.bool(),
});

const FILE_SIZE = 5 * 1024 * 1024;

export const schemaEditCourse = Yup.object().shape({
    name: Yup.string().required('Course title is required'),
    video: Yup.string().required('Course URL Video is required').url('Please provide a valid URL'),
    category: Yup.string().required('Please select at least one category'),
    price: Yup.number().required('Course price is required').test('Is positive?', 'The number must be greater than 0!', (value) => value > 0),
}).required();

export const schemaAddCourse = Yup.object().shape({
    name: Yup.string().required('Course title is required'),
    image: Yup.mixed()
        .test('required', "Course image is required", (value) => value && value.length)
        .test('file-size', 'File size must be less than 2MB', (value) => value && value.length && value[0].size <= FILE_SIZE),
    video: Yup.string().required('Course URL Video is required').url('Please provide a valid URL'),
    category: Yup.string().required('Please select at least one category'),
    price: Yup.number().required('Course price is required').test('Is positive?', 'The number must be greater than 0!', (value) => value > 0),
}).required();

export const schemaEditLesson = Yup.object({
    name: Yup.string().required('Course title is required'),
    video: Yup.string().required('Course URL Video is required').url('Please provide a valid URL'),
}).required();

export const schemaAddLesson = Yup.object({
    name: Yup.string().required('Course title is required'),
    image: Yup.mixed()
        .test('required', "Course image is required", (value) => {
            return value && value.length
        })
        .test('file-size', 'File size must be less than 2MB', (value) => {
            return value && value.length && value[0].size <= FILE_SIZE;
        }),
    video: Yup.string().required('Course URL Video is required').url('Please provide a valid URL'),
}).required();

const time = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/

export const schemaEditQuiz = Yup.object().shape({
    title: Yup.string().required('Quiz title is required'),
    time: Yup.string().matches(time, 'Time is greater than 0'),
}).required();

export const schemaAddQuiz = Yup.object().shape({
    title: Yup.string().required('Quiz title is required'),
    image: Yup.mixed()
        .test('required', "Quiz image is required", (value) => {
            return value && value.length
        })
        .test('file-size', 'File size must be less than 2MB', (value) => {
            return value && value.length && value[0].size <= FILE_SIZE;
        }),
    time: Yup.string().required('Time is required').matches(time, 'Time is greater than 0'),
}).required();

const point = /^[0-9]*$/
const pointMax = /\b(0*(?:[1-9][0-9]?|100))\b/

export const schemaAddQuestion = Yup.object().shape({
    title: Yup.string().required('Question title is required'),
    point: Yup.string().required('Question point is required').matches(point, 'Question point is not valid').matches(pointMax, "Question point is lower than 100"),
    answers: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Answer title is required'),
            result: Yup.boolean().test(
                "customValidation",
                "One checkbox needs to be checked",
                function (val) {
                    var { parent, from } = this;
                    // console.log(from); //e.g. from[1].value
                    // console.log(from[1].value.answers);
                    const checkBoxValues = from[1].value.answers.map((data) => data.result);
                    var result = false;
                    for (var i = 0; i < checkBoxValues.length; i++) {
                        if (checkBoxValues[i]) {
                            result = true;
                            break;
                        } else {
                            result = false;
                        }
                    }
                    // console.log(result);
                    return result;
                }
            ).test(
                "customValidation",
                "Only one checkbox needs to be checked",
                function (val) {
                    var { parent, from } = this;
                    // console.log(from); //e.g. from[1].value
                    // console.log(from[1].value.answers);
                    const checkBoxValues = from[1].value.answers.map((data) => data.result);
                    var result = false;
                    // console.log((checkBoxValues))
                    const onlyOne = checkBoxValues.filter((item) => item === true);
                    if (onlyOne.length > 1) {
                        result = false;
                    } else {
                        result = true;
                    }
                    // console.log(result);
                    return result;
                }
            )
        })
    )
}).required();

export const schemaEditQuestion = Yup.object().shape({
    title: Yup.string().required('Question title is required'),
    point: Yup.string().required('Question point is required').matches(point, 'Question point is not valid').matches(pointMax, "Question point is lower than 100"),
    answers: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Answer title is required'),
            result: Yup.boolean().test(
                "customValidation",
                "Only one checkbox needs to be checked",
                function (val) {
                    var { parent, from } = this;
                    // console.log(from); //e.g. from[1].value
                    // console.log(from[1].value.answers);
                    const checkBoxValues = from[1].value.answers.map((data) => data.result);
                    var result = false;
                    for (var i = 0; i < checkBoxValues.length; i++) {
                        if (checkBoxValues[i]) {
                            result = true;
                            break;
                        } else {
                            result = false;
                        }
                    }
                    // console.log(result);
                    return result;
                }
            ).test(
                "customValidation",
                "Only one checkbox needs to be checked",
                function (val) {
                    var { parent, from } = this;
                    // console.log(from); //e.g. from[1].value
                    // console.log(from[1].value.answers);
                    const checkBoxValues = from[1].value.answers.map((data) => data.result);
                    var result = false;
                    // console.log((checkBoxValues))
                    const onlyOne = checkBoxValues.filter((item) => item === true);
                    if (onlyOne.length > 1) {
                        result = false;
                    } else {
                        result = true;
                    }
                    // console.log(result);
                    return result;
                }
            )
        })
    )
}).required();
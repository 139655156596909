import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { updatePermission } from '~/features/Auth/userSlice';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

function EditAccount(props) {
    const dataItem = props.dataItem ? props.dataItem : null;

    const { register, handleSubmit } = useForm();

    const dispatch = useDispatch();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 3000,
    });

    const Roles = [
        {
            title: 'student',
            name: 'isStudent',
        },
        {
            title: 'Instructor',
            name: 'isInstructor',
        },
        {
            title: 'admin',
            name: 'isAdmin',
        },
    ];

    const onSubmit = async (data) => {
        const response = await dispatch(updatePermission(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            swalWithBootstrapButtons.fire({
                title: 'Save Successfully!',
                icon: 'success',
            }).then(function () {
                props.handlerClickPopup()
            });         
        } else {
            swalWithBootstrapButtons.fire({
                title: responseData.message,
                icon: 'error',
            });
        }
    };

    return (
        <>
            <div id="overlay">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '700px' }}>
                    <div className="modal-content">
                        <div className="modal-header bg-primary">
                            <h4 className="modal-title text-white">Edit Account</h4>
                            <button
                                type="button"
                                className="close text-white"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    props.handlerClickPopup(false);
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input
                                    type="text"
                                    className="form-control title-display-inline mb-0"
                                    defaultValue={dataItem.id}
                                    hidden
                                    {...register('id')}
                                />
                                <div className="form-group row">
                                    <label htmlFor="qtitle" className="col-form-label form-label col-md-3">
                                        Name:
                                    </label>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            className="form-control title-display-inline mb-0"
                                            disabled
                                            defaultValue={dataItem.name}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="qtitle" className="col-form-label form-label col-md-3">
                                        Email:
                                    </label>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            className="form-control title-display-inline mb-0"
                                            name="email"
                                            disabled
                                            defaultValue={dataItem.email}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label form-label col-md-3">Roles:</label>
                                    <div className="col-md-9">
                                        {Roles.map((item, index) => (
                                            <div className="custom-control custom-checkbox text-capitalize" key={index}>
                                                <input
                                                    id={'checkbox ' + index}
                                                    type="checkbox"
                                                    className="custom-control-input js-check-selected-row"
                                                    defaultChecked={dataItem.roles
                                                        .map((role) => role.name)
                                                        .includes(item.title)}
                                                    {...register(item.name)}
                                                />
                                                <label htmlFor={'checkbox ' + index} className="custom-control-label">
                                                    {item.title}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer border-top">
                                    <button type="submit" className="btn btn-success mb-2 mb-sm-0">
                                        Save
                                    </button>
                                    <p
                                        className="btn btn-danger ml-0 ml-sm-2 mb-2 mb-sm-0"
                                        onClick={() => {
                                            props.handlerClickPopup(false);
                                            props.handlerClickSetData();
                                        }}
                                    >
                                        Close
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditAccount;

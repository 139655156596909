import React from 'react';

function Button(props) {
    const bg = props.backgroundColor ? 'bg-' + props.backgroundColor : 'btn-primary';

    const size = props.size ? 'btn-' + props.size : '';

    const loading = props.loading ? props.loading : false;
    return (
        <div className="form-group ">
            <button type="submit" className={`btn btn-block ${bg} ${size}`} disabled={loading}>
                {!loading && <span className="indicator-label">{props.children}</span>}
                {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                )}
            </button>
        </div>
    );
}

export default Button;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import coursesApi from '~/api/coursesApi';

export const getAllCourses = createAsyncThunk('courses/getCourses', async (payload) => {
    try {
        const response = await coursesApi.getAllCourses(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const getLandingCourses = createAsyncThunk('courses/getLandingCourses', async (payload) => {
    try {
        const response = await coursesApi.getLandingCourses(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const getCoursesGuest = createAsyncThunk('courses/getCoursesGuest', async (payload) => {
    try {
        const response = await coursesApi.getCoursesGuest(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const getCourse = createAsyncThunk('courses/getcourse', async (payload) => {
    try {
        const response = await coursesApi.getCourse(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const getMyCourses = createAsyncThunk('courses/getmycourses', async (payload) => {
    try {
        const response = await coursesApi.getMyCourses(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const lessonCompleted = createAsyncThunk('courses/completelesson', async (payload) => {
    try {
        const response = await coursesApi.lessonCompleted(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const resetCourse = createAsyncThunk('courses/resetcourse', async (payload) => {
    try {
        const response = await coursesApi.resetCourse(payload);
        return response;
    } catch (e) {
        return e;
    }
});

const courseSlice = createSlice({
    name: 'courses',
    initialState: {
        current: null,
        settings: {},
    },
    reducers: {},
    extraReducers: {},
});

const { actions, reducer } = courseSlice;

export default reducer;

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';

function Filter(props) {
    const location = useLocation();

    const navigate = useNavigate();

    const dataFilter = props.dataFilter ? props.dataFilter : [];
    const titleFilter = props.titleFilter ? props.titleFilter : '';
    const classDropdown = props.classDropdown ? props.classDropdown : '';

    const searchFilter = (e) => {
        const params = new URLSearchParams(location.search);

        params.delete('page');

        params.set('categories', e.target.value);

        navigate('?' + params.toString(), { replace: true });
    };

    const clearFilter = (e) => {
        const params = new URLSearchParams(location.search);

        params.delete('categories', '');

        navigate('?' + params.toString(), { replace: true });
    }

    $("#reset").on("click", function () {
        $(this).parents('.dropdown').find('.btn-white').dropdown('hide')
        $('#custom-select option').prop('selected', function () {
            return this.defaultSelected;
        });
    });

    $("option").on("change", function () {
        $(this).parent('.dropdown').find('.btn-white').dropdown('hide')
    });

    return (
        <div className="dropdown" id="filters-dropdown">
            <a data-toggle="dropdown" className="btn btn-white">
                <i className="material-icons mr-sm-1">tune</i>
                <span className="d-none d-sm-block">{titleFilter}</span>
            </a>
            <div className={'dropdown-menu ' + classDropdown}>
                <div className="dropdown-item d-flex flex-column">
                    <form>
                        {dataFilter.map((item, key) => {
                            return (
                                <div className="form-group" key={key}>
                                    <label htmlFor="custom-select" className="form-label">
                                        {item.title}
                                    </label>
                                    <br />
                                    <select
                                        id="custom-select"
                                        className="form-control custom-select"
                                        style={{ width: '200px' }}
                                        key={key}
                                        onChange={(e) => searchFilter(e)}
                                    >
                                        <option value="">Choose Category</option>
                                        {item.options && item.options.map((option, key) => {
                                            return (
                                                <option className="option-filter" value={option.id} key={key}>
                                                    {option.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            );
                        })}
                        <span id='reset' role="button" onClick={(e) => clearFilter(e)}>Clear filters</span>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Filter;

import Input from '~/components/Input';
import Helmet from '~/components/Helmet';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaForgotPass } from '~/helpers/validationSchema';
import AuthHeader from '~/components/auth/AuthHeader';
import { forgotPassword } from '~/features/Auth/userSlice';
import { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import Button from '~/components/Button';
import Alert from '~/components/Alert';

function ForgotPassword() {
    const [message, setMessage] = useState({ message: '', success: true });
    const dataState = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaForgotPass),
    });

    const onSubmit = async (data) => {
        const response = await dispatch(forgotPassword(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            setMessage({
                message:
                    'An email with password reset instructions has been sent to your email. Please check your email and follow the instructions to reset your password.',
                success: true,
            });
        } else {
            setMessage({
                message: 'Your email is not registered with us!',
                success: false,
            });
        }
    };

    return (
        <Helmet title="Forgot Password">
            <div className="d-flex align-items-center min-vh-100">
                <div className="col-sm-8 col-md-6 col-lg-4 mx-auto" style={{ minWidth: '300px' }}>
                    <AuthHeader title="Forgot Password?" subtitle="Recover your account password" />
                    <div className="card navbar-shadow">
                        <div className="card-header">
                            <div
                                className="alert alert-light border-1 border-left-3 border-left-primary d-flex align-items-center mb-1"
                                role="alert"                           
                            >
                                <i className="material-icons text-success mr-2">check_circle</i>
                                <div className="text-body">
                                    An email with password reset instructions has been sent to your email address, if it
                                    exists on our system.
                                </div>
                            </div>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Input
                                    id="email"
                                    label="Email address:"
                                    type="email"
                                    name="email"
                                    placeholder="Your email address"
                                    register={register}
                                    icon={'far fa-envelope'}
                                    valid={errors.hasOwnProperty('email') ? 'is-invalid' : ''}
                                    message={errors.email?.message}
                                />
                                <div className='w-50 mx-auto mt-4'>
                                    <Button loading={dataState.loading}>Send Email</Button>
                                </div>
                            </form>
                            <Alert
                                display={message.message !== ''}
                                message={message.message}
                                alertClass={message.success ? 'success' : 'danger'}
                            />
                        </div>
                    </div>
                    <div className="text-center text-black-50 mt-2">
                        Remember your password? <Link to="/login">Login</Link>
                    </div>
                </div>
            </div>
        </Helmet>
    );
}

export default ForgotPassword;

import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import Helmet from '~/components/Helmet';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuiz, showQuiz, destroyQuestion, reIndexQuestion } from '~/features/ManageQuizzes/quizzesManageSlice';
import React, { useState, useEffect } from 'react';
import Nestable from 'react-nestable';
import QuizQuestionAdd from './QuizQuestionAdd';
import PageTitle from '~/components/page/PageTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaEditQuiz } from '~/helpers/validationSchema';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export default function QuizEdit(props) {

    const params = useParams();

    const [quiz, setQuiz] = useState();

    const [popup, setPopup] = useState(false);

    const [data, setData] = useState('');

    const [thumbnail, setThumbnail] = useState('');

    const [fetch, setFetch] = useState(false);

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onChange', resolver: yupResolver(schemaEditQuiz)
    });

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    });

    const swalCustomButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mr-2',
            denyButton: 'btn btn-danger mr-2',
        },
        buttonsStyling: false,
    });

    const fetchQuiz = async (data) => {
        const response = await dispatch(showQuiz(data));
        const responseData = unwrapResult(response);
        setQuiz(responseData.data);
    };

    const reIndexData = async (data) => {
        let questionIndex = {};
        data.items.map((val, key) => {
            questionIndex[val.id] = key + 1;
        });
        const response = await dispatch(reIndexQuestion(questionIndex));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            setFetch(!fetch);
        }
    };

    useEffect(() => {
        if (params.quizid) {
            fetchQuiz(params.quizid);
        }
    }, [fetch, popup]);

    const deleteQuestion = (data) => {
        swalCustomButtons
            .fire({
                title: 'Do you want to delete the question?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const response = await dispatch(destroyQuestion(data));
                    const responseData = unwrapResult(response);
                    if (responseData.success) {
                        setFetch(!fetch);
                        swalWithBootstrapButtons.fire({
                            title: 'Delete Successfully!',
                            icon: 'success',
                        });
                    } else {
                        swalWithBootstrapButtons.fire({
                            title: responseData.message,
                            icon: 'error',
                        });
                    }
                }
            });
    };

    register('image', {
        onChange: (e) => {
            setThumbnail(URL.createObjectURL(e.target.files[0]));
        },
    });

    const setTimeQuiz = (e) => {
        setValue('time', e.target.value * 60, { shouldValidate: true })
    }

    const renderItem = (props) => {
        const { item, handler } = props;
        return (
            <div className="media align-items-center bg-white p-2 border rounded">
                {handler}
                <div className="media-body">{item.title}</div>
                <div className="media-right text-right">
                    <div>
                        <span
                            href="#"
                            data-toggle="modal"
                            data-target="#editQuiz"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                                setPopup(true);
                                setData(item);
                            }}
                        >
                            <i className="material-icons">edit</i>
                        </span>
                    </div>
                </div>
                <div className="media-right">
                    <span
                        onClick={() => {
                            deleteQuestion(item.id);
                        }}
                        className="btn btn-danger btn-sm"
                    >
                        <i className="material-icons">delete</i>
                    </span>
                </div>
            </div>
        );
    };

    const Handler = () => {
        return (
            <div className="media-left">
                <a href="#" className="btn btn-default nestable-handle px-1 py-2">
                    <i className="material-icons font-weight-bold">menu</i>
                </a>
            </div>
        );
    };

    const onSubmit = async (data) => {
        const response = await dispatch(updateQuiz(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            swalWithBootstrapButtons.fire({
                title: 'Save Successfully!',
                icon: 'success',
            });
        } else {
            swalWithBootstrapButtons.fire({
                title: responseData.message,
                icon: 'error',
            });
        }
    };

    const page = [{ title: 'Edit Quiz', active: true }];

    return (
        <Helmet title="Edit Quiz">
            <Breadcrumb page={page} />
            <PageTitle
                title={"Edit Quiz"}
                button={'save'}
                handleSubmit={handleSubmit(onSubmit)}
                submit={true}
            />
            <Card classHeader={' '} title={'Basic'}>
                {quiz &&
                    <form action="#">
                        <div className="form-group row">
                            <div className='col-md-2' />
                            <label className="col-sm-3 col-md-2 col-form-label form-label" htmlFor="quiz_title">
                                Title
                            </label>
                            <div className="col-sm-9 col-md-6">
                                <input
                                    type="text"
                                    id="quiz_title"
                                    className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                    placeholder="Title"
                                    defaultValue={quiz.title}
                                    {...register('title')}
                                />
                                <div className="invalid-feedback">{errors.title?.message}</div>
                                <input
                                    type="hidden"
                                    className="form-control"
                                    defaultValue={quiz.courses_id}
                                    {...register('course_id')}
                                />
                                <input
                                    type="hidden"
                                    className="form-control"
                                    defaultValue={quiz.id}
                                    {...register('id')}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className='col-md-2' />
                            <label htmlFor="quiz_image" className="col-sm-3 col-md-2 col-form-label form-label">
                                Quiz Image:
                            </label>
                            <div className="col-sm-9 col-md-6">
                                <p>
                                    <img
                                        src={thumbnail ? thumbnail : quiz.image_thumbnail}
                                        alt="quiz caps"
                                        width={150}
                                        className="rounded"
                                    />
                                </p>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        id="quiz_image"
                                        className={`custom-file-input ${errors.image ? 'is-invalid' : ''}`}
                                        accept="image/*"
                                        {...register('image')}
                                    />
                                    <label htmlFor="quiz_image" className="custom-file-label">
                                        Choose file
                                    </label>
                                </div>
                                <div className="invalid-feedback">{errors.image?.message}</div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className='col-md-2' />
                            <label htmlFor="cmn-toggle" className="col-sm-3 col-md-2 col-form-label form-label">
                                Time
                            </label>
                            <div className="col-sm-9 col-md-6">
                                <div className="form-inline">
                                    <div className="form-group mr-2">
                                        <input
                                            type="number"
                                            className={`form-control text-center ${errors.time ? 'is-invalid' : ''}`}
                                            defaultValue={quiz.time / 60}
                                            style={{ width: '90px' }}
                                            onChange={(e) => setTimeQuiz(e)}
                                        />
                                        <span className="ml-3"> Minutes</span>
                                        <div className="invalid-feedback">{errors.time?.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                }
            </Card>
            {quiz &&
                <Card title={'Questions'} classHeader={'header'}>
                    <>
                        <p>
                            <span
                                onClick={() => {
                                    setPopup(true);
                                }}
                                className="btn btn-primary"
                            >
                                Add Question <i className="material-icons ml-1">add</i>
                            </span>
                        </p>
                        <Nestable
                            onChange={(items) => {
                                reIndexData(items);
                            }}
                            items={quiz.question}
                            renderItem={renderItem}
                            handler={<Handler />}
                            maxDepth={1}
                        />
                    </>
                </Card>
            }

            {quiz && (
                <QuizQuestionAdd
                    setFetch={() => setFetch(!fetch)}
                    data={data}
                    quizId={quiz.id}
                    handlerClickSetData={() => setData()}
                    handlerClickPopup={() => setPopup()}
                    display={popup}
                    index={quiz.question.length + 1}
                />
            )}
        </Helmet>
    );
}

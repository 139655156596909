import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Breadcrumb from '~/components/Breadcrumb';
import Helmet from '~/components/Helmet';
import PageTitle from '~/components/page/PageTitle';
import Pagination from '~/components/Pagination';
import Preloader from '~/components/Preloader';
import { getMyCourses, resetCourse } from '~/features/Courses/courseSlice';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

function MyCourses(props) {
    const [course, setCourses] = useState('');

    const [fetch, setFetch] = useState(false);

    const dispatch = useDispatch();

    const location = useLocation();

    const navigate = useNavigate();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    });

    const swalCustomButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mr-2',
            denyButton: 'btn btn-danger mr-2',
        },
        buttonsStyling: false,
    });

    const fetchCourses = async (data) => {
        const response = await dispatch(getMyCourses(data));
        const responseData = unwrapResult(response);
        setCourses(responseData.data);
    };

    useEffect(() => {
        fetchCourses(location.search);
    }, [location, fetch]);

    const handleSubmitUrl = (url) => {
        let paramString = url.split('=')[1];

        const params = new URLSearchParams(window.location.search);

        params.set('page', paramString);

        navigate('?' + params.toString(), { replace: true });
    };

    const resetMyCourse = (data) => {
        swalCustomButtons
            .fire({
                title: 'Do you want to restart the course?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const response = await dispatch(resetCourse(data));
                    const responseData = unwrapResult(response);
                    if (responseData.success) {
                        setFetch(!fetch);
                        swalWithBootstrapButtons.fire({
                            title: 'Restart Successfully!',
                            icon: 'success',
                        })
                    } else {
                        swalWithBootstrapButtons.fire({
                            title: responseData.message,
                            icon: 'error',
                        });
                    }
                    console.log(responseData);

                }
            })
    }

    const calculateProgress = (data) => {
        const arr = [...data];
        const totalLessons = arr.length;
        let count = 0;
        arr.map((data, key) => {
            if (data.status) count++;
        });
        if (count === 0) return { progress: 0, total: totalLessons, count: count };

        const progress = Math.floor((count * 100) / totalLessons);

        return { progress: progress, total: totalLessons, count: count };
    };

    const MessagePreload = () => {
        const [show, setShow] = useState(true);
        useEffect(() => {
            const timer = setTimeout(() => {
                setShow(false);
            }, 250);
            return () => clearTimeout(timer);
        }, [show]);
        if (!show) {
            return null;
        }
        return <Preloader />;
    };

    const page = [{ title: 'My Courses', active: true }];

    return (
        <Helmet title={'My Courses'}>
            <Breadcrumb page={page} />
            <PageTitle title={'My Courses'}></PageTitle>
            {course && course.data && course.data.length > 0 ? (
                <>
                    <div className="row">
                        {course.data &&
                            course.data.map((data, key) => {
                                const patch_url = '/student-view-course/' + data.slug;
                                const progress = calculateProgress(data.lessons);
                                return (
                                    <div className="col-md-4 col-lg-3" key={key}>
                                        <div className="card">
                                            <div className="card-header p-2">
                                                <Link to={patch_url}>
                                                    <img
                                                        src={data.image_thumbnail}
                                                        alt="Card cap"
                                                        className="img-fluid img-responsive rounded"
                                                        style={{ height: '200px', width: '100%', objectFit: 'cover' }}
                                                    />
                                                </Link>
                                            </div>
                                            <div className="progress rounded-0">
                                                <div
                                                    className={`progress-bar progress-bar-none-transition ${progress.progress === 100 ? 'bg-success' : 'bg-primary'
                                                        }`}
                                                    role="progressbar"
                                                    style={{ width: progress.progress + '%' }}
                                                    aria-valuenow={progress.progress}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                            <div className="card-body p-2">
                                                <div className="media-body">
                                                    <h5 className="m-0 title-display-inline text-capitalize mb-2">
                                                        <Link to={patch_url}>{data.name}</Link>
                                                    </h5>
                                                    <small className='description-display-inline-2 mb-1' dangerouslySetInnerHTML={{ __html: data.description }} />
                                                    <small className="text-muted">
                                                        <i className='bx bx-receipt text-muted' />{' Lessons: ' + progress.count + ' of ' + progress.total}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-white p-0">
                                                {progress.progress && progress.progress === 100 ? (
                                                    <>
                                                        <Link to={patch_url} className="col-6 btn btn-success btn-sm rounded-0">
                                                            Completed{' '}
                                                            <i className="material-icons btn__icon--right">check</i>
                                                        </Link>
                                                        <button className="col-6 btn btn-white btn-sm rounded-0" onClick={() => { resetMyCourse(data.id) }} >
                                                            Restart{' '}
                                                            <i className="material-icons btn__icon--right">replay</i>
                                                            &nbsp;
                                                        </button>
                                                    </>
                                                ) : progress.progress > 0 && progress.progress !== 100 ? (
                                                    <Link to={patch_url} className="col-12 btn btn-primary btn-sm rounded-0">
                                                        Continue&nbsp;
                                                        <i className="material-icons btn__icon--right">
                                                            play_circle_outline
                                                        </i>
                                                    </Link>
                                                ) : progress.progress === 0 ? (
                                                    <Link to={patch_url} className="col-12 btn btn-primary btn-sm rounded-0">
                                                        Start&nbsp;
                                                        <i className="material-icons btn__icon--right">
                                                            play_circle_outline
                                                        </i>
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <Pagination
                        links={course.links}
                        handleSubmitUrl={handleSubmitUrl}
                        currentPage={course.current_page}
                    />
                </>
            ) : (
                <>
                    {' '}
                    <MessagePreload />
                    <h2 className="text-center my-5 col-md-12 text-capitalize">You don't have any courses yet!</h2>
                </>
            )}
        </Helmet>
    );
}

export default MyCourses;

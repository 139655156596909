import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import Card from '~/components/Card';
import EditorComponent from '~/components/plugin/Editor';
import { updateLesson } from '~/features/ManageCourses/coursesManageSlice';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { yupResolver } from '@hookform/resolvers/yup';

function FormLesson(props) {
    const name = props.name ? props.name : '';

    const description = props.description ? props.description : '';

    const videoUrl = props.videoUrl ? props.videoUrl : '';

    const [video, setVideo] = useState(videoUrl);

    const [thumbnail, setThumbnail] = useState(props.thumbnail ? props.thumbnail : '');

    const lessonId = props.lessonId ? props.lessonId : '';

    const index = props.index ? props.index : '';

    const action = props.action ? props.action : 'add';

    const courseId = props.courseId ? props.courseId : '';

    const schema = props.schema ? props.schema : '';

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    const dispatch = useDispatch();

    register('image', {
        onChange: (e) => setThumbnail(URL.createObjectURL(e.target.files[0])),
    });

    register('video', {
        onChange: (e) => setVideo(e.target.value),
    });

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 3000,
    });

    const onSubmit = async (data) => {
        const response = await dispatch(updateLesson(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            props.setFetch()
            swalWithBootstrapButtons.fire({
                title: 'Save Successfully!',
                icon: 'success',
            })
            props.handlerClickPopup();
            props.handlerClickSetData();
        } else {
            swalWithBootstrapButtons.fire({
                title: responseData.message,
                icon: 'error',
            });
        }
        console.log(responseData);
    };

    return (
        <div className="modal-body">
            <Card cardClass={'rounded-0 mb-0'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row flex-column flex-md-row">
                        <label htmlFor="avatar" className="col-sm-3 col-form-label form-label">
                            Preview
                        </label>
                        <div className="col-12 col-md-8 px-0">
                            <div className="media align-items-center flex-column flex-md-row ">
                                <div className="media-left">
                                    <img src={thumbnail} alt="output" width={100} className="rounded pb-2 pb-md-0" />
                                </div>
                                <div className="media-body w-100">
                                    <div className="custom-file w-100">
                                        <input
                                            type="file"
                                            className={`custom-file-input ${errors.image ? 'is-invalid' : ''}`}
                                            name="image"
                                            accept="image/*"
                                            {...register('image', {
                                                required: action === 'add' ? true : false,
                                            })}
                                        />
                                        <label htmlFor="avatar" className="custom-file-label title-display-inline">
                                            Choose file
                                        </label>
                                        <div className="invalid-feedback">{errors.image?.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="title" className="col-md-3 col-form-label form-label">
                            Title
                        </label>
                        <div className="col-12 col-md-8 px-0">
                            <input
                                type="text"
                                className={`form-control title-display-inline mb-0 ${errors.name ? 'is-invalid' : ''}`}
                                name='name'
                                defaultValue={name}
                                {...register('name', {
                                    required: action == 'add' ? true : false,
                                })}
                            />
                            <div className="invalid-feedback">{errors.name?.message}</div>
                        </div>
                    </div>
                    {lessonId && (
                        <input
                            type="hidden"
                            className="form-control title-display-inline"
                            defaultValue={lessonId}
                            {...register('id', {
                                required: true,
                            })}
                        />
                    )}
                    {courseId && (
                        <input
                            type="hidden"
                            className="form-control title-display-inline"
                            defaultValue={courseId}
                            {...register('courses_id', {
                                required: true,
                            })}
                        />
                    )}

                    <input
                        type="hidden"
                        className="form-control title-display-inline"
                        defaultValue={index}
                        {...register('index_value', {
                            required: true,
                        })}
                    />
                    <div className="form-group row">
                        <label htmlFor="desc" className="col-md-3 col-form-label form-label">
                            Description
                        </label>
                        <div className="col-12 col-md-8 px-0">
                            <EditorComponent description={description} setValue={setValue} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-md-3 col-form-label form-label">Upload Video</label>
                        <div className="col-md-8 px-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="video-paste"
                                            className={`form-control ${errors.video ? 'is-invalid' : ''}`}
                                            defaultValue={video}
                                            onChange={(e) => {
                                                setVideo(e.target.value);
                                                setValue('video', e.target.value, { shouldValidate: true });
                                            }}
                                            name='video'
                                            {...register('video', {
                                                required: action == 'add' ? true : false,
                                            })}
                                        />
                                        <div className="invalid-feedback">{errors.video?.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <ReactPlayer
                                                width="100%"
                                                height="100%"
                                                autoPlay
                                                playing={false}
                                                className="embed-responsive-item"
                                                url={video}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-top">
                        <button type="submit" className="btn btn-success mb-2 mb-sm-0">
                            Save
                        </button>
                        <p
                            className="btn btn-danger ml-0 ml-sm-2 mb-2 mb-sm-0"
                            onClick={() => {
                                props.handlerClickPopup();
                                props.handlerClickSetData();
                            }}
                        >
                            Close
                        </p>
                    </div>
                </form>
            </Card>
        </div>
    );
}

export default FormLesson;

import FormLesson from '~/components/coursesManager/FormLesson';
import { schemaEditLesson } from '~/helpers/validationSchema';
import { schemaAddLesson } from '~/helpers/validationSchema';
import noLesson from '~/assets/images/courses/no-data.jpg';

export default function CourseLessonAdd(props) {
    const lesson = props.data ? props.data : null;

    return (
        <>
            {props.display && (
                <div title="Edit Lesson" id="overlay">
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '700px' }}>
                        <div className="modal-content">
                            <div className="modal-header d-block border-bottom py-0">
                                <div className="d-flex flex-row flex-wrap mb-headings align-items-center m-0 px-2 py-2">
                                    <div className="flex mb-2 mb-sm-0 h-100">
                                        <h1 className="h2">{props.data ? 'Edit Lesson' : 'Add Lesson'}</h1>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            props.handlerClickPopup(false);
                                            props.handlerClickSetData(null);
                                        }}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            {lesson && (
                                <FormLesson
                                    name={lesson.name}
                                    description={lesson.description}
                                    videoUrl={lesson.video}
                                    thumbnail={lesson.image_thumbnail}
                                    handlerClickPopup={() => props.handlerClickPopup(false)}
                                    handlerClickSetData={() => props.handlerClickSetData(null)}
                                    setFetch={() => props.setFetch()}
                                    lessonId={lesson.id}
                                    index={lesson.index_value}
                                    action="update"
                                    schema={schemaEditLesson}
                                />
                            )}

                            {!lesson && (
                                <FormLesson
                                    thumbnail={noLesson}
                                    handlerClickPopup={() => props.handlerClickPopup(false)}
                                    handlerClickSetData={() => props.handlerClickSetData(null)}
                                    setFetch={() => props.setFetch()}
                                    courseId={props.courseId}
                                    action="add"
                                    index={props.index}
                                    schema={schemaAddLesson}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

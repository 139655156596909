import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userApi from '../../api/userApi';
import StorageKeys from '~/constants/storage-keys';

export const login = createAsyncThunk('users/login', async (payload) => {
    try {
        const response = await userApi.login(payload);
        if (response.success) {
            localStorage.setItem(StorageKeys.access, response.data.access_token);
            const responseUser = await userApi.getUser(response.data.access_token);
            return responseUser;
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const getUser = createAsyncThunk('users/getUser', async (payload) => {
    try {
        const response = await userApi.getUser(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const loginWithGoogle = createAsyncThunk('users/loginwithgoogle', async (payload) => {
    try {
        const response = await userApi.loginWithGoogle(payload);
        if (response.success) {
            localStorage.setItem(StorageKeys.access, response.data.access_token);
            const responseUser = await userApi.getUser(response.data.access_token);
            return responseUser;
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const signup = createAsyncThunk('user/signup', async (payload) => {
    try {
        const response = await userApi.register(payload);
        if (response.success) {
            localStorage.setItem(StorageKeys.access, response.data.access_token);
            const responseUser = await userApi.getUser(response.data.access_token);
            return responseUser;
        } else {
            return response;
        }
    } catch (error) {
        return error;
    }
});

export const logout = createAsyncThunk('user/logout', async () => {
    localStorage.clear();
    return true;
});

export const managerPermission = createAsyncThunk('user/managerPermission', async (payload) => {
    try {
        const response = await userApi.managerPermission(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const updatePermission = createAsyncThunk('user/updatePermission', async (payload) => {
    try {
        const response = await userApi.updatePermission(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (payload) => {
    try {
        const response = await userApi.forgotPassword(payload);
        return response;
    } catch (error) {
        return error.message;
    }
});

export const resetPassword = createAsyncThunk('user/resetPassword', async (payload) => {
    try {
        const response = await userApi.resetPassword(payload);
        if (response.success) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.log('Error catch here: ', error.message);
        return error.message;
    }
});

export const updatePassword = createAsyncThunk('user/updatePassword', async (payload) => {
    try {
        const response = await userApi.updatePassword(payload);
        if (response.success) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.log('Error catch here: ', error.message);
        return error.message;
    }
});

export const updateUser = createAsyncThunk('user/update', async (payload) => {
    try {
        const response = await userApi.updateUser(payload);
        if (response.success) {
            return response;
        }
        throw new Error('Something went wrong');
    } catch (error) {
        return error.message;
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        current: null,
        loading: false,
        settings: {},
    },
    reducers: {},
    extraReducers: {
        [login.pending]: (state) => {
            state.loading = true;
        },
        [login.fulfilled]: (state, action) => {
            state.loading = false;
            state.current = action.payload.data;
        },
        [login.rejected]: (state) => {
            state.loading = false;
        },

        [forgotPassword.pending]: (state) => {
            state.loading = true;
        },
        [forgotPassword.fulfilled]: (state) => {
            state.loading = false;
        },
        [forgotPassword.rejected]: (state) => {
            state.loading = false;
        },

        [loginWithGoogle.fulfilled]: (state, action) => {
            state.current = action.payload.data;
        },

        [signup.fulfilled]: (state, action) => {
            state.current = action.payload.data;
        },

        [getUser.fulfilled]: (state, action) => {
            state.current = action.payload.data;
        },

        [updateUser.fulfilled]: (state, action) => {
            state.current = action.payload.data;
        },

        [logout.fulfilled]: (state) => {
            state.current = null;
        },
    },
});

const { reducer } = userSlice;

export default reducer;

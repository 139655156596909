import React from 'react';
import logo from '~/assets/images/logo/LMS-Icon.svg';
import badge from '~/assets/images/logo/Experium.png';
import { Link } from 'react-router-dom';
import 'sweetalert2/src/sweetalert2.scss';

function AuthHeader({ title, subtitle, description, url, urlTitle }) {
    return (
        <div className="auth-header d-flex flex-column align-items-center mb-4 mb-md-0">
            <div className="d-flex flex-row align-items-center">
                {/* <Link to="/landing">
                    <img src={logo} className="avatar-img"/>
                </Link> */}
                <Link to="/landing" className="text-center">
                    <img src={badge} className="avatar-img" style={{ maxHeight: '115px' }}/>
                </Link>
            </div>

            <div className="text-center">
                <h2 className="card-title text-uppercase font-weight-bold mb-0">{title}</h2>
                <h3 className="text-uppercase font-weight-bold text-muted">{subtitle}</h3>
                <div className="text-center text-black-50 mb-2">
                    {description} <Link to={`/${url}`}>{urlTitle}</Link>
                </div>
            </div>
        </div>
    );
}
export default AuthHeader;

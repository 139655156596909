import StorageKeys from '../constants/storage-keys';
import axiosClient from './axiosClient';

const cartApi = {
    async store() {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/carts/store';
        const response = await axiosClient.post(
            url,
            {},
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async show() {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const cartId = JSON.parse(localStorage.getItem('cartData'));
        const cartKey = cartId.cartKey;
        const url = 'api/carts/show';
        const response = await axiosClient.post(
            url,
            { cartKey: cartKey },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async addToCart(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const cartId = JSON.parse(localStorage.getItem('cartData'));
        const cartToken = cartId.cartToken;
        const url = 'api/carts/addtocart';
        const response = await axiosClient.post(
            url,
            { courseId: data, cartToken: cartToken },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async destroyItem(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const cartId = JSON.parse(localStorage.getItem('cartData'));
        const cartToken = cartId.cartToken;
        const url = 'api/carts/destroy';
        const response = await axiosClient.post(
            url,
            { courseId: data, cartToken: cartToken },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async checkoutCart(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const cartId = JSON.parse(localStorage.getItem('cartData'));
        const cartToken = cartId.cartToken;
        const url = 'api/carts/checkout';
        const response = await axiosClient.post(
            url,
            { course_id: data, cartToken: cartToken },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },
};

export default cartApi;

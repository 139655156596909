import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Breadcrumb from '~/components/Breadcrumb';
import Helmet from '~/components/Helmet';
import PageTitle from '~/components/page/PageTitle';
import Pagination from '~/components/Pagination';
import Preloader from '~/components/Preloader';
import { getAllCourses } from '~/features/Courses/courseSlice';

function BrowseCourses(props) {
    const [course, setCourses] = useState('');

    const page = [{ title: 'Courses', active: true }];

    const location = useLocation();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const fetchCourses = async (data) => {
        const response = await dispatch(getAllCourses(data));
        const responseData = unwrapResult(response);
        setCourses(responseData.data);
    };

    useEffect(() => {
        fetchCourses(location.search);
    }, [location]);

    const handleSubmitUrl = (url) => {
        let paramString = url.split('=')[1];

        const params = new URLSearchParams(location.search);

        params.set('page', paramString);

        navigate('?' + params.toString(), { replace: true });
    };

    const MessagePreload = () => {
        const [show, setShow] = useState(true);
        useEffect(() => {
            const timer = setTimeout(() => {
                setShow(false);
            }, 150);
            return () => clearTimeout(timer);
        }, [show]);
        if (!show) {
            return null;
        }
        return <Preloader />;
    };

    return (
        <div className="page__container">
            {!course && <Preloader />}
            {course && (
                <Helmet title="Courses">
                    <Breadcrumb page={page} />
                    <PageTitle title={'Courses'}></PageTitle>
                    {course.data && course.data.length !== 0 ?
                        (
                            <>
                                <div className="row">

                                    {course.data.map((data, key) => {
                                        const patch_url = '/student-view-course/' + data.slug;
                                        return (
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-3" key={key}>
                                                <div className="card mb-5 bg-light p-2">
                                                    <div className="card-header text-center border-bottom-0 p-0 bg-light">
                                                        <Link to={patch_url}>
                                                            <img
                                                                src={data.image_thumbnail}
                                                                alt="Card course cap"
                                                                className="img-fluid img-responsive px-0"
                                                                style={{ height: '190px', width: '100%', objectFit: 'cover' }}
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="card-body p-1 border-top-0">
                                                        <h4 className="card-title title-display-inline mb-0 font-size-16pt text-capitalize">
                                                            <Link to={patch_url}>{data.name}</Link>
                                                        </h4>
                                                        <div className="rating mb-2">
                                                            <i className="material-icons font-size-16pt">star</i>
                                                            <i className="material-icons font-size-16pt">star</i>
                                                            <i className="material-icons font-size-16pt">star</i>
                                                            <i className="material-icons font-size-16pt">star</i>
                                                            <i className="material-icons font-size-16pt">star_border</i>
                                                        </div>
                                                        <small className="font-weight-bolder">
                                                            {data.level === 1 ? 'Beginner' : 'Advanced'}
                                                        </small>
                                                        <p
                                                            className="description-display-inline-2 mb-1"
                                                            dangerouslySetInnerHTML={{ __html: data.description }}
                                                            style={{ fontSize: '12px' }}
                                                        />
                                                        <span className="badge badge-primary text-capitalize">
                                                            {data.category_name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <Pagination
                                    links={course.links}
                                    handleSubmitUrl={handleSubmitUrl}
                                    currentPage={course.current_page}
                                />
                            </>
                        ) : (
                            <>
                                <MessagePreload />
                                <h2 className="text-center my-5 col-md-12 text-capitalize"> We can't find courses matching the selection!</h2>
                            </>

                        )}
                </Helmet>
            )}
        </div>
    );
}

export default BrowseCourses;

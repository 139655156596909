import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Breadcrumb from '~/components/Breadcrumb';
import Helmet from '~/components/Helmet';
import { managerPermission } from '~/features/Auth/userSlice';
import PageTitle from '~/components/page/PageTitle';
import noData from '~/assets/images/people/50/no-data.png';
import Pagination from '~/components/Pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import EditAccount from '~/components/auth/EditAccount';
import { useAuth } from '~/routes/AuthProvider';
import { useForm } from 'react-hook-form';

function ManagerAccount(props) {
    const [dataUser, setDataUser] = useState();

    const [dataItem, setDataItem] = useState();

    const { register, handleSubmit } = useForm();

    const dispatch = useDispatch();

    const location = useLocation();

    const navigate = useNavigate();

    const [popup, setPopup] = useState(false);

    const auth = useAuth();

    const fetchData = async (data) => {
        const response = await dispatch(managerPermission(data));
        const responseData = response.payload;
        if (responseData.success) {
            setDataUser(responseData.data);
        }
    };

    const handleSubmitUrl = (url) => {
        let paramString = url.split('=')[1];
        const params = new URLSearchParams(location.search);
        params.set('page', paramString);
        navigate('?' + params.toString(), { replace: true });
    };

    const onSubmit = (data) => {
        const params = new URLSearchParams(location.search);

        params.set('search', data.search);

        params.set('page', 1);

        navigate('?' + params.toString(), { replace: true });
    };

    useEffect(() => {
        fetchData(location.search);
    }, [location, popup]);

    useEffect(() => {
        if (auth.user.roles) {
            let isAdmin = false;

            auth.user.roles.map((value, index) => {
                if (value.name === 'admin') {
                    isAdmin = true;
                }
            });

            if (!isAdmin) {
                const isDashBoard = localStorage.getItem('isDashBoard');
                if (isDashBoard === 'Instructor') {
                    navigate('/instructor-dashboard', { replace: true });
                } else {
                    navigate('/', { replace: true });
                }
            }
        }
    }, []);

    const tableTitle = [
        { title: 'Name' },
        { title: 'Email' },
        { title: 'Login with Google' },
        { title: 'Role' },
        { title: 'Avatar' },
        { title: 'Email Verified' },
        { title: 'Action' },
    ];

    const page = [{ title: 'Manager Account', active: true }];

    return (
        <Helmet title="Manager Account">
            {dataUser && (
                <>
                    <div className="row m-0">
                        <div className="col-lg-11 container-fluid page__container">
                            <Breadcrumb page={page} />
                            <PageTitle title={'Manager Account'} />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="search-form search-form--light mb-3">
                                    <input
                                        type="text"
                                        className="form-control search"
                                        placeholder="Enter email"
                                        {...register('search')}
                                    />
                                    <button className="btn" type="button" role="button">
                                        <i className="material-icons">search</i>
                                    </button>
                                </div>
                            </form>
                            <div className="card">
                                <table className="table table-nowrap mb-0 table-responsive-lg">
                                    <tbody className="list">
                                        <tr>
                                            {tableTitle.map((item, index) => (
                                                <th className="title text-capitalize py-3" key={index}>
                                                    <span>{item.title}</span>
                                                </th>
                                            ))}
                                        </tr>

                                        {dataUser.data.map((item, index) => (
                                            <tr key={index}>
                                                <td className="list__content">{item.name}</td>
                                                <td className="list__content">{item.email}</td>
                                                <td className="list__content">
                                                    {item.google_id && item.google_id ? 'Yes' : 'No'}
                                                </td>
                                                <td className="list__content">
                                                    {item.roles && item.roles.length > 0
                                                        ? item.roles.map((item, index) => (
                                                              <div key={index}>
                                                                  <label className="text-capitalize">{item.name}</label>
                                                              </div>
                                                          ))
                                                        : 'No'}
                                                </td>
                                                <td className="list__content">
                                                    <img
                                                        src={item.image && item.image ? item.image : noData}
                                                        alt="avatar"
                                                        className="avatar rounded"
                                                    />
                                                </td>
                                                <td className="list__content">
                                                    {item.email_verified_at && item.email_verified_at !== null
                                                        ? 'Yes'
                                                        : 'No'}
                                                </td>
                                                <td className="list__content">
                                                    <button
                                                        className="btn btn-primary btn-sm mr-1"
                                                        onClick={() => {
                                                            setPopup(true);
                                                            setDataItem(item);
                                                        }}
                                                    >
                                                        <i className="material-icons">edit</i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-12">
                            <Pagination
                                links={dataUser.links}
                                handleSubmitUrl={handleSubmitUrl}
                                currentPage={dataUser.current_page}
                            />
                        </div>
                    </div>
                    {popup && (
                        <EditAccount
                            dataItem={dataItem}
                            handlerClickSetData={() => setDataItem()}
                            handlerClickPopup={() => setPopup()}
                            display={popup}
                        />
                    )}
                </>
            )}
        </Helmet>
    );
}

export default ManagerAccount;

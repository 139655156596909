export const sidebarData = [
    {
        label: 'APPLICATIONS',
        level1: [
            {
                label: 'Student',
                icon: 'account_box',
                url: '/student-dashboard',
            },
        ],
    },
    {
        label: 'Account',
        level1: [
            {
                label: 'Account',
                icon: 'person_outline',
                level2: [
                    {
                        label: 'Edit Account',
                        url: '/edit-account',
                    },
                    {
                        label: 'Basic Information',
                        url: '/account-information',
                    },
                ],
            },
        ],
    },
    {
        label: 'Student',
        level1: [
            {
                label: ' Browse Courses',
                icon: 'search',
                url: '/student-browse-courses',
            },
            {
                label: 'My Courses',
                icon: 'school',
                url: '/student-my-course',
            },
            {
                label: 'Take a Quiz',
                icon: 'dvr',
                url: '/student-take-quiz',
            },
            {
                label: 'Quiz Results',
                icon: 'poll',
                url: '/student-quiz-results',
            },
        ],
    },
];

import React, { useState } from 'react';
import Helmet from '~/components/Helmet';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaLogin } from '~/helpers/validationSchema';
import Input from '~/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '~/features/Auth/userSlice';
import { useNavigate } from 'react-router-dom';
import AuthHeader from '~/components/auth/AuthHeader';
import { inputLogin } from '~/assets/data/dataInput';
import { useAuth } from '~/routes/AuthProvider';
import { unwrapResult } from '@reduxjs/toolkit';
import Alert from '~/components/Alert';
import Button from '~/components/Button';
import LoginGoogle from '~/components/auth/LoginGoogle';

function Login() {
    const [message, setMessage] = useState('');

    const dataState = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const location = useLocation();

    const auth = useAuth();

    let navigate = useNavigate();

    const params = (new URLSearchParams(location.search)).get('redirect');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaLogin),
    });

    const handlerRedirect = () => {
        const params = new URLSearchParams(location.search);
        const redirectTo = params.get('redirect');
        navigate(redirectTo == null ? "/" : `/student-view-course/${redirectTo}`, { replace: true });
    }

    const onSubmit = async (data) => {
        try {
            const response = await dispatch(login(data));
            const responseData = unwrapResult(response);
            if (responseData.success) {
                auth.signin(responseData.data);
                handlerRedirect()
            } else {
                setMessage('Email or password is incorrect, please try again !');
            }
        } catch (error) { }
    };

    return (
        <Helmet title="Login">
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center min-vh-100">
                <div className="col-sm-8 col-md-5 col-lg-4">
                    <AuthHeader title="Login" subtitle="Access your account" description={"Not yet a student?"} url={params ? `signup?redirect=${params}` : "signup"} urlTitle={"Sign Up"} />
                </div>
                <div className="col-sm-8 col-md-5 col-lg-4" style={{ minWidth: '300px' }}>
                    <LoginGoogle isLoginSignUp={true} />
                    <div className="card navbar-shadow">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {inputLogin.map((data, key) => {
                                    return (
                                        <div key={key}>
                                            <Input
                                                label={data.label}
                                                type={data.type}
                                                name={data.name}
                                                placeholder={data.placeholder}
                                                register={register}
                                                icon={data.icon}
                                                valid={errors.hasOwnProperty(data.name) ? 'is-invalid' : ''}
                                                message={errors[data.name]?.message}
                                            />
                                        </div>
                                    );
                                })}
                                <div className='d-flex flex-row justify-content-between mb-3'>
                                    <div className="text-black-70 d-flex align-items-center" >
                                        <input type="checkbox" />
                                        <p className='mb-0 ml-2'>
                                            Remember Password
                                        </p>
                                    </div>
                                    <Link to="/forgot-password" className="text-primary-70">
                                        <p className='mb-0'>
                                            <u>Forgot Password?</u>
                                        </p>
                                    </Link>
                                </div>

                                <div className='w-50 mx-auto'>
                                    <Button loading={dataState.loading}>Continue</Button>
                                </div>
                            </form>
                            <Alert display={message !== ''} message={message} alertClass="danger" />
                        </div>
                    </div>
                </div>
            </div>
        </Helmet>
    );
}
export default Login;

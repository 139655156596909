import StorageKeys from '../constants/storage-keys';
import axiosClient from './axiosClient';

const quizzesManageApi = {

    async getQuizzes(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        let url = 'api/quizzesmanager/getquizzes';

        const response = await axiosClient.post(url, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        return response.data;
    },

    async showQuiz(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        let url = 'api/quizzesmanager/index';

        const response = await axiosClient.post(url, { id: data }, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        return response.data;
    },

    async getStudentResult(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        let url = 'api/quizzesmanager/getstudentresult';

        const response = await axiosClient.post(url, { id: data }, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        return response.data;
    },

    async destroyQuiz(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/quizzesmanager/destroy';
        const response = await axiosClient.post(
            url,
            { id: data },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async destroyQuestion(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/quizzesmanager/destroyquestion';
        const response = await axiosClient.post(
            url,
            { id: data },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async reIndexQuestion(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/quizzesmanager/indexquestion';

        const response = await axiosClient.post(
            url,
            { indexed: data },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );

        return response.data;
    },

    async updateQuiz(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/quizzesmanager/updatequiz';

        let formdata = new FormData();
        if (data.id) {
            formdata.append('id', data.id);
        }
        if (data.image[0]) {
            formdata.append('image_thumbnail', data.image[0]);
        }
        if (data.title) {
            formdata.append('title', data.title);
        }
        if (data.time) {
            formdata.append('time', data.time);
        }
        if (data.course) {
            formdata.append('courses_id', data.course);
        }

        const response = await axiosClient.post(url, formdata, {
            headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    },

    async updateQuestion(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/quizzesmanager/updatequestion';
        const response = await axiosClient.post(
            url,
            data,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );

        return response.data;
    },
};

export default quizzesManageApi;

import { useState } from 'react';
import Input from '~/components/Input';
import Helmet from '~/components/Helmet';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaResetPass } from '~/helpers/validationSchema';
import AuthHeader from '~/components/auth/AuthHeader';
import { resetPassword } from '~/features/Auth/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { inputResetPassword } from '~/assets/data/dataInput';
import { unwrapResult } from '@reduxjs/toolkit';
import Button from '~/components/Button';

function ResetPassword() {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const token = searchParams.get('token');
    const useEmail = searchParams.get('email');
    const navigate = useNavigate();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 3000,
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaResetPass),
    });

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const response = await dispatch(resetPassword(data));
            const responseData = unwrapResult(response);
            if (responseData.success) {
                setLoading(false);
                swalWithBootstrapButtons
                    .fire({
                        title: 'Reset Password Successfully!',
                        icon: 'success',
                    })
                navigate('/login', { replace: true });
            } else {
                let message = 'Please try again!';
                if (responseData.message === 'passwords.token') {
                    message = 'Your token has expired, please resend the email.';
                }

                setLoading(false);
                swalWithBootstrapButtons
                    .fire({
                        title: 'Reset password failed!',
                        icon: 'error',
                    })
                navigate('/forgot-password', { replace: true });
                ;
            }
        } catch (error) {
            swalWithBootstrapButtons.fire({
                title: 'Something Went Wrong!',
                icon: 'error',
            });
            setLoading(false);
        }
    };
    return (
        <Helmet title="Forgot Password">
            <div className="d-flex align-items-center" style={{ minHeight: '100vh' }}>
                <div className="col-sm-8 col-md-6 col-lg-4 mx-auto" style={{ minWidth: '300px' }}>
                    <AuthHeader title="Reset Password" subtitle="Recover your account password" />
                    <div className="card navbar-shadow">
                        <div className="card-body">
                            <div
                                className="alert alert-light border-1 border-left-3 border-left-primary d-flex"
                                role="alert"
                            >
                                <i className="material-icons text-success mr-3">check_circle</i>
                                <div className="text-body">Enter a new password for your email</div>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {inputResetPassword.map((data, key) => {
                                    return (
                                        <div key={key}>
                                            <Input
                                                label={data.label}
                                                type={data.type}
                                                name={data.name}
                                                placeholder={data.placeholder}
                                                register={register}
                                                icon={data.icon}
                                                valid={errors.hasOwnProperty(data.name) ? 'is-invalid' : ''}
                                                message={errors[data.name]?.message}
                                            />
                                        </div>
                                    );
                                })}
                                <input
                                    type="text"
                                    name="token"
                                    value={token}
                                    style={{ display: 'none' }}
                                    {...register('token')}
                                />
                                <input
                                    type="text"
                                    name="email"
                                    value={useEmail}
                                    style={{ display: 'none' }}
                                    {...register('email')}
                                />
                                <div className='w-50 mx-auto mt-4'>
                                    <Button loading={loading}>Reset Password</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Helmet>
    );
}

export default ResetPassword;

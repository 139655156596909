import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cartApi from '~/api/cartApi';

export const storeCart = createAsyncThunk('cart/store', async () => {
    try {
        const response = await cartApi.store();
        return response;
    } catch (e) {
        return e;
    }
});

export const fetchCart = createAsyncThunk('cart/show', async (payload) => {
    try {
        const response = await cartApi.show(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const addToCart = createAsyncThunk('cart/addtocart', async (payload) => {
    try {
        const response = await cartApi.addToCart(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const destroyItem = createAsyncThunk('cart/destroyitem', async (payload) => {
    try {
        const response = await cartApi.destroyItem(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const checkoutCart = createAsyncThunk('cart/checkoutCart', async (payload) => {
    try {
        const response = await cartApi.checkoutCart(payload);
        return response;
    } catch (e) {
        return e;
    }
});

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        current: null,
        message: '',
        settings: {},
    },
    reducers: {},
    extraReducers: {
        [fetchCart.fulfilled]: (state, action) => {
            state.current = action.payload.data;
        },
        [addToCart.fulfilled]: (state, action) => {
            state.message = action.payload.message;
        },
        [destroyItem.fulfilled]: (state, action) => {
            state.message = action.payload.message;
        },
        [checkoutCart.fulfilled]: (state, action) => {
            state.current = null;
            state.message = 'checkout';
        },
    },
});

const { actions, reducer } = cartSlice;

export default reducer;

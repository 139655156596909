import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Helmet from '~/components/Helmet';
import { getQuestionsResult } from '~/features/Quiz/quizSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import 'sweetalert2/src/sweetalert2.scss';
import { useDispatch } from 'react-redux';
import Card from '~/components/Card';
import Breadcrumb from '~/components/Breadcrumb';
import PageTitle from '~/components/page/PageTitle';

function QuizResultDetail(props) {
    const params = useParams();
    const [quiz, setQuiz] = useState('');
    const [result, setResult] = useState('');

    const dispatch = useDispatch();

    const fetchQuestions = async (data) => {
        const response = await dispatch(getQuestionsResult(data));
        const responseData = unwrapResult(response);

        const dataMap = mapQuestionsResult(responseData.data);
        setResult(responseData.data.quiz_result);
        setQuiz(dataMap);
    };

    const mapQuestionsResult = (data) => {
        const newQuestions = [];
        data.questions.forEach((question) => {
            data.quiz_result.answers.forEach((result) => {
                if (question.id === result.questions) {
                    newQuestions.push({
                        answers: question.answers,
                        point: question.point,
                        title: question.title,
                        choose: result.answers,
                        corect: result.corect,
                    });
                }
            });
        });
        return newQuestions;
    };

    const checkCorect = (key, check, corect, answer) => {
        if (corect) {
            return (
                <>
                    <input
                        id={'checkbox' + key}
                        type="checkbox"
                        className="custom-control-input"
                        disabled
                        checked={answer.result ? true : false}
                    />
                    <label htmlFor={'checkbox' + key} className="custom-control-label  custom-control-corect">
                        {answer.title}
                    </label>
                </>
            );
        } else {
            return (
                <>
                    <input
                        id={'checkbox' + key}
                        type="checkbox"
                        className="custom-control-input"
                        disabled
                        checked={key === check || answer.result ? true : false}
                    />
                    <label
                        htmlFor={'checkbox' + key}
                        className={
                            'custom-control-label ' +
                            (key === check ? 'custom-control-danger' : '') +
                            (answer.result ? 'custom-control-corect' : '')
                        }
                    >
                        {answer.title}
                    </label>
                </>
            );
        }
    };
    useEffect(() => {
        if (params.slug && params.quizid) {
            const data = {
                slug: params.slug,
                quiz_id: params.quizid,
            };
            fetchQuestions(data);
        }
    }, []);

    const page = [{ title: 'Quiz Results', active: true }];
    return (
        <Helmet title="Quiz Results">
            <div className="container-fluid page__container">
                <Breadcrumb page={page} />
                <PageTitle title={'Quiz Results'} />
                <div className="row">
                    <div className="col-12">
                        {result && (
                            <div className="card-group">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h4 className="mb-0">
                                            <strong>{result.corect + result.left + result.wrong}</strong>
                                        </h4>
                                        <small className="text-muted-light">TOTAL</small>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h4 className="text-success mb-0">{result.corect}</h4>
                                        <small className="text-muted-light">CORRECT</small>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h4 className="text-danger mb-0">{result.wrong}</h4>
                                        <small className="text-muted-light">WRONG</small>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body text-center">
                                        <h4 className="text-primary mb-0">{result.left}</h4>
                                        <small className="text-muted-light">LEFT</small>
                                    </div>
                                </div>
                            </div>
                        )}
                        {quiz &&
                            quiz.map((data, index) => {
                                return (
                                    <Card
                                        title={'#' + (index + 1) + ' ' + data.title}
                                        classHeader={'d-flex align-items-center'}
                                        rightComponet={
                                            <i
                                                className={
                                                    'material-icons ml-2 ' +
                                                    (data.corect ? 'text-success' : 'text-danger')
                                                }
                                            >
                                                {data.corect ? 'check' : 'error'}
                                            </i>
                                        }
                                        key={index}
                                    >
                                        <div className="card-body">
                                            {data.answers.map((answer, key) => {
                                                return (
                                                    <div className="form-group" key={key}>
                                                        <div className="custom-control custom-checkbox">
                                                            {checkCorect(key, data.choose, data.corect, answer)}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Card>
                                );
                            })}
                    </div>
                </div>
            </div>
        </Helmet>
    );
}

export default QuizResultDetail;

import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '~/components/Breadcrumb';
import Helmet from '~/components/Helmet';
import PageTitle from '~/components/page/PageTitle';
import { getQuizzes } from '~/features/ManageQuizzes/quizzesManageSlice';

function QuizReview() {
    const params = useParams();
   
    const [quiz, setQuiz] = useState();
    
    const dispatch = useDispatch();
    
    const fetchQuiz = async (data) => {
        const response = await dispatch(getQuizzes(data));
        const responseData = unwrapResult(response);
        setQuiz(responseData.data);
    };

    useEffect(() => {
        if (params.slug) {
            const data = {
                slug: params.slug,
            };
            fetchQuiz(data);
        }
    }, [params]);

    const timeElapsed = Date.now();
   
    const today = new Date(timeElapsed);

    const page = [{ title: 'Quiz Review', active: true }];

    return (
        <Helmet title="Quiz Review">
            <Breadcrumb page={page} />
            <PageTitle title={'Quiz Review'} />
            {quiz && (
                <>
                    <div className="media mb-headings align-items-center">
                        <div className="media-left">
                            <img src={quiz.image_thumbnail} alt="" width={80} className="rounded" />
                        </div>
                        <div className="media-body">
                            <h1 className="h2">{quiz.name}</h1>
                            <p className="text-muted">
                                submitted on {quiz.created_at ? quiz.created_at : today.toDateString()}
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header align-items-center d-flex justify-content-between">
                            <h4 className="card-title mb-0 col-4">Lessons</h4>
                            <div className="text-right col-4 text-center">
                                <h4 className="card-title mb-0">Review</h4>
                            </div>
                        </div>

                        <ul className="list-group list-group-fit mb-0">
                            {quiz.quizzes && quiz.quizzes.map((data, key) => {
                                return (
                                    <li
                                        className='list-group-item'
                                        key={key}
                                    >
                                        <div className="media justify-content-between">
                                            <div className="media-body col-4 d-flex p-0">
                                                <div className="media-left">
                                                    <div className="text-muted-light">{key + 1}</div>
                                                </div>
                                                <Link to={`${data.id}`} className="text-decoration-none">
                                                    {data.title}
                                                </Link>
                                            </div>

                                            <div className="media-left col-4 text-center">
                                                <span className="">
                                                    <Link to={`${data.id}`} className="text-decoration-none btn btn-outline-primary py-1 px-2">
                                                        Review Quiz
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            )}
        </Helmet>
    );
}
export default QuizReview;
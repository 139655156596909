import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '~/assets/images/logo/LMS-Icon.svg';
import badge from '~/assets/images/logo/Experium.png';

function FooterLanding(props) {
    const location = useLocation();

    const navigate = useNavigate();
    return (
        <footer className="footer-distributed mt-5 mt-sm-5 mt-lg-0 mt-md-0 mt-xl-0">
            <div className="footer-left d-sm-flex flex-sm-column d-lg-inline-block align-items-center">
                <h3>
                    {/* <img src={logo} className="svg mr-1" alt="LMS" style={{ height: '50px' }} /> */}
                    <img src={badge} className="svg mr-1" alt="LMS" style={{ height: '50px' }} />
                </h3>
                <p className="footer-links">
                    <Link to="/" className="link-home mr-1">
                        Home
                    </Link>
                    <Link to="/" className="link-blog mx-1">
                        Blog
                    </Link>
                    <Link to="/" className="link-pricing mx-1">
                        Pricing
                    </Link>
                    <Link to="/" className="link-about mx-1">
                        About
                    </Link>
                    <Link to="/" className="link-faq mx-1">
                        Faq
                    </Link>
                    <Link to="/" className="link-contact mx-1">
                        Contact
                    </Link>
                </p>
                <p className="footer-company-name">LMS © 2022</p>
            </div>
            <div className="footer-center d-flex flex-row d-lg-inline-block align-items-center justify-content-center">
                <div>
                    <a href="https://goo.gl/maps/yY9fdC3mndxXPWTo9">
                        <i className="fa fa-map-marker bg-primary" />
                        <p className="d-none d-sm-none d-lg-inline-block">
                            <span>2630 Rosewood Lane</span> New York. NY{' '}
                        </p>
                    </a>
                </div>
                <div>
                    <span>
                        <i className="fa fa-phone bg-primary" />
                        <p className="d-none d-sm-none d-lg-inline-block">+1-847-555-5555</p>
                    </span>
                </div>
                <div>
                    <a href="mailto:support@company.com">
                        <i className="fa fa-envelope bg-primary" />
                        <p className="d-none d-sm-none d-lg-inline-block">support@company.com</p>
                    </a>
                </div>
            </div>
            <div className="footer-right d-sm-flex flex-sm-column d-lg-inline-block align-items-center">
                <p className="footer-company-about">
                    <span className="mb-3 font-weight-bolder">About the company</span>
                    LMS believes that everyone has limitless potential to become proficient. The problem is they don't
                    apply the right methods to make their learning more effective. Therefore, LMS wants to help those
                    who have difficulty learning in general and learning programming, in particular, to have access to
                    smart programming methods and experiences to become really good.
                </p>
                <div className="footer-icons">
                    <Link to="/" className="bg-primary">
                        <i className="fab fa-facebook-square" />
                    </Link>
                    <Link to="/" className="bg-primary">
                        <i className="fab fa-twitter-square" />
                    </Link>
                    <Link to="/" className="bg-primary">
                        <i className="fab fa-linkedin" />
                    </Link>
                    <Link to="/" className="bg-primary">
                        <i className="fab fa-github-square" />
                    </Link>
                </div>
            </div>
        </footer>
    );
}

export default FooterLanding;

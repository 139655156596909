import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '~/pages/auth/Login';
import SignUp from '~/pages/auth/SignUp';
import Nodata from '~/pages/Nodata';
import Dashboard from '~/pages/student/Dashboard';
import ForgotPassword from '~/pages/auth/ForgotPassword';
import ResetPassword from '~/pages/auth/ResetPass';
import EditAccount from '~/pages/account/EditAccount';
import InformationAccount from '~/pages/account/InformationAccount';
import RequiredAuth from '~/routes/RequiredAuth';
import PublicProvider from './PublicProvider';
import BrowseCourses from '~/pages/student/BrowseCourses';
import LandingPage from '~/pages/Landing/LandingPage';
import Courses from '~/components/student/Courses';
import Quiz from '~/pages/student/quiz/Quiz';
import TakeQuiz from '~/pages/student/quiz/TakeQuiz';
import QuizSumResults from '~/pages/student/quiz/QuizSumResults';
import MyCourses from '~/pages/student/MyCourses';
import QuizResultDetail from '~/pages/student/quiz/QuizResultDetail';
import InstuctorDashboard from '~/pages/instructor/Dashboard';
import CourseManager from '~/pages/instructor/course/CourseManager';
import CourseEdit from '~/pages/instructor/course/CourseEdit';
import Cart from '~/pages/student/cart/Cart';
import QuizManager from '~/pages/instructor/quiz/QuizManager';
import QuizEdit from '~/pages/instructor/quiz/QuizEdit';
import QuizReview from '~/pages/instructor/quiz/QuizReview';
import RequiredAuthRole from './RequiredAuthRole';
import CourseAdd from '~/pages/instructor/course/CourseAdd';
import LandingCourse from '~/pages/Landing/LandingCourse';
import Statement from '~/pages/instructor/statement/Statement';
import Earning from '~/pages/instructor/earnings/Earnings';
import QuizReviewDetail from '~/pages/instructor/quiz/QuizReviewDetail';
import QuizInstDetail from '~/pages/instructor/quiz/QuizDetail';
import QuizAdd from '~/pages/instructor/quiz/QuizAdd';
import ManagerAccount from '~/pages/account/ManagerAccount';

function RootRouter() {
    return (
        <Routes>
            <Route element={<PublicProvider />}>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/landing" element={<LandingPage />} />
                <Route path="/landing/:slug" element={<LandingCourse />} />
            </Route>
            <Route element={<RequiredAuth />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/student-dashboard" element={<Dashboard />} />

                {/* Account page*/}

                <Route path="edit-account" element={<EditAccount />} />
                <Route path="account-information" element={<InformationAccount />} />
                {/* End account page*/}

                {/* Course page*/}

                <Route path="student-browse-courses" element={<BrowseCourses />} />
                <Route path="student-my-course" element={<MyCourses />} />
                <Route path="student-view-course/:slug" element={<Courses />} />

                {/* End course page*/}

                {/* View a quiz page*/}

                <Route path="student-take-quiz" element={<Quiz />} />

                <Route path="student-take-quiz/:slug/:quizid" element={<TakeQuiz />} />

                {/* End view a quiz page*/}

                {/* Quiz result page */}

                <Route path="student-quiz-results" element={<QuizSumResults />} />

                <Route path="student-quiz-results/:slug/:quizid" element={<QuizResultDetail />} />

                {/* End Quiz result page */}

                <Route path="my-cart" element={<Cart />} />

                <Route element={<RequiredAuthRole />}>
                    <Route path="/instructor-dashboard" element={<InstuctorDashboard />} />

                    <Route path="/instructor-courses-manager" element={<CourseManager />} />

                    <Route path="/instructor-courses-manager/:slug" element={<CourseEdit />} />

                    <Route path="/instructor-courses-manager/course-add" element={<CourseAdd />} />

                    <Route path="/instructor-quiz-manager" element={<QuizManager />} />

                    <Route path="/instructor-quiz-manager/quiz-edit/:slug" element={<QuizInstDetail />} />

                    <Route path="/instructor-quiz-manager/quiz-edit/:slug/:quizid" element={<QuizEdit />} />

                    <Route path="/instructor-quiz-manager/quiz-edit/:slug/quiz-add" element={<QuizAdd />} />

                    <Route path="/instructor-quiz-manager/quiz-review/:slug" element={<QuizReview />} />

                    <Route path="/instructor-quiz-manager/quiz-review/:slug/:quizid" element={<QuizReviewDetail />} />

                    <Route path="/instructor-statement" element={<Statement />} />

                    <Route path="/instructor-earning" element={<Earning />} />

                    <Route path="/manager-account" element={<ManagerAccount />} />
                </Route>

                <Route path="/*" element={<Nodata />} />
                <Route path="my-cart" element={<Cart />} />

                <Route path="/*" element={<Nodata />} />
            </Route>
            <Route path="/*" element={<RequiredAuth />} />
        </Routes>
    );
}

export default RootRouter;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import quizzesApi from '~/api/quizzesApi';

export const getQuizzes = createAsyncThunk('courses/getquizzes', async (payload) => {
    try {
        const response = await quizzesApi.getQuizzes(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const getQuiz = createAsyncThunk('courses/getquiz', async (payload) => {
    try {
        const response = await quizzesApi.getQuiz(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const getQuestions = createAsyncThunk('courses/getquestions', async (payload) => {
    try {
        const response = await quizzesApi.getQuestions(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const updateQuizResult = createAsyncThunk('courses/updateresult', async (payload) => {
    try {
        const response = await quizzesApi.updateQuizResult(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const getQuestionsResult = createAsyncThunk('courses/getQuestionsResult', async (payload) => {
    try {
        const response = await quizzesApi.getQuestionsResult(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const resetQuiz = createAsyncThunk('courses/resetquiz', async (payload) => {
    try {
        const response = await quizzesApi.resetQuiz(payload);
        return response;
    } catch (e) {
        return e;
    }
});

const quizSlice = createSlice({
    name: 'quiz',
    initialState: {
        current: null,
        settings: {},
    },
    reducers: {},
    extraReducers: {},
});

const { actions, reducer } = quizSlice;

export default reducer;

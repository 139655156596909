import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dataLine, dataRadar, lineOptions, RadarOptions } from '~/assets/data/chartData';
import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import LineChart from '~/components/chart/LineChart';
import RadarChart from '~/components/chart/RadarChart';
import Helmet from '~/components/Helmet';
import PageTitle from '~/components/page/PageTitle';
import { getMyCourses } from '~/features/Courses/courseSlice';
import { getQuizzes } from '~/features/Quiz/quizSlice';
import { useAuth } from '~/routes/AuthProvider';
import { RedirectHome } from '~/helpers/Hook';
import { Link, useNavigate } from 'react-router-dom';

function Dashboard(props) {
    const dispatch = useDispatch();
    const [courses, setCourses] = useState('');
    const [quizzes, setQuizzes] = useState('');
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(RedirectHome(), { replace: true });
    }, []);

    const fetchCourses = async (url) => {
        const response = await dispatch(getMyCourses(url));
        const responseData = unwrapResult(response);
        const course = responseData.data;
        if (responseData.success) if (course.data.length > 0) setCourses(course.data.slice(0, 3));
    };

    const fetchQuizzes = async (url) => {
        const response = await dispatch(getQuizzes(url));
        const responseData = unwrapResult(response);
        const quiz = responseData.data;
        if (responseData.success) if (quiz.data.length > 0) setQuizzes(quiz.data.slice(0, 4));
    };

    useEffect(() => {
        if (auth.token) {
            fetchCourses();
            fetchQuizzes();
        }
    }, [auth.token]);

    const calculateProgress = (data) => {
        const arr = [...data];
        const totalLessons = arr.length;
        let count = 0;
        arr.map((data, key) => {
            if (data.status) count++;
        });
        if (count === 0) return { progress: 0, total: totalLessons, count: count };

        const progress = Math.floor((count * 100) / totalLessons);

        return { progress: progress, total: totalLessons, count: count };
    };

    const quizProgress = (total, completed) => {
        if (completed === 0) return 0;
        const progress = Math.floor((completed * 100) / total);
        return progress;
    };

    const page = [{ title: 'Dashboard', active: true }];

    return (
        <Helmet title="Student Dashboard">
            <Breadcrumb page={page} />
            <PageTitle title={'dashboard'}></PageTitle>

            <div className="row">
                <div className="col-lg-7">
                    <Card
                        bestScore={166}
                        title={'Angular'}
                        subtitle={'Best score'}
                        classHeader={'d-flex align-items-center'}
                        classBody={'mx-auto mh-mw-75'}
                        rightComponet={
                            <div className="dropdown">
                                <a href="#" className="dropdown-toggle text-black-70" data-toggle="dropdown">
                                    Popular Topics
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a href="" className="dropdown-item">
                                        Popular Topics
                                    </a>
                                    <a href="" className="dropdown-item">
                                        Web Design
                                    </a>
                                </div>
                            </div>
                        }
                    >
                        <RadarChart chartData={dataRadar} RadarOptions={RadarOptions} />
                    </Card>
                    <Card
                        title={'Courses'}
                        subtitle={'Your recent courses'}
                        classHeader={'d-flex align-items-center'}
                        classBody={'p-0'}
                        rightComponet={
                            <div className="media-right">
                                <Link className="btn btn-sm btn-primary" to="/student-my-course">
                                    My courses
                                </Link>
                            </div>
                        }
                    >
                        <ul className="list-group list-group-fit mb-0" style={{ zIndex: 'initial' }}>
                            {courses && courses ? (
                                courses.map((item, key) => {
                                    const progress = calculateProgress(item.lessons);
                                    const patch_url = '/student-view-course/' + item.slug;
                                    return (
                                        <li key={key} className="list-group-item" style={{ zIndex: 'initial' }}>
                                            <div className="d-flex align-items-center">
                                                <Link to={patch_url} className="avatar avatar-4by3 avatar-sm mr-3">
                                                    <img
                                                        src={item.image_thumbnail}
                                                        alt="course"
                                                        className="avatar-img rounded"
                                                    />
                                                </Link>
                                                <div className="flex">
                                                    <Link to={patch_url} className="text-body">
                                                        <strong>{item.name}</strong>
                                                    </Link>
                                                    <div className="d-flex align-items-center">
                                                        <div className="progress" style={{ width: '100px' }}>
                                                            <div
                                                                className="progress-bar bg-primary"
                                                                role="progressbar"
                                                                style={{ width: progress.progress }}
                                                                aria-valuenow={progress.progress}
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                        <small className="text-muted ml-2">{progress.progress}%</small>
                                                    </div>
                                                </div>
                                                <div className="dropdown ml-3">
                                                    <a
                                                        href="#"
                                                        className="dropdown-toggle text-muted"
                                                        data-caret="false"
                                                        data-toggle="dropdown"
                                                    >
                                                        <i className="material-icons">more_vert</i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link to={patch_url} className="dropdown-item">
                                                            Continue
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                            ) : (
                                <div className="h2 text-center p-3">You don't have any courses yet!</div>
                            )}
                        </ul>
                    </Card>
                </div>
                <div className="col-lg-5">
                    <Card
                        bestScore={432}
                        title={'Experience IQ'}
                        subtitle={'4 days streak this week'}
                        classHeader={'d-flex align-items-center'}
                        rightComponet={<i className="material-icons text-muted ml-2">trending_up</i>}
                    >
                        <LineChart chartData={dataLine} lineOptions={lineOptions} />
                    </Card>
                    <Card
                        title={'Rewards'}
                        subtitle={'YOUR LATEST ACHIEVEMENTS'}
                        classHeader={'d-flex align-items-center'}
                        classBody={'p-0'}
                        cardClass={'card-2by1'}
                    >
                        <div className="card-body text-center d-flex align-items-center justify-content-center">
                            <div className="btn btn-primary btn-circle mx-1">
                                <i className="material-icons">thumb_up</i>
                            </div>
                            <div className="btn btn-danger btn-circle mr-1">
                                <i className="material-icons">grade</i>
                            </div>
                            <div className="btn btn-success btn-circle mr-1">
                                <i className="material-icons">bubble_chart</i>
                            </div>
                            <div className="btn btn-warning btn-circle mr-1">
                                <i className="material-icons">keyboard_voice</i>
                            </div>
                            <div className="btn btn-info btn-circle mr-1">
                                <i className="material-icons">event_available</i>
                            </div>
                        </div>
                    </Card>
                    <Card
                        title={'Quizzes'}
                        subtitle={'Your Performance'}
                        classHeader={'d-flex align-items-center'}
                        classBody={'p-0'}
                        rightComponet={
                            <div className="media-right">
                                <Link className="btn btn-sm btn-primary" to="/student-quiz-results">
                                    Quiz results
                                </Link>
                            </div>
                        }
                    >
                        <ul className="list-group list-group-fit mb-0">
                            {quizzes && quizzes ? (
                                quizzes.map((item, key) => {
                                    const progress = quizProgress(item.quiz.length, item.completed);
                                    const path_url = '/student-quiz-results';
                                    return (
                                        <li key={key} className="list-group-item">
                                            <div className="media align-items-center">
                                                <Link to={path_url} className="avatar avatar-4by3 avatar-sm mr-3 mb-0">
                                                    <img
                                                        src={item.image_thumbnail}
                                                        alt="course"
                                                        className="avatar-img rounded"
                                                    />
                                                </Link>
                                                <div className="media-body">
                                                    <Link to={path_url} className="text-body mb-0">
                                                        <strong>{item.name}</strong>
                                                    </Link>
                                                    <div className="d-flex align-items-center">
                                                        <small className="text-muted">
                                                            {'Quizzes: ' + item.completed + ' of ' + item.quiz.length}
                                                        </small>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="progress" style={{ width: '100px' }}>
                                                            <div
                                                                className="progress-bar bg-primary"
                                                                role="progressbar"
                                                                style={{ width: progress + '%' }}
                                                                aria-valuenow={progress}
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                        <small className="text-muted ml-2">{progress}%</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                            ) : (
                                <div className="h2 text-center p-3">You don't have any quizzes yet!</div>
                            )}
                        </ul>
                    </Card>
                </div>
            </div>
        </Helmet>
    );
}

export default Dashboard;

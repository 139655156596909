export const StudentNavbarData = [
    {
        label: 'Student Dashboard',
        icon: 'dashboard',
        url: '/student-dashboard',
    },
    {
        label: ' Browse Courses',
        icon: 'search',
        url: '/student-browse-courses',
    },
    {
        label: 'My Courses',
        icon: 'school',
        url: '/student-my-course',
    },
    {
        label: 'Take a Quiz',
        icon: 'dvr',
        url: '/student-take-quiz',
    },
    {
        label: 'Quiz Results',
        icon: 'poll',
        url: '/student-quiz-results',
    },
]

export const InstructorNavbarData = [
    {
        label: 'Instructor Dashboard',
        icon: 'dashboard',
        url: '/instructor-dashboard',
    },
    {
        label: 'Courses Manager',
        icon: 'import_contacts',
        url: '/instructor-courses-manager',
    },
    {
        label: 'Quiz Manager',
        icon: 'help',
        url: '/instructor-quiz-manager',
    },
    {
        label: 'Earnings',
        icon: 'trending_up',
        url: '/instructor-earning',
    },
    {
        label: 'Statement',
        icon: 'receipt',
        url: '/instructor-statement',
    },
]
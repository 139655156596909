import StorageKeys from '../constants/storage-keys';
import axiosClient from './axiosClient';

const userApi = {
    async register(data) {
        const url = 'api/register/';
        const response = await axiosClient.post(url, data);
        return response.data;
    },
    async login(data) {
        const url = 'api/login/';
        const response = await axiosClient.post(url, data);
        return response.data;
    },
    async loginWithGoogle(data) {
        const url = 'api/google-login/';
        const response = await axiosClient.post(url, data);
        return response.data;
    },
    async forgotPassword(data) {
        const url = 'api/forgot-password/';
        const response = await axiosClient.post(url, data);
        return response.data;
    },
    async resetPassword(data) {
        const url = 'api/reset-password/';
        const response = await axiosClient.post(url, data);
        return response.data;
    },
    async managerPermission(data) {
        let url = 'api/manager-permission/';
        const accessToken = localStorage.getItem(StorageKeys.access);
        if (data) url = url + data;
        const response = await axiosClient.get(
            url,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );
        return response.data;
    },
    async updateUser(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/update/user';

        let formdata = new FormData();
        formdata.append('image', data.image[0]);
        formdata.append('name', data.name);
        formdata.append('about', data.about);

        const response = await axiosClient.post(url, formdata, {
            headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' },
        });
        return response.data;
    },
    async updatePassword(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/update/user';
        const response = await axiosClient.post(url, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    },
    async updatePermission(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/update-permission';
        const response = await axiosClient.post(url, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    },

    async getUser(params) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/user/';
        const response = await axiosClient.post(
            url,
            {},
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },
};

export default userApi;


export default function FormReply(props) {

    return (
        <form action="#" id="message-reply">
            <div className="input-group input-group-merge">
                <input type="text" className="form-control form-control-appended" required placeholder="Quick Reply" />
                <div className="input-group-append">
                    <div className="input-group-text pr-2">
                        <button className="btn btn-flush" type="button"><i className="material-icons">tag_faces</i></button>
                    </div>
                    <div className="input-group-text pl-0">
                        <div className="custom-file custom-file-naked d-flex" style={{ width: '24px', overflow: 'hidden' }}>
                            <input type="file" className="custom-file-input" id="customFile" />
                            <label className="custom-file-label" style={{ color: 'inherit' }} htmlFor="customFile">
                                <i className="material-icons">attach_file</i>
                            </label>
                        </div>
                    </div>
                    <div className="input-group-text pl-0">
                        <button className="btn btn-flush" type="button"><i className="material-icons">send</i></button>
                    </div>
                </div>
            </div>
        </form>
    )
}
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import Helmet from '~/components/Helmet';
import { updateUser } from '~/features/Auth/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import PageTitle from '~/components/page/PageTitle';
import avatar from '~/assets/images/people/50/no-data.png';

function EditAccount(props) {
    const dataState = useSelector((state) => state.user);

    const [dataUser, setDataUser] = useState('');

    const [thumbnail, setThumbnail] = useState('');

    useEffect(() => {
        setDataUser(dataState.current);
    }, [dataState]);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    });

    const dataNav = [
        {
            title: 'Account',
            link: 'first',
        },
        {
            title: 'Billing',
            link: 'second',
        },
    ];

    const dispatch = useDispatch();

    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        try {
            const reps = await dispatch(updateUser(data));
            const dataReps = unwrapResult(reps);
            if (dataReps.success) {
                setDataUser(dataReps.data);
                swalWithBootstrapButtons.fire({
                    title: 'Save Successfully!',
                    icon: 'success',
                });
            } else {
                swalWithBootstrapButtons.fire({
                    title: dataReps.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    register('image', {
        onChange: (e) => {
            setThumbnail(URL.createObjectURL(e.target.files[0]));
        },
    });

    const page = [{ title: 'Edit Account', active: true }];

    return (
        <div>
            <Helmet title="Edit Account">
                <div className="col-lg-11 container-fluid page__container">
                    <Breadcrumb page={page} />
                    <PageTitle title={'edit account'}></PageTitle>
                    <Card cardNav={dataNav}>
                        <div className="tab-pane active" id="first">
                            <form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
                                <div className="row">
                                    <div className="col-md-12 col-lg-4 col-xl-3 d-flex flex-column align-items-center card py-4 px-3 mb-3 mb-lg-0">
                                        <div style={{ width: '260px', height: '260px' }} className="avatar-img rounded-circle mb-3">
                                            <div className='d-inline'>
                                                <img style={{ width: '260px', height: '260px', objectFit: "cover" }} src={thumbnail ? thumbnail : (!dataUser.image ? avatar : dataUser.image)} className="img-fluid rounded-circle" />
                                                <div className="btn btn-primary btn-circle p-2 position-relative" role="button" style={{ bottom: '20%', left: "80%" }}>
                                                    <i className="fas fa-camera" role="button" />
                                                    <input
                                                        type="file"
                                                        id="avatar"
                                                        className="custom-file-input"
                                                        {...register('image')}
                                                        name="image"
                                                        accept="image/*"
                                                        style={{ position: 'absolute', opacity: 0, cursor: "pointer", maxHeight: '35px', maxWidth: '35px' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <label className="text-center font-size-20pt text-uppercase">
                                            Avatar
                                        </label>
                                    </div>
                                    <div className="col-md-12 col-lg-8 col-xl-9 px-0">
                                        <div className="card py-4 px-3 ml-0 ml-lg-4 mb-0 h-100">
                                            <div className="form-group column">
                                                <label htmlFor="name" className="col-sm-3 col-form-label text-uppercase font-weight-bold text-black font-size-16pt">
                                                    Full Name
                                                </label>
                                                <div className="col-sm-10">
                                                    <input
                                                        {...register('name')}
                                                        name="name"
                                                        type="text"
                                                        className="form-control w-100"
                                                        placeholder="Full Name"
                                                        defaultValue={dataUser.name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group column">
                                                <label htmlFor="email" className="col-sm-3 col-form-label text-uppercase font-weight-bold text-black font-size-16pt">
                                                    Email
                                                </label>
                                                <div className="col-sm-10 col-md-10">
                                                    <div className="input-group w-100">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                <i className="material-icons md-18 text-muted">mail</i>
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            id="email"
                                                            className="form-control"
                                                            placeholder="Email Address"
                                                            defaultValue={dataUser.email}
                                                            style={{ backgroundColor: '#f5f6f700' }}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group column">
                                                <label htmlFor="about" className="col-sm-3 col-form-label text-uppercase font-weight-bold text-black font-size-16pt">
                                                    About you
                                                </label>
                                                <div className="col-sm-10 col-md-10">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">
                                                                <i className="material-icons md-18 text-muted">info</i>
                                                            </div>
                                                        </div>
                                                        <textarea
                                                            id="about"
                                                            placeholder="About you ..."
                                                            rows={3}
                                                            className="form-control scroll"
                                                            defaultValue={dataUser.about}
                                                            {...register('about')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group column">
                                                <div className="col-sm-10">
                                                    <div className="media-body pb-3">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                id="subscribe"
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                defaultChecked
                                                            />
                                                            <label htmlFor="subscribe" className="custom-control-label">
                                                                Subscribe to Newsletter
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="media-left">
                                                        <button type="submit" className="btn btn btn-primary w-50">
                                                            <span className="indicator-label text-capitalize">Save changes</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="tab-pane" id="second">
                            <div className="row">
                                <div className="col-lg-6 card px-4 py-2 mb-lg-0">
                                    <form action="#" className="form-horizontal">
                                        <div className="form-group row">
                                            <label htmlFor="name_on_invoice" className="col-form-label text-black font-size-16pt font-weight-bold">
                                                Name on Invoice
                                            </label>
                                            <input
                                                id="name_on_invoice"
                                                type="text"
                                                className="form-control"
                                                defaultValue="Adrian Demian"
                                            />
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="billing_address" className="col-form-label text-black font-size-16pt font-weight-bold">
                                                Address
                                            </label>
                                            <input
                                                id="billing_address"
                                                type="text"
                                                className="form-control"
                                                defaultValue="Sunny Street, 21, Miami, Florida"
                                            />
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="billing_country" className="col-form-label text-black font-size-16pt font-weight-bold">
                                                Country
                                            </label>
                                            <select
                                                id="billing_country"
                                                className="custom-control custom-select form-control"
                                                defaultValue={1}
                                            >
                                                <option value={1}>USA</option>
                                                <option value={2}>India</option>
                                                <option value={3}>United Kingdom</option>
                                            </select>
                                        </div>
                                        <div className="form-group row">
                                            <div className="">
                                                <a href="#" className="btn btn-primary">
                                                    {' '}
                                                    Update Billing
                                                </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-6 mb-sm-0 px-0 pl-lg-3 mt-3 mt-lg-0">
                                    <div className="card px-4 py-2 h-100">
                                        <div className="card-header">
                                            <div className="media align-items-center">
                                                <div className="media-body">
                                                    <h4 className="card-title">Payment Info</h4>
                                                </div>
                                                <div className="media-right">
                                                    <a href="#" className="btn btn-sm btn-outline-primary">
                                                        <i className="material-icons">add</i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="card-footer p-0 list-group list-group-fit">
                                            <li className="list-group-item active rounded border shadow mb-3">
                                                <div className="media align-items-center">
                                                    <div className="media-left">
                                                        <span className="btn btn-primary btn-circle">
                                                            <i className="material-icons">credit_card</i>
                                                        </span>
                                                    </div>
                                                    <div className="media-body">
                                                        <p className="mb-0">**** **** **** 2422</p>
                                                        <small>Updated on 12/02/2016</small>
                                                    </div>
                                                    <div className="media-right">
                                                        <a href="#" className="btn btn-primary btn-sm">
                                                            <i className="material-icons btn__icon--left">edit</i> EDIT
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item rounded border shadow">
                                                <div className="media align-items-center">
                                                    <div className="media-left">
                                                        <span className="btn btn-white btn-circle">
                                                            <i className="material-icons">credit_card</i>
                                                        </span>
                                                    </div>
                                                    <div className="media-body">
                                                        <p className="mb-0">**** **** **** 6321</p>
                                                        <small className="text-muted">Updated on 11/01/2016</small>
                                                    </div>
                                                    <div className="media-right">
                                                        <a href="#" className="btn btn-white btn-sm">
                                                            <i className="material-icons btn__icon--left">edit</i> EDIT
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card >
                </div>
            </Helmet >
        </div >
    );
}

export default EditAccount;

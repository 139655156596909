import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import Layout from './components/Layout';
import './assets/css/app.css';
import './assets/css/spinkit.css';
import './assets/css/fontawesome.css';
import './assets/css/material-icons.css';
import './assets/boxicons-2.0.7/css/boxicons.min.css';
import '~/assets/css/landingpage.css';
import 'bootstrap';

import AuthProvider from '~/routes/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter>
                <AuthProvider>
                    <Layout />
                </AuthProvider>
            </BrowserRouter>
        </React.StrictMode>
    </Provider>,
);
reportWebVitals();

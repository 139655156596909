import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import categoriesApi from '~/api/categoriesApi';

export const index = createAsyncThunk('categories/index', async () => {
    try {
        const response = await categoriesApi.index();
        return response;
    } catch (e) {
        return e;
    }
});

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        current: null,
        message: '',
        settings: {},
    },
    reducers: {},
    extraReducers: {
        [index.fulfilled]: (state, action) => {
            state.current = action.payload.data;
        },
    },
});

const { actions, reducer } = categoriesSlice;

export default reducer;

export const RedirectHome = () => {
    const isNav = localStorage.getItem('isDashBoard');

    if (isNav == 'Student') {
        return '/student-dashboard';
    } else if (isNav == 'Instructor') {
        return '/instructor-dashboard';
    } else {
        return '/student-dashboard';
    }
};

export const resizeCustom = (className, checked) => {
    if (window.innerWidth > 992) {
        document.getElementById(className).setAttribute('data-persistent', 'true');
        document.getElementById(className).setAttribute('data-opened', 'true');
    } else {
        document.getElementById(className).removeAttribute('data-persistent');
        document.getElementById(className).removeAttribute('data-opened');
    }
    window.addEventListener('resize', () => {
        if (window.innerWidth > 992) {
            document.getElementById(className).setAttribute('data-persistent', 'true');
            document.getElementById(className).setAttribute('data-opened', 'true');
        } else {
            document.getElementById(className).removeAttribute('data-persistent');
            document.getElementById(className).removeAttribute('data-opened');
        }
    });
    if (checked) {
        document.getElementById(className).setAttribute('data-opened', 'true');
    } else {
        document.getElementById(className).removeAttribute('data-opened');
    }
};

export const sidebarHideMobile = (className) => {
    if (window.innerWidth <= 992) {
        document.getElementById('default-drawer').removeAttribute('data-opened');
    }
};

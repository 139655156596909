import React, { useEffect, useState } from 'react';
import Header from '~/parts/Header';
import Sidebar from '~/parts/Sidebar';
import RootRouter from '~/routes/Routes';
import { getUser } from '~/features/Auth/userSlice';
import { useAuth } from '~/routes/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart, storeCart } from '~/features/Cart/cartSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { index } from '~/features/Categories/categoriesSlice';
import $ from 'jquery';

const Layout = () => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const [showSideBar, setShowSideBar] = useState(true);
    const [pageStyle, setPageStyle] = useState(true);
    const dataState = useSelector((state) => state.cart);

    if (window.innerWidth < 992) {
        $('.mdk-drawer__scrim').on('click', function (e) {
            setShowSideBar(!showSideBar);
        });
        $(".sidebar li.sidebar-menu-item a.sidebar-menu-button.sidebar-js-collapse").on('click', function () {
            setShowSideBar(!showSideBar);
        });
        $("ul.sidebar-submenu a.sidebar-menu-button").on('click', function () {
            setShowSideBar(!showSideBar);
        });
    }

    const handleClick = () => {
        setShowSideBar(!showSideBar);
    };
    useEffect(() => {
        if (window.innerWidth < 992) {
            setPageStyle(false);
            setShowSideBar(false);
        } else {
            setPageStyle(true);
            setShowSideBar(true);
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth < 992) {
                setPageStyle(false);
                setShowSideBar(false);
            } else {
                setPageStyle(true);
                setShowSideBar(true);
            }
        });
    }, []);

    useEffect(() => {
        if (auth.token) {
            getUserData(auth.token);
            setNewCart(auth.token);
            getCategories();
        }
    }, [auth.token]);

    const getCategories = async () => {
        const response = await dispatch(index());
        const responseData = unwrapResult(response);
    };

    const setNewCart = async (data) => {
        const response = await dispatch(storeCart(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            localStorage.setItem('cartData', JSON.stringify(responseData.data));
            const cartData = dispatch(fetchCart());
        }
    };

    const getUserData = async (data) => {
        const response = await dispatch(getUser(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            const timer = setTimeout(() => {
                auth.signin(responseData.data);
            }, 1000);
            return () => clearTimeout(timer);
        }
    };

    if (auth.token) {
        return (
            <div className="mdk-header-layout js-mdk-header-layout" data-domfactory-upgraded="mdk-header-layout">
                {auth.user ? (
                    <>
                        <Header handleClick={() => handleClick()} />

                        <div className="mdk-header-layout__content">
                            <div className="mdk-drawer-layout js-mdk-drawer-layout">
                                <Sidebar showSideBar={showSideBar} />
                                <div
                                    className="mdk-drawer-layout__content page"
                                    style={
                                        pageStyle
                                            ? showSideBar
                                                ? { transform: 'translate3d(0px, 0px, 0px)' }
                                                : { transform: 'translate3d(-120px, 0px, 0px)' }
                                            : { transform: 'translate3d(0px, 0px, 0px)' }
                                    }
                                >
                                    <div className="container-fluid page__container px-3 px-md-5" >
                                        <RootRouter />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="preloader">
                        <div className="sk-chase">
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                        </div>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div>
                <RootRouter />
            </div>
        );
    }
};

export default Layout;

import React from 'react';

function Preloader(props) {
    return (
        <div
            className="preloader"
            style={{ top: '0', left: '0', right: '0', bottom: '0', height: '94vh', backgroundColor: '#222357' }}
        >
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </div>
    );
}

export default Preloader;

import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import Helmet from '~/components/Helmet';
import Pagination from '~/components/Pagination';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import PageTitle from '~/components/page/PageTitle';
import { dataSoftFilter } from '~/assets/data/InstructorDashData';
import SortFilter from '~/components/filter/SortFilter';
import { useState, useEffect } from 'react';
import { show } from '~/features/ManageCourses/coursesManageSlice';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

export default function Statement() {
    const dispatch = useDispatch();

    const page = [{ title: 'Statement', active: true }];

    const [courses, setCourses] = useState();

    const location = useLocation();

    const navigate = useNavigate();

    const fetchData = async (data) => {
        const response = await dispatch(show(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            setCourses(responseData.data);
        }
    }

    const handleSubmitUrl = (url) => {
        let paramString = url.split('=')[1];

        const params = new URLSearchParams(location.search);

        params.set('page', paramString);

        navigate('?' + params.toString(), { replace: true });
    };

    useEffect(() => {
        fetchData(location.search);
    }, [location.search]);

    return (
        <Helmet title="Statement">
            <Breadcrumb page={page} />
            <PageTitle
                title={'Statement'}
            />
            <div className="row">
                <div className="col-lg-9 container-fluid">
                    <Card
                        classHeader="form-inline"
                        rightComponet={
                            <div className="form-group ml-auto">
                                <label className="form-label mr-3" htmlFor="flatpickrSample02">Date</label>
                                <input
                                    id="flatpickrSample02"
                                    type="text"
                                    className="form-control w-100"
                                    placeholder="Select dates"
                                    data-toggle="flatpickr"
                                    defaultValue="2018-10-07 to 2018-10-15" />
                            </div>
                        }
                        classBody={'p-0 table-responsive'}
                    >
                        <div data-toggle="lists" className="table-responsive">
                            <table className="table table-nowrap mb-0">
                                <SortFilter threadClass={'thead-light'} data={dataSoftFilter} />
                                <tbody className="list">
                                    {courses && courses.data.map((item, index) => {
                                        const path = '/instructor-courses-manager/' + item.slug;
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div className="media align-items-center">
                                                        <Link
                                                            to={path}
                                                            className="avatar avatar-4by3 avatar-sm mr-3"
                                                        >
                                                            <img
                                                                src={item.image_thumbnail}
                                                                alt="course"
                                                                className="avatar-img rounded"
                                                            />
                                                        </Link>
                                                        <div className="media-body">
                                                            <Link
                                                                to={path}
                                                                className="text-body js-lists-values-course"
                                                            >
                                                                <strong>{item.name}</strong>
                                                            </Link>
                                                            <br />
                                                            <small className="text-muted mr-1">
                                                                Invoice#
                                                                <Link
                                                                    to={path}
                                                                    className="js-lists-values-document text-muted"
                                                                >
                                                                    8736
                                                                </Link>
                                                                &nbsp;- $
                                                                <span className="js-lists-values-amount">89</span>
                                                                &nbsp;USD
                                                            </small>
                                                            <small className="text-muted text-uppercase js-lists-values-date d-block d-sm-none mt-2 mt-lg-0">
                                                                14 Nov 2018
                                                            </small>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    <small className="text-muted text-uppercase js-lists-values-date d-none d-sm-block">
                                                        14 Nov 2018
                                                    </small>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </div>
                {courses && (
                    <div className="col-lg-12">
                        <Pagination
                            links={courses.links}
                            handleSubmitUrl={handleSubmitUrl}
                            currentPage={courses.current_page}
                        />
                    </div>
                )}
            </div>
        </Helmet>
    )
}
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getCourse, lessonCompleted } from '~/features/Courses/courseSlice';
import ReactPlayer from 'react-player';
import Preloader from '../Preloader';
import LessonItems from './LessonItems';
import { addToCart, fetchCart } from '~/features/Cart/cartSlice';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { RedirectHome } from '~/helpers/Hook';

function Courses(props) {
    const params = useParams();

    const [course, setCourses] = useState('');

    const [message, setMessage] = useState({ message: '', success: true });

    const [video, setVideo] = useState({ url: '', lesson_id: '', active: '', next: '' });

    const [indexNext, setIndexNext] = useState('');

    const dispatch = useDispatch();

    const dataState = useSelector((state) => state.cart);

    const [dataItems, setDataItems] = useState('');

    useEffect(() => {
        const fetchCourses = async (params) => {
            const response = await dispatch(getCourse(params));
            const responseData = unwrapResult(response);
            setCourses(responseData.data);
        };

        if (params.slug) {
            fetchCourses({ slug: params.slug });
        }

        setDataItems(dataState.current);
        console.log(video);
    }, [indexNext, params, dataState]);

    const load = (url, id, key) => {
        setVideo({ url: url, lesson_id: id, active: key });
    };

    const onEndedVideo = async () => {
        if (video.lesson_id) {
            const response = await dispatch(lessonCompleted(video.lesson_id));
            const responseData = unwrapResult(response);
            if (responseData.success) {
                setIndexNext(video.lesson_id);
                if (course.lessons) {
                    var breakPoint = 1000;
                    course.lessons.map((val, index) => {
                        if (val.id === video.lesson_id) {
                            breakPoint = index;
                        }
                        if (index === breakPoint + 1) {
                            load(val.video, val.id, video.active + 1);
                            setVideo({ url: val.video, lesson_id: val.id, active: index + 1 });
                        }
                    });
                }
            }
        }
    };

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 3000,
    });

    const addCoureToCart = async (coursesId) => {
        const response = await dispatch(addToCart(coursesId));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            const response = await dispatch(fetchCart());
            const responseData = unwrapResult(response);
            swalWithBootstrapButtons.fire({
                title: 'Add To Cart Successfully!',
                icon: 'success',
            });
        } else {
            swalWithBootstrapButtons.fire({
                title: responseData.message,
                icon: 'error',
            });
        }
    };

    return (
        <div>
            {!course && <Preloader />}
            {course && (
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={RedirectHome()}>Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/student-browse-courses">Courses</Link>
                        </li>
                        <li className="breadcrumb-item active">{course.name}</li>
                    </ol>
                    <h1 className="h2">{course.name}</h1>
                    {!course.my_course && (
                        <div className="row pb-5">
                            <div className="col-md-8">
                                <div className="card mb-0 h-100">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-2 mb-3 mb-lg-0 px-0">
                                                <div className="">
                                                    {course.instructor_image && (
                                                        <img
                                                            src={course.instructor_image}
                                                            alt="About Instructor"
                                                            className="img-fluid img-responsive rounded-xl"
                                                            style={{ objectFit: 'cover' }}
                                                        />
                                                    )}
                                                    {!course.instructor_image && (
                                                        <img alt="About Instructor" className="img-fluid" />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-7 mb-4 mb-md-0 d-flex flex-column align-items-start justify-content-end">
                                                <div className="">
                                                    <div className="media-body">
                                                        <h4 className="card-title">
                                                            <p>{course.instructor_name}</p>
                                                        </h4>
                                                        <p className="card-subtitle">Instructor</p>
                                                    </div>
                                                    <p className="mb-3 mb-md-5">{course.instructor_about}</p>
                                                    <div className="d-flex justify-content-start">
                                                        <span className="mr-4 mt-2" type="button">
                                                            <i className="fab fa-facebook font-size-32pt" />
                                                        </span>
                                                        <span className="mr-4 mt-2" type="button">
                                                            <i className="fab fa-twitter font-size-32pt" />
                                                        </span>
                                                        <span className="mr-4 mt-2" type="button">
                                                            <i className="fab fa-github font-size-32pt" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 d-flex flex-column align-items-start justify-content-end">
                                                <div>
                                                    <ul className="list-group list-group-fit list-unstyled mb-2">
                                                        <li className="">
                                                            <div className="media-body p-0 font-size-16pt text-uppercase font-weight-bolder">
                                                                {course.level === 1 ? 'Beginner' : 'Advanced'}
                                                            </div>
                                                        </li>
                                                        <li className="">
                                                            <div className="media align-items-center font-size-16pt font-weight-bolder">
                                                                2<small className="text-muted"> &nbsp;hrs</small>
                                                                &nbsp; 26{' '}
                                                                <small className="text-muted"> &nbsp;min</small>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <h4 className="card-title font-size-16pt text-uppercase font-weight-bolder mb-2 mb-lg-0">
                                                        Ratings
                                                    </h4>
                                                    <div className="rating d-flex flex-row flex-md-column flex-lg-row align-items-center">
                                                        <div className="star-rating mr-2 mr-md-0 mr-lg-2">
                                                            <i className="material-icons">star</i>
                                                            <i className="material-icons">star</i>
                                                            <i className="material-icons">star</i>
                                                            <i className="material-icons">star</i>
                                                            <i className="material-icons">star_border</i>
                                                        </div>
                                                        <div className="number-rating">
                                                            <small className="text-muted">20 ratings</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {!course.my_course && (
                                    <div className="card p-5 mb-0 h-100">
                                        <div className="card-body text-center">
                                            {dataItems && dataItems.filter((item) => item.id === course.id).length ? (
                                                <p className="font-size-24pt font-weight-bolder mt-auto mb-auto text-muted">
                                                    <span> Added to Cart </span>
                                                    <br />
                                                    <strong>${course.price}</strong>
                                                    <span className={`btn btn-primary btn-block mt-3 disabled`}>
                                                        <i className="bx bx-cart-alt font-size-32pt"></i>&nbsp;Added To
                                                        Cart
                                                    </span>
                                                </p>
                                            ) : (
                                                <p className="font-size-24pt font-weight-bolder mt-auto mb-auto">
                                                    <span> Add to Cart </span>
                                                    <br />
                                                    <strong>${course.price}</strong>
                                                    <span
                                                        className={`btn btn-primary btn-block mt-3`}
                                                        type="button"
                                                        onClick={() => addCoureToCart(course.id)}
                                                    >
                                                        <i className="bx bx-cart-alt font-size-32pt"></i>&nbsp;Add To
                                                        Cart
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="row pb-5">
                        <div className="col-md-8">
                            <div className="embed-responsive embed-responsive-16by9 mb-0">
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    autoPlay={true}
                                    playing={true}
                                    className="embed-responsive-item"
                                    controls={true}
                                    playsinline={true}
                                    url={video.url ? video.url : course.demo_video}
                                    onEnded={() => onEndedVideo(video.key)}
                                />
                            </div>
                            <div className="card mb-lg-0">
                                {video.active && course.lessons ? (
                                    course.lessons.map((lesson, index) =>
                                        video.active === lesson.index_value ? (
                                            <div
                                                key={index}
                                                className="card-body"
                                                dangerouslySetInnerHTML={{ __html: lesson.description }}
                                            />
                                        ) : null,
                                    )
                                ) : (
                                    <div
                                        className="card-body"
                                        dangerouslySetInnerHTML={{ __html: course.description }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/* Lessons */}
                            {course.lessons && (
                                <LessonItems lesson={course.lessons} active={video.active} handelClick={load} />
                            )}
                            {/* Lessons */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Courses;

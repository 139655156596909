import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState, useEffect, useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateQuestion } from '~/features/ManageQuizzes/quizzesManageSlice';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { yupResolver } from '@hookform/resolvers/yup';

function FormQuestion(props) {

    const point = props.point ? props.point : '';

    const name = props.name ? props.name : '';

    const id = props.id ? props.id : '';

    const quizId = props.quizId ? props.quizId : '';

    const action = props.action ? props.action : 'add';

    const schema = props.schema ? props.schema : '';

    const index = props.index ? props.index : '';

    const answers = props.answers ? props.answers : [{ title: "", result: false }];

    const defaultValues = { answers };

    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {
        fields,
        append,
        remove
    } = useFieldArray({
        control,
        name: 'answers',
    });

    const dispatch = useDispatch();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 3000,
    });

    const onSubmit = async (data) => {
        const response = await dispatch(updateQuestion(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            props.setFetch()
            swalWithBootstrapButtons.fire({
                title: 'Save Successfully!',
                icon: 'success',
            })
            props.handlerClickPopup();
            props.handlerClickSetData();
        } else {
            swalWithBootstrapButtons.fire({
                title: responseData.message,
                icon: 'error',
            });
        }
    };

    return (
        <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                    <label htmlFor="qtitle" className="col-form-label form-label col-md-3">Title:</label>
                    <div className="col-md-9">
                        <input
                            type="text"
                            className={`form-control title-display-inline mb-0 ${errors.title ? 'is-invalid' : ''}`}
                            name='title'
                            defaultValue={name}
                            {...register('title')}
                        />
                        <div className="invalid-feedback">{errors.title?.message}</div>
                        <input
                            type="number"
                            hidden
                            className={`form-control title-display-inline mb-0`}
                            defaultValue={id}
                            {...action === 'add' ? '' : register('id')}
                        />
                        <input
                            type="number"
                            hidden
                            className={`form-control title-display-inline mb-0`}
                            defaultValue={quizId}
                            {...register('quiz_id')}
                        />
                        <input
                            type="number"
                            hidden
                            className="form-control title-display-inline"
                            defaultValue={index}
                            {...register('index_value')}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-form-label form-label col-md-3">Answers:</label>
                    <div className="col-md-9">
                        <span role='button' className={`btn btn-default mb-2 `} onClick={() => append({})}><i className="material-icons">add</i> Add Answer</span>
                        {fields.map((answers, index) => {
                            return (
                                <div className='media align-items-center px-2 py-4 border rounded mb-2' key={answers.id}>
                                    <div className="media-body">
                                        <input
                                            type="text"
                                            className={`form-control ${errors.answers?.[index]?.title ? "is-invalid" : ""}`}
                                            {...register(`answers[${index}].title`, { required: true })}
                                            control={control}
                                        />
                                        <div className="invalid-feedback">{errors.answers?.[index]?.title?.message}</div>
                                    </div>
                                    <div className="media-right text-center custom-control custom-checkbox">
                                        <input
                                            id={`answers[${index}].result`}
                                            type="checkbox"
                                            className={`position-relative ml-0 mr-2 form-check-input custom-control-input ${errors.answers?.[index]?.result ? "is-invalid" : ""}`}
                                            {...register(`answers[${index}].result`)}
                                            onClick={(e) => {
                                                if (e.target.checked) {
                                                    setValue(
                                                        `answers[${index}].result`,
                                                        true
                                                    );
                                                } else {
                                                    setValue(
                                                        `answers[${index}].result`,
                                                        false
                                                    );
                                                }
                                            }}
                                            control={control}
                                        />
                                        <label htmlFor={`answers[${index}].result`} className="custom-control-label my-2">
                                            Correct Answer
                                        </label>
                                        <div className="invalid-feedback">{errors.answers?.[index]?.result?.message}</div>
                                    </div>
                                    <div className="media-right">
                                        <span className="btn btn-danger btn-sm" onClick={() => remove(index)}>
                                            <i className="material-icons">delete</i>
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="touch-spin-2" className="col-form-label form-label col-md-3">Question Score:</label>
                    <div className="col-md-4">
                        <input
                            type="text"
                            defaultValue={point}
                            name="demo2"
                            className={`form-control ${errors.point ? 'is-invalid' : ''}`}
                            {...register('point')}
                        />
                        <div className="invalid-feedback">{errors.point?.message}</div>
                    </div>
                </div>
                <div className="modal-footer border-top">
                    <button type="submit" className="btn btn-success mb-2 mb-sm-0">
                        Save
                    </button>
                    <p
                        className="btn btn-danger ml-0 ml-sm-2 mb-2 mb-sm-0"
                        onClick={() => {
                            props.handlerClickPopup();
                            props.handlerClickSetData();
                        }}
                    >
                        Close
                    </p>
                </div>
            </form>
        </div>
    );
}

export default FormQuestion;

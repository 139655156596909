import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import Helmet from '~/components/Helmet';
import { useState } from 'react';
import 'react-nestable/dist/styles/index.css';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { updateCourse } from '~/features/ManageCourses/coursesManageSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import EditorComponent from '~/components/plugin/Editor';
import { useForm } from 'react-hook-form';
import PageTitle from '~/components/page/PageTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaAddCourse } from '~/helpers/validationSchema';
import { useLocation, useNavigate } from 'react-router-dom';

function CourseAdd(props) {
    const dataState = useSelector((state) => state.categories);

    const dispatch = useDispatch();

    const [video, setVideo] = useState('');

    const navigate = useNavigate();

    const location = useLocation();

    const [thumbnail, setThumbnail] = useState('');

    const page = [{ title: 'Add Course', active: true }];

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    });

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ mode: 'onChange', resolver: yupResolver(schemaAddCourse) });

    register('image', {
        onChange: (e) => {
            setThumbnail(URL.createObjectURL(e.target.files[0]));
        },
    });

    const onSubmit = async (data) => {
        const response = await dispatch(updateCourse(data));
        const responseData = unwrapResult(response);

        if (responseData.success) {
            swalWithBootstrapButtons.fire({
                title: 'Save Successfully!',
                icon: 'success',
            });
            
            const pathname = location.pathname.replace('course-add', responseData.data);

            navigate(pathname, { replace: true });
        } else {
            swalWithBootstrapButtons.fire({
                title: responseData.message,
                icon: 'error',
            });
        }
    };

    return (
        <Helmet title="Edit Course">
            <Breadcrumb page={page} />
            <PageTitle title={'Add Course'} button={'save'} handleSubmit={handleSubmit(onSubmit)} submit={true} />

            <form>
                <div className="row">
                    <div className="col-md-8">
                        <Card title={'Basic Information'} classHeader={'header'}>
                            <div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="title">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                        name="name"
                                        {...register('name')}
                                    />
                                    <div className="invalid-feedback">{errors.name?.message}</div>
                                    <input type="hidden" className="form-control" name="id" {...register('id')} />
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="title">
                                        Thumbnail
                                    </label>
                                    <div className="media-body">
                                        <div className="custom-file w-100">
                                            <input
                                                type="file"
                                                className={`custom-file-input ${errors.image ? 'is-invalid' : ''}`}
                                                name="image"
                                                accept="image/*"
                                                {...register('image')}
                                            />
                                            <label htmlFor="avatar" className="custom-file-label title-display-inline">
                                                Choose file
                                            </label>
                                            <div className="invalid-feedback">{errors.image?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="price">
                                        Price
                                    </label>
                                    <div className="input-group input-group-merge rounded">
                                        <input
                                            type="text"
                                            className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                                            name="price"
                                            {...register('price')}
                                        />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <span className="bx bx-dollar" />
                                            </div>
                                        </div>
                                        <div className="invalid-feedback">{errors.price?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group mb-0" style={{ height: '290px' }}>
                                    <label className="form-label">Description</label>
                                    <EditorComponent setValue={setValue} description="" />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-4">
                        <div className="embed-responsive embed-responsive-16by9 mb-0">
                            <ReactPlayer
                                width="100%"
                                height="100%"
                                autoPlay
                                playing={true}
                                className="embed-responsive-item"
                                url={video ? video : ''}
                                light={thumbnail ? thumbnail : ''}
                            />
                        </div>
                        <Card>
                            <input
                                type="text"
                                className={`form-control ${errors.video ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                    setVideo(e.target.value);
                                    setValue('video', e.target.value, { shouldValidate: true });
                                }}
                            />
                            <div className="invalid-feedback">{errors.video?.message}</div>
                        </Card>
                        <Card classHeader={'header'} title={'Meta'} subtitle={'Extra Options'}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="category">
                                    Category
                                </label>
                                <select
                                    className={`custom-select form-control ${errors.category ? 'is-invalid' : ''}`}
                                    name="category"
                                    {...register('category')}
                                >
                                    <option value="">Choose Category</option>
                                    {dataState.current &&
                                        dataState.current.map((val, key) => {
                                            return (
                                                <option key={key} value={val.id}>
                                                    {val.name}
                                                </option>
                                            );
                                        })}
                                </select>
                                <div className="invalid-feedback">{errors.category?.message}</div>
                            </div>
                        </Card>
                    </div>
                </div>
            </form>
        </Helmet>
    );
}
export default CourseAdd;

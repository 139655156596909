import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Pagination from '~/components/Pagination';
import Helmet from '~/components/Helmet';
import Breadcrumb from '~/components/Breadcrumb';
import { useDispatch } from 'react-redux';
import { getQuizzes, resetQuiz } from '~/features/Quiz/quizSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import PageTitle from '../page/PageTitle';
import Preloader from '../Preloader';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

function QuizViewAll(props) {

    const [course, setCourses] = useState('');

    const [fetch, setFetch] = useState(false);

    const location = useLocation();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const fetchCourses = async (data) => {
        const response = await dispatch(getQuizzes(data));
        const responseData = unwrapResult(response);
        setCourses(responseData.data);
    };

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    });

    const swalCustomButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mr-2',
            denyButton: 'btn btn-danger mr-2',
        },
        buttonsStyling: false,
    });


    useEffect(() => {
        fetchCourses(location.search);
    }, [location, fetch]);

    const handleSubmitUrl = (url) => {
        let paramString = url.split('=')[1];

        const params = new URLSearchParams(location.search);

        params.set('page', paramString);

        navigate('?' + params.toString(), { replace: true });
    };

    const calculateProgress = (total, completed) => {
        if (completed === 0) return 0;
        if (total === null) return 0;
        const progress = Math.floor((completed * 100) / total);

        return progress;
    };
    const timeElapsed = Date.now();

    const today = new Date(timeElapsed);

    const page = [{ title: props.title, active: true }];

    const getBadge = (data) => {
        if (data.quizResult.length === 0) {
            return (
                <div className="col-4 text-center">
                    <span className="badge badge-warning">Not Completed</span>
                </div>
            );
        }
        const percent = (data.quizResult.point * 100) / data.quizResult.total;
        const point = data.quizResult.point + '/' + data.quizResult.total;
        return (
            <div className="col-4 text-center">
                <span
                    className={
                        'badge ' +
                        (percent >= 80 ? 'badge-success' : percent <= 50 ? 'badge-danger' : 'badge-primary')
                    }
                >
                    {point}
                </span>
            </div>
        );
    };

    const resetMyQuiz = (data) => {
        swalCustomButtons
            .fire({
                title: 'Do you want to restart the quiz?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const response = await dispatch(resetQuiz(data));
                    const responseData = unwrapResult(response);
                    if (responseData.success) {
                        setFetch(!fetch);
                        swalWithBootstrapButtons.fire({
                            title: 'Restart Successfully!',
                            icon: 'success',
                        })
                    } else {
                        swalWithBootstrapButtons.fire({
                            title: responseData.message,
                            icon: 'error',
                        });
                    }
                    console.log(responseData);

                }
            })
    }

    const MessagePreload = () => {
        const [show, setShow] = useState(true);
        useEffect(() => {
            const timer = setTimeout(() => {
                setShow(false);
            }, 250);
            return () => clearTimeout(timer);
        }, [show]);
        if (!show) {
            return null;
        }
        return <Preloader />;
    };

    return (
        <Helmet title={page}>
            <Breadcrumb page={page} />
            <PageTitle title={props.title} />
            {course && course.data && course.data.length > 0 ? (
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table shadow" >
                                <div className='bg-primary rounded-top card flex-row justify-content-between mb-1'>
                                    <h5 className="pl-2 text-white mb-0 p-2" style={{ width: '400px' }}>Course</h5>
                                    <h5 className="pl-2 text-white mb-0 ml-0 ml-md-3 ml-lg-4 p-2" style={{ width: '500px' }}>Quiz</h5>
                                    {location.pathname === "/student-take-quiz" ? (
                                        <>
                                            <h5 className="pl-2 text-white text-center mb-0 p-2" style={{ width: '120px' }}>Question</h5>
                                            <h5 className="pl-2 text-white text-center mb-0 p-2" style={{ width: '120px' }}>Completed</h5>
                                        </>
                                    ) : location.pathname === "/student-quiz-results" ? (
                                        <>
                                            <h5 className="pl-2 text-white text-center mb-0 ml-1 ml-md-3 ml-lg-5 p-2" style={{ width: '120px' }}>Point</h5>
                                            <h5 className="pl-2 text-white text-center mb-0 ml-2 ml-md-0 mr-4 mr-md-3 mr-lg-2 mr-xl-0 p-2" style={{ width: '120px' }}>Result</h5>
                                        </>
                                    ) : " "
                                    }
                                </div>

                            </div>
                        </div>
                        <div className='col-md-12'>
                            {course.data.map((data, key) => {
                                if (data.quiz) {
                                    const progress = calculateProgress(data.quiz.length, data.completed);
                                    return (
                                        <div className='card d-flex flex-column shadow rounded-lg mb-5' key={key}>
                                            <div className='d-flex flex-row justify-content-between align-items-center px-2 py-4'>
                                                <div className='d-flex flex-column flex-lg-row align-items-lg-center' style={{ width: '400px' }}>
                                                    <p className='mb-0'>
                                                        <img
                                                            src={data.image_thumbnail}
                                                            alt="Card image cap"
                                                            width={90}
                                                            height={50}
                                                            className="rounded"
                                                        />
                                                    </p>
                                                    <span className="ml-0 ml-lg-2">
                                                        <p className="h4 card-title m-0 title-display-inline text-capitalize">{data.name}</p>
                                                        <p className="text-muted mb-0">
                                                            submitted on {data.created_at ? data.created_at : today.toDateString()}
                                                        </p>
                                                    </span>
                                                </div>
                                                <div className='' style={{ width: '500px' }}>
                                                    <span className='d-flex flex-column justify-content-between'>
                                                        {data.quiz.map((dataQuiz, key) => (
                                                            location.pathname === "/student-take-quiz" ? (
                                                                <Link
                                                                    to={`${location.pathname}/${data.slug}/${dataQuiz.id}`}
                                                                    className={`text-decoration-none title-display-inline mb-0 text-dark font-weight-bold mb-2 ${dataQuiz.quizResult && dataQuiz.quizResult.answers ? 'disabled-link' : ''} ${dataQuiz.questions && dataQuiz.questions != 0 ? '' : 'disabled-link'}`}
                                                                    key={key}
                                                                >
                                                                    {key + 1}. {dataQuiz.title}
                                                                </Link>
                                                            ) : location.pathname === "/student-quiz-results" ? (
                                                                <Link
                                                                    to={`${location.pathname}/${data.slug}/${dataQuiz.id}`}
                                                                    className={`text-decoration-none title-display-inline mb-0 text-dark font-weight-bold mb-2 ${dataQuiz.quizResult && dataQuiz.quizResult.answers ? '' : 'disabled-link'}`}
                                                                    key={key}
                                                                >
                                                                    {key + 1}. {dataQuiz.title}
                                                                </Link>
                                                            ) : " "
                                                        ))}
                                                    </span>
                                                </div>
                                                {location.pathname === "/student-take-quiz" ? (
                                                    <div className='mr-4 mr-sm-0' style={{ width: '140px' }}>
                                                        <span className='d-flex flex-column justify-content-between'>
                                                            {data.quiz.map((dataQuiz, key) => (
                                                                <p className="text-dark text-center mb-2 font-weight-bold" key={key}>
                                                                    {dataQuiz.questions}
                                                                </p>
                                                            ))}
                                                        </span>
                                                    </div>
                                                ) : location.pathname === "/student-quiz-results" ? (
                                                    <div className='mr-2 mr-sm-0' style={{ width: '140px' }}>
                                                        <span className='d-flex flex-column justify-content-between'>
                                                            {data.quiz.map((dataQuiz, key) => (
                                                                <p className="text-dark text-center mb-2 font-weight-bold" key={key}>
                                                                    {dataQuiz.quizResult.point > 0 ? dataQuiz.quizResult.point : 0}
                                                                </p>
                                                            ))}
                                                        </span>
                                                    </div>
                                                ) : " "
                                                }

                                                {location.pathname === "/student-take-quiz" ? (
                                                    <div className='mr-4 mr-md-0' style={{ width: '100px' }}>
                                                        <span className='d-flex flex-column justify-content-between align-items-center'>
                                                            {data.quiz.map((dataQuiz, key) => (
                                                                dataQuiz.quizResult && dataQuiz.quizResult.answers ? (
                                                                    <i className='bx bx-check-circle text-success font-size-25pt mb-2' key={key}></i>
                                                                ) : (
                                                                    <i className='bx bx-play-circle  text-primary font-size-25pt mb-2' key={key}></i>
                                                                )
                                                            ))}
                                                        </span>
                                                    </div>
                                                ) : location.pathname === "/student-quiz-results" ? (
                                                    <div style={{ width: '80px' }}>
                                                        <span className='d-flex flex-column justify-content-between align-items-center'>
                                                            {data.quiz.map((dataQuiz, key) => (
                                                                <span key={key} className="mb-2">
                                                                    {getBadge(dataQuiz)}
                                                                </span>
                                                            ))}
                                                        </span>
                                                    </div>) : " "
                                                }
                                            </div>
                                            <div className='p-2 rounded-lg' style={{ backgroundColor: "#E5E5F2" }}>
                                                <div>
                                                    <span className="d-flex justify-content-end align-items-center">
                                                        <p className="mb-0 mr-2 font-weight-bold">
                                                            {'Lessons: ' + data.completed + ' of ' + data.quiz.length}
                                                        </p>
                                                        {progress && progress > 0 ? (
                                                            <button className="btn btn-white btn-sm mb-0" onClick={() => { resetMyQuiz(data.quiz.map((dataQuiz) => (dataQuiz.id))) }}>
                                                                Restart&nbsp;
                                                                <i className="material-icons btn__icon--right">replay</i>
                                                            </button>
                                                        ) : progress === 0 ? (
                                                            <button className="btn btn-white btn-sm mb-0" disabled>
                                                                Restart&nbsp;
                                                                <i className="material-icons btn__icon--right">replay</i>
                                                            </button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <Pagination
                        links={course.links}
                        handleSubmitUrl={handleSubmitUrl}
                        currentPage={course.current_page}
                    />
                </>
            ) : (
                <>
                    <MessagePreload />
                    <h2 className="text-center my-5 text-capitalize">You don't have any quizzes yet!</h2>
                </>
            )
            }
        </Helmet >
    );
}
export default QuizViewAll;

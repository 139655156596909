import React from 'react';
import { Link } from 'react-router-dom';
import { RedirectHome } from '~/helpers/Hook';
import Card from './Card';
import Filter from './filter/Filter';

function Breadcrumb(props) {
    const page = props.page ? props.page : '';
    const subscription = props.subscription ? props.subscription : false;
    const button = props.button ? props.button : '';
    const buttonLink = props.buttonLink ? props.buttonLink : '';
    const dataFilter = props.dataFilter ? props.dataFilter : '';
    const classDropdown = props.classDropdown ? props.classDropdown : '';
    return (
        <ol className="breadcrumb">
            <li className="breadcrumb-item">
                <Link to={RedirectHome()}>Home</Link>
            </li>
            {page &&
                page.map((val, key) => {
                    if (val.active) {
                        return (
                            <li key={key} className="breadcrumb-item active">
                                {val.title}
                            </li>
                        );
                    } else {
                        return (
                            <li className="breadcrumb-item" key={key}>
                                <Link to={val.url}>{val.title}</Link>
                            </li>
                        );
                    }
                })}
        </ol>
    );
}

export default Breadcrumb;

import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import Helmet from '~/components/Helmet';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'react-nestable/dist/styles/index.css';
import Nestable from 'react-nestable';
import ReactPlayer from 'react-player';
import CourseLessonAdd from './CourseLessonAdd';
import { useDispatch, useSelector } from 'react-redux';
import { destroy, get, reIndexLesson, updateCourse } from '~/features/ManageCourses/coursesManageSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import EditorComponent from '~/components/plugin/Editor';
import { useForm } from 'react-hook-form';
import PageTitle from '~/components/page/PageTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaEditCourse } from '~/helpers/validationSchema';

function CourseEdit(props) {
    const dataState = useSelector((state) => state.categories);

    const params = useParams();

    const [course, setCourse] = useState('');

    const dispatch = useDispatch();

    const [fetch, setFetch] = useState(false);

    const [data, setData] = useState('');

    const [popup, setPopup] = useState(false);

    const [video, setVideo] = useState('');

    const [thumbnail, setThumbnail] = useState('');

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ mode: 'onChange', resolver: yupResolver(schemaEditCourse) });

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    });

    const swalCustomButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mr-2',
            denyButton: 'btn btn-danger mr-2',
        },
        buttonsStyling: false,
    });

    useEffect(() => {
        if (params.slug) {
            getCourse({ slug: params.slug });
        }
    }, [fetch]);

    const getCourse = async (data) => {
        const response = await dispatch(get(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            setCourse(responseData.data);
        }
    };

    const reIndexData = async (data) => {
        let lessonIndex = {};
        data.items.map((val, key) => {
            lessonIndex[val.id] = key + 1;
        });
        const response = await dispatch(reIndexLesson(lessonIndex));
        const responseData = unwrapResult(response);

        if (responseData.success) {
            setFetch(!fetch);
        }
    };

    register('image', {
        onChange: (e) => {
            setThumbnail(URL.createObjectURL(e.target.files[0]));
        },
    });

    const deleteLesson = (data) => {
        swalCustomButtons
            .fire({
                title: 'Do you want to delete the lesson?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const response = await dispatch(destroy(data));
                    const responseData = unwrapResult(response);
                    if (responseData.success) {
                        setFetch(!fetch);
                    }
                }
            });
    };

    const onSubmit = async (data) => {
        const response = await dispatch(updateCourse(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            swalWithBootstrapButtons.fire({
                title: 'Save Successfully!',
                icon: 'success',
            });
        } else {
            swalWithBootstrapButtons.fire({
                title: responseData.message,
                icon: 'error',
            });
        }
    };

    const renderItem = (props) => {
        const { item, handler } = props;
        return (
            <div className="d-flex align-items-start justify-content-start">
                <div>{handler}</div>
                <div className="media align-items-center justify-content-between w-100 border rounded p-2 bg-white">
                    <div className="media-body">
                        <h5 className="card-title h6 mb-0">
                            <a
                                onClick={() => {
                                    setPopup(true);
                                    setData(item);
                                }}
                            >
                                {item.name}
                            </a>
                        </h5>
                        <small className="text-muted">{item.updated}</small>
                    </div>
                    <div className="media-right">
                        <span
                            onClick={() => {
                                setPopup(true);
                                setData(item);
                            }}
                            className="btn btn-primary btn-sm"
                        >
                            <i className="material-icons">edit</i>
                        </span>
                    </div>
                    <div className="media-right">
                        <span
                            onClick={() => {
                                deleteLesson(item.id);
                            }}
                            className="btn btn-danger btn-sm"
                        >
                            <i className="material-icons">delete</i>
                        </span>
                    </div>
                </div>
            </div>
        );
    };
    const Handler = () => {
        return (
            <div className="border rounded mr-2 bg-white" role="button">
                <i className="material-icons py-2 px-1">menu</i>
            </div>
        );
    };

    const page = [{ title: params.slug ? 'Edit Course' : 'Add Course', active: true }];

    return (
        <Helmet title="Edit Course">
            <Breadcrumb page={page} />
            <PageTitle
                title={params.slug ? 'Edit Course' : 'Add Course'}
                button={'save'}
                handleSubmit={handleSubmit(onSubmit)}
                submit={true}
            />
            {course && (
                <form>
                    <div className="row">
                        <div className="col-md-8">
                            <Card title={'Basic Information'} classHeader={'header'}>
                                <div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="title">
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                            name="name"
                                            defaultValue={course.name ? course.name : ''}
                                            {...register('name')}
                                        />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                        {course && (
                                            <input
                                                type="hidden"
                                                className="form-control"
                                                defaultValue={course.id}
                                                {...register('id')}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="title">
                                            Thumbnail
                                        </label>
                                        <div className="media-body">
                                            <div className="custom-file w-100">
                                                <input
                                                    type="file"
                                                    className={`custom-file-input ${errors.image ? 'is-invalid' : ''}`}
                                                    name="image"
                                                    accept="image/*"
                                                    {...register('image')}
                                                />
                                                <label
                                                    htmlFor="avatar"
                                                    className="custom-file-label title-display-inline"
                                                >
                                                    Choose file
                                                </label>
                                                <div className="invalid-feedback">{errors.image?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="price">
                                            Price
                                        </label>
                                        <div className="input-group input-group-merge rounded">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                                                name="price"
                                                defaultValue={course.price ? course.price : ''}
                                                {...register('price')}
                                            />
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <span className="bx bx-dollar" />
                                                </div>
                                            </div>
                                            <div className="invalid-feedback">{errors.price?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-0" style={{ height: '290px' }}>
                                        <label className="form-label">Description</label>
                                        <EditorComponent
                                            description={course.description ? course.description : ''}
                                            setValue={setValue}
                                        />
                                    </div>
                                </div>
                            </Card>
                            {course && (
                                <Card title={'Lessons'} classHeader={'header'}>
                                    <span
                                        onClick={() => {
                                            setPopup(true);
                                        }}
                                        className="btn btn-primary mb-4"
                                    >
                                        Add Lesson <i className="material-icons ml-1">add</i>
                                    </span>
                                    <div className={dataState.loading ? 'is-disabled' : ''}>
                                        <Nestable
                                            onChange={(items) => {
                                                reIndexData(items);
                                            }}
                                            items={course.lessons}
                                            renderItem={renderItem}
                                            handler={<Handler />}
                                            maxDepth={1}
                                        />
                                    </div>
                                </Card>
                            )}
                        </div>
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9 mb-0">
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    autoPlay
                                    playing={true}
                                    className="embed-responsive-item"
                                    url={video ? video : course.demo_video ? course.demo_video : ''}
                                    light={thumbnail ? thumbnail : course.image_thumbnail ? course.image_thumbnail : ''}
                                />
                            </div>
                            <Card>
                                <input
                                    type="text"
                                    className={`form-control ${errors.video ? 'is-invalid' : ''}`}
                                    defaultValue={course.demo_video ? course.demo_video : ''}
                                    {...register('video')}
                                    onChange={(e) => {
                                        setVideo(e.target.value);
                                        setValue('video', e.target.value, { shouldValidate: true });
                                    }}
                                />
                                <div className="invalid-feedback">{errors.video?.message}</div>
                            </Card>
                            <Card classHeader={'header'} title={'Meta'} subtitle={'Extra Options'}>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="category">
                                        Category
                                    </label>
                                    <select
                                        className={`custom-select form-control ${errors.select ? 'is-invalid' : ''}`}
                                        {...register('category')}
                                        name="category"
                                        defaultValue={course.category_id ? course.category_id : ''}
                                    >
                                        <option value="">Choose Category</option>
                                        {dataState.current &&
                                            dataState.current.map((val, key) => {
                                                return (
                                                    <option key={key} value={val.id}>
                                                        {val.name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                    <div className="invalid-feedback">{errors.select?.message}</div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </form>
            )}

            {course && (
                <CourseLessonAdd
                    setFetch={() => setFetch(!fetch)}
                    data={data}
                    handlerClickSetData={() => setData()}
                    handlerClickPopup={() => setPopup()}
                    display={popup}
                    courseId={course.id}
                    index={course.lessons.length + 1}
                />
            )}
        </Helmet>
    );
}
export default CourseEdit;

import React from 'react';
import { Link } from 'react-router-dom';
import { logout } from '~/features/Auth/userSlice';
import { useDispatch } from 'react-redux';

import { useAuth } from '~/routes/AuthProvider';

function DropDown(props) {
    const dispatch = useDispatch();
    const auth = useAuth();

    const onLogout = () => {
        dispatch(logout());
        auth.signout();
    };

    return (
        <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="/edit-account">
                <i className="material-icons">edit</i> Edit Account
            </Link>
            <Link className="dropdown-item" to="/account-information">
                <i className="material-icons">person</i> Basic Information
            </Link>
            {auth.user.roles &&
                auth.user.roles.map((value, index) => {
                    if (value.name === 'admin') {
                        return (
                            <Link key={index} className="dropdown-item" to="/manager-account">
                                <i className="material-icons">dashboard</i> Manager Account
                            </Link>
                        );
                    }
                })}
            <Link to="/landing" className="dropdown-item" role="button" onClick={() => onLogout()}>
                <i className="material-icons">lock</i> Logout
            </Link>
        </div>
    );
}

export default DropDown;

import StorageKeys from '../constants/storage-keys';
import axiosClient from './axiosClient';

const quizzesApi = {
    async getQuizzes(data) {
        const accessToken = await localStorage.getItem(StorageKeys.access);

        let url = 'api/showquiz';

        if (data) url = 'api/showquiz' + data;

        const response = await axiosClient.post(
            url,
            {},
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async getQuiz(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/getquiz';
        const response = await axiosClient.post(url, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    },

    async getQuestions(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/getquiz/questions';
        const response = await axiosClient.post(url, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    },

    async updateQuizResult(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/update/quizresult';
        const response = await axiosClient.post(url, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    },

    async getQuestionsResult(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/get/questionsresult';
        const response = await axiosClient.post(url, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    },
    async resetQuiz(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/quizzesmanager/restartquiz';
        const response = await axiosClient.post(url, { id: data }, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    },
};

export default quizzesApi;

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { StudentNavbarData } from '~/assets/data/NavbarData';
import { InstructorNavbarData } from '~/assets/data/NavbarData';
import { useAuth } from '~/routes/AuthProvider';

function Navbar(props) {
    const pathname = useLocation().pathname;
    const [navbar, setNavbar] = useState([]);
    const auth = useAuth();

    useEffect(() => {
        checkRole();
    }, [navbar, localStorage.getItem('isDashBoard')]);

    const checkRole = () => {
        let isStudent = false;
        let isInstructor = false;
        let isDashBoard = localStorage.getItem('isDashBoard');
        if (auth.user) {
            auth.user.roles.map((item) => {
                if (item.name === "student") isStudent = true;
                else if (item.name === "Instructor") isInstructor = true;
            })
            if (isInstructor && isStudent) {
                if (isDashBoard && isDashBoard === "Instructor") {
                    setNavbar(InstructorNavbarData);
                } else if (isDashBoard && isDashBoard === "Student") {
                    setNavbar(StudentNavbarData);
                } else {
                    setNavbar(StudentNavbarData);
                }
            }
            if (isInstructor && !isStudent) {
                setNavbar(InstructorNavbarData);
            }
            if (isStudent && !isInstructor) {
                setNavbar(StudentNavbarData);
            }
            if (!isStudent && !isInstructor) {
                setNavbar(StudentNavbarData);
            }
        }
    }

    return (
        <div className="row z-0">
            <div className="container">
                <div className="row">
                    <ul className="col-12 d-flex flex-row tab p-0">
                        {navbar && navbar.map((item, index) => {
                            return (
                                <li className={`p-0 dropdown-item${(pathname === item.url ? ' active' : '')}`} key={index} >
                                    <Link className={`nav-link px-4 py-2 ${(pathname === item.url ? 'active' : '')}`} to={item.hasOwnProperty('url') ? item.url : ''} style={{ maxWidth: "210px", minWidth: "190px" }}>
                                        <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">
                                            {item.icon}
                                        </i>
                                        {item.label}
                                    </Link>
                                </li>
                            )
                        }
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Navbar;

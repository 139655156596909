import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '~/components/Breadcrumb';
import Helmet from '~/components/Helmet';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaResetPass } from '~/helpers/validationSchema';
import { updatePassword } from '~/features/Auth/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { inputResetPassword } from '~/assets/data/dataInput';
import Input from '~/components/Input';
import PageTitle from '~/components/page/PageTitle';

function InformationAccount(props) {
    const dataState = useSelector((state) => state.user);

    const [dataUser, setDataUser] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        setDataUser(dataState.current);
    }, [dataUser]);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaResetPass),
    });

    const onSubmit = async (data) => {
        try {
            const reps = await dispatch(updatePassword(data));

            const dataReps = unwrapResult(reps);
            if (dataReps.success) {
                swalWithBootstrapButtons.fire({
                    title: 'Save Successfully!',
                    icon: 'success',
                });
            } else {
                swalWithBootstrapButtons.fire({
                    title: dataReps.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const page = [{ title: 'Basic Information', active: true }];

    return (
        <Helmet title="Information Account">
            <div className="row m-0">
                <div className="col-lg-11 container-fluid page__container">
                    <Breadcrumb page={page} />
                    <PageTitle title={'Basic Information'} />
                    <div className="card">
                        <div className="list-group list-group-fit">
                            <div className="list-group-item border-0 mt-3">
                                <div className="form-group m-0" role="group" aria-labelledby="label-lastname">
                                    <div className="form-row">
                                        <div className="col-md-1" />
                                        <label
                                            id="label-lastname"
                                            htmlFor="lastname"
                                            className="col-md-2 col-form-label font-weight-bold text-capitalize"
                                        >
                                            Full name
                                        </label>
                                        <div className="col-md-8">
                                            <div role="group" className="input-group input-group-merge">
                                                <input
                                                    id="lastname"
                                                    type="text"
                                                    placeholder="Your last name"
                                                    defaultValue={dataUser.name}
                                                    className="form-control"
                                                    style={{ backgroundColor: '#f5f6f700' }}
                                                    disabled
                                                />
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <i className="material-icons">people</i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-group-item border-0">
                                <div className="form-group m-0" role="group" aria-labelledby="label-email">
                                    <div className="form-row">
                                        <div className="col-md-1" />
                                        <label
                                            id="label-email"
                                            htmlFor="email"
                                            className="col-md-2 col-form-label font-weight-bold text-capitalize"
                                        >
                                            Your email address
                                        </label>
                                        <div className="col-md-8">
                                            <div role="group" className="input-group input-group-merge">
                                                <input
                                                    id="email"
                                                    type="email"
                                                    placeholder="Your email address"
                                                    defaultValue={dataUser.email}
                                                    className="form-control"
                                                    style={{ backgroundColor: '#f5f6f700' }}
                                                    disabled
                                                />
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <i className="material-icons">email</i>
                                                    </div>
                                                </div>
                                            </div>
                                            <small className="form-text text-muted">
                                                Note that if you change your email, you will have to confirm it again.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h4 className='card-title mx-3 my-3'>Change Password</h4>
                        <div className="alert alert-light border-1 border-left-3 border-primary d-flex align-items-center mx-auto w-75">
                            <i className="material-icons text-success mr-3">check_circle</i>
                            <div className="text-body">
                                An email with password reset instructions has been sent to your email address, if it exists
                                on our system.
                            </div>
                        </div>
                        <form>
                            <div className="list-group list-group-fit mb-md-0">
                                <div className="list-group-item">
                                    {inputResetPassword.map((data, key) => {
                                        return (
                                            <div className="form-row align-items-center pb-lg-12pt" key={key}>
                                                <div className="col-md-1" />
                                                <label
                                                    id={data.name}
                                                    htmlFor={data.name}
                                                    className="col-md-2 mb-0 mb-lg-3 col-font-weight-bold col-form-label font-weight-bold text-capitalize"
                                                >
                                                    {data.label}
                                                </label>
                                                <div className="col-md-8">
                                                    <Input
                                                        type={data.type}
                                                        name={data.name}
                                                        placeholder={data.placeholder}
                                                        register={register}
                                                        icon={data.icon}
                                                        valid={errors.hasOwnProperty(data.name) ? 'is-invalid' : ''}
                                                        message={errors[data.name]?.message}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </form>
                        <button
                            type="submit"
                            className="btn btn-primary mx-auto mb-4 d-block text-capitalize px-5"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Save changes
                        </button>
                    </div>
                </div>
            </div>
        </Helmet>
    );
}

export default InformationAccount;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Carousel from 'better-react-carousel';
import { VideoData, DataRequirements, DataTarget } from '~/assets/data/landingPageData';
import { getAllCourses, getCoursesGuest } from '~/features/Courses/courseSlice';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import ReactPlayer from 'react-player';
import tick from '~/assets/images/logo/tick.svg';
import plus from '~/assets/images/logo/plus-icon.svg';
import $ from 'jquery';
import HeaderLanding from './HeaderLanding';
import FooterLanding from './FooterLanding';
import Preloader from '~/components/Preloader';

export default function LandingCourse() {
    const params = useParams();

    const [course, setCourses] = useState('');

    const [courseCarousel, setCourseCarousel] = useState('');

    const dispatch = useDispatch();

    const prevLocation = params.slug;

    const fetchCoursesBanner = async (data) => {
        const response = await dispatch(getAllCourses(data));
        const responseData = unwrapResult(response);
        const course = responseData.data;
        if (responseData.success) if (course.data.length > 0) setCourseCarousel(course.data.slice(1, 9));
    };

    const fetchCourses = async (params) => {
        const response = await dispatch(getCoursesGuest(params));
        const responseData = unwrapResult(response);
        if (responseData.success) setCourses(responseData.data);
    };

    useEffect(() => {
        fetchCoursesBanner();
        if (params.slug) {
            fetchCourses(`?slug=${params.slug}`);
        }
    }, [params]);

    $('.mdk-header__content').on('click', '.search-toggle', function (e) {
        var selector = $(this).data('selector');

        $(selector).toggleClass('show').find('.search-input').trigger();
        $(this).toggleClass('active');

        e.preventDefault();
    });

    return (
        <>
            {!course && <Preloader/>}
            <HeaderLanding />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-7">
                        <div className="d-flex flex-column">
                            <div className="w-full">
                                {course && (
                                    <>
                                        <h1 className="mt-5 mb-5 font-weight-bolder" style={{ fontSize: '28px' }}>
                                            {course.name}
                                        </h1>
                                        <div className="text-sm text-break" style={{ color: 'rgba(0,0,0,.8)' }}>
                                            <p dangerouslySetInnerHTML={{ __html: course.description }} />
                                        </div>
                                    </>
                                )}
                                <section className="my-4">
                                    <h3 className="mb-4 font-weight-bolder" style={{ fontSize: '20px' }}>
                                        What will you learn?
                                    </h3>
                                    <ul
                                        className="w-full list-unstyled"
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
                                        }}
                                    >
                                        {DataTarget.map((dataList, key) => {
                                            return (
                                                <li
                                                    key={key}
                                                    className="d-flex items-start pr-xl-4 pr-md-3 pr-lg-2 align-items-center"
                                                >
                                                    <img src={tick} className="svg mr-1" alt="tick" />
                                                    <p className="text-sm ml-1 my-1" style={{ color: '#333' }}>
                                                        {dataList.text}
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </section>
                                <section className="my-4">
                                    <div className="pb-1" style={{ top: '66px' }}>
                                        <h3 className="mb-4 font-weight-bolder" style={{ fontSize: '20px' }}>
                                            Course content
                                        </h3>
                                        <div className="d-md-flex d-flex flex-wrap align-items-center justify-content-between ">
                                            <ul
                                                className="d-md-flex d-flex text-sm align-items-center list-unstyled mb-0"
                                                style={{ paddingInlineStart: '0px' }}
                                            >
                                                <div className="d-md-flex d-flex">
                                                    <div className="d-md-flex d-flex align-items-center">
                                                        <li>
                                                            <span>
                                                                Level: {course.level === 1 ? 'Beginner' : 'Advanced'}
                                                            </span>
                                                        </li>
                                                        <li className="mx-1">
                                                            <svg
                                                                stroke="currentColor"
                                                                fill="currentColor"
                                                                strokeWidth={0}
                                                                viewBox="0 0 16 16"
                                                                height="1em"
                                                                width="1em"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="text-sm"
                                                            >
                                                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                            </svg>
                                                        </li>
                                                    </div>
                                                    <li>
                                                        <span>Category: {course.category_name}</span>
                                                    </li>
                                                    <li className="mx-1">
                                                        <svg
                                                            stroke="currentColor"
                                                            fill="currentColor"
                                                            strokeWidth={0}
                                                            viewBox="0 0 16 16"
                                                            height="1em"
                                                            width="1em"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="text-sm"
                                                        >
                                                            <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                        </svg>
                                                    </li>
                                                    <li>
                                                        <span>Instructor: {course.instructor_name}</span>
                                                    </li>
                                                </div>
                                            </ul>
                                            {/* <a
                                                    className="d-md-flex d-flex text-decoration-none"
                                                    data-toggle="collapse"
                                                    data-target=".multi-collapse"
                                                    aria-expanded="false"
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    Expand all
                                                </a> */}
                                        </div>
                                    </div>
                                    <ul className="w-full mt-3 list-unstyled card p-2" style={{ paddingInlineStart: '0px' }}>
                                        {course &&
                                            course.lessons.map((dataLesson, key) => {
                                                return (
                                                    <li
                                                        key={key}
                                                        className="mb-2"
                                                        data-toggle="collapse"
                                                        data-target={`#${dataLesson.index_value}`}
                                                    >
                                                        <div
                                                            className="d-md-flex d-flex flex-row align-items-baseline justify-content-between p-2"

                                                        >
                                                            <div className="flex align-items-center">
                                                                <div className="mr-2 d-inline-flex align-items-center">
                                                                    <img
                                                                        src={plus}
                                                                        className="svg m-1"
                                                                        alt="plus"
                                                                        style={{ height: '10px' }}
                                                                    />
                                                                    <p
                                                                        className="text-sm m-1 font-weight-bold"
                                                                        style={{
                                                                            color: '#333',
                                                                            fontSize: '14px',
                                                                            lineHeight: '1.5',
                                                                        }}
                                                                    >
                                                                        {dataLesson.name}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {/* <p
                                                                className="text-sm font-weight-bold mb-0"
                                                                style={{
                                                                    color: '#333',
                                                                    fontSize: '14px',
                                                                    lineHeight: '1.5',
                                                                }}
                                                            >
                                                                {dataLesson.lesson}
                                                            </p> */}
                                                        </div>

                                                        {/* <ul
                                                            className="w-full collapse multi-collapse list-unstyled"
                                                            style={{ paddingInlineStart: '0px' }}
                                                            id={dataLesson.id}
                                                        >
                                                            {dataLesson.lessonList.map(
                                                                (dataListLesson, key) => {
                                                                    return (
                                                                        <li key={key}>
                                                                            <div
                                                                                className="d-md-flex d-flex align-items-center justify-content-between rounded p-2"
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    border: '1px solid rgba(0,0,0,.03)',
                                                                                }}
                                                                            >
                                                                                <div className="d-md-flex d-flex align-items-center">
                                                                                    <svg
                                                                                        stroke="currentColor"
                                                                                        fill="currentColor"
                                                                                        strokeWidth={0}
                                                                                        viewBox="0 0 512 512"
                                                                                        className="text-sm mr-[6px] text-primary opacity-50"
                                                                                        height="1em"
                                                                                        width="1em"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z" />
                                                                                    </svg>
                                                                                    <p
                                                                                        className="text-sm ml-2 mb-0 font-weight-bold"
                                                                                        style={{
                                                                                            color: '#333',
                                                                                            fontSize: '14px',
                                                                                            lineHeight: '1.5',
                                                                                        }}
                                                                                    >
                                                                                        {dataListLesson.text}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="text-sm">
                                                                                    {dataListLesson.time}
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                },
                                                            )}
                                                        </ul> */}
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </section>

                                <section className="my-4">
                                    <h3 className="mb-4 font-weight-bolder" style={{ fontSize: '20px' }}>
                                        Requirement
                                    </h3>
                                    <ul className="w-full list-unstyled" style={{ paddingInlineStart: '0px' }}>
                                        {DataRequirements.map((dataRequiredList, key) => {
                                            return (
                                                <li key={key} className="d-md-flex d-flex items-start pr-6 mb-3">
                                                    <img src={tick} className="svg mr-1" alt="tick" />
                                                    <p
                                                        className="text-sm font-weight-bold mb-0 ml-1"
                                                        style={{
                                                            color: '#333',
                                                            fontSize: '14px',
                                                            fontWeight: '500',
                                                            lineHeight: '1.5',
                                                        }}
                                                    >
                                                        {dataRequiredList.text}
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <section className="d-md-flex d-flex align-items-center flex-column mt-5">
                            <div className="embed-responsive embed-responsive-16by9 mb-0">
                                {course && (
                                    <ReactPlayer
                                        width="100%"
                                        height="100%"
                                        autoPlay={false}
                                        playing={false}
                                        className="embed-responsive-item"
                                        url={course.demo_video}
                                        light={course.image_thumbnail}
                                    />
                                )}
                            </div>
                            <div className="mt-4 d-md-flex d-flex">
                                <button className="btn btn-danger">
                                    <Link to={`/login?redirect=${prevLocation}`} className="text-decoration-none text-white">
                                        COURSE REGISTRATION
                                    </Link>
                                </button>
                            </div>
                            <ul className="mt-8 mx-auto list-unstyled" style={{ paddingInlineStart: '0px' }}>
                                {VideoData.map((data, key) => {
                                    return (
                                        <li
                                            key={key}
                                            className="d-md-flex d-flex align-items-center m-xl-3 m-md-2 m-lg-2 m-3"
                                            style={{ color: '#494949' }}
                                        >
                                            <div className="text-left" style={{ minWidth: '35px' }}>
                                                {data.icon}
                                            </div>
                                            <span className="text-sm text-xl-center text-lg-center">{data.title}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </section>
                    </div>
                </div>

                <div className="row mb-5 mb-lg-0">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                        <h2 className="mt-4 mb-5 mb-4 font-weight-bolder" style={{ fontSize: '28px' }}>
                            Related Courses
                        </h2>
                        <div className="d-block mx-auto">
                            {courseCarousel && (
                                <Carousel
                                    cols={3}
                                    rows={1}
                                    gap={10}
                                    loop
                                    responsiveLayout={[
                                        { breakpoint: 1280, cols: 2, rows: 1, gap: 10, loop: true },
                                        { breakpoint: 992, cols: 1, rows: 1, gap: 10, loop: true },
                                        { breakpoint: 768, cols: 1, rows: 1, gap: 57, loop: true, autoplay: 4500 },
                                        { breakpoint: 576, cols: 1, rows: 1, gap: 52, loop: true, autoplay: 4500 },
                                        { breakpoint: 500, cols: 1, rows: 1, gap: 37, loop: true, autoplay: 4500 },
                                        { breakpoint: 400, cols: 1, rows: 1, gap: 32, loop: true, autoplay: 4500 },
                                    ]}
                                    mobileBreakpoint={768}
                                >
                                    {courseCarousel.map((data, key) => {
                                        const path = `/landing/${data.slug}`;
                                        return (
                                            <Carousel.Item key={key}>
                                                <Link className="text-decoration-none text-white" to={path}>
                                                    <div className="slide-item h-50 d-flex flex-row flex-wrap align-items-center">
                                                        <img
                                                            src={data.image_thumbnail}
                                                            className="bg-primary"
                                                            alt="slide"
                                                            style={{
                                                                height: '233px',
                                                                width: '100%',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                        <div className="slide-footer d-none d-lg-block w-100 position-relative">
                                                            <p className="title-display-inline text-center text-uppercase mb-0">
                                                                {data.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Carousel.Item>
                                        );
                                    })}
                                </Carousel>
                            )}
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div>
            <FooterLanding />
        </>
    );
}

import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import Helmet from '~/components/Helmet';
import Filter from '~/components/filter/Filter';
import Preloader from '~/components/Preloader';
import Pagination from '~/components/Pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '~/components/page/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { show } from '~/features/ManageCourses/coursesManageSlice';
import { useAuth } from '~/routes/AuthProvider';
import { useForm } from 'react-hook-form';

export default function QuizManager(props) {
    const [course, setCourses] = useState('');

    const location = useLocation();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { register, handleSubmit } = useForm();

    const auth = useAuth();

    const dataState = useSelector((state) => state.categories);

    useEffect(() => {
        getData(location.search);
    }, [location]);

    const onSubmit = (data) => {
        const params = new URLSearchParams(location.search);

        params.set('search', data.search);

        params.set('page', 1);

        navigate('?' + params.toString(), { replace: true });
    };

    const getData = async (dataSearch) => {
        if (auth.token) {
            let isInstructor = false;
            auth.user.roles.map((data, key) => {
                if (data.name == 'Instructor') {
                    isInstructor = true;
                }
            });
            if (isInstructor) {
                const response = await dispatch(show(dataSearch));
                const responseData = unwrapResult(response);
                if (responseData.success) {
                    setCourses(responseData.data);
                }
            }
        }
    };

    const handleSubmitUrl = (url) => {
        let paramString = url.split('=')[1];

        const params = new URLSearchParams(location.search);

        params.set('page', paramString);

        navigate('?' + params.toString(), { replace: true });
    };
    const page = [{ title: 'Quiz Manager', active: true }];

    const dataFilter = [
        {
            title: 'categories',
            options: dataState.current,
        },
    ];

    const MessagePreload = () => {
        const [show, setShow] = useState(true);
        useEffect(() => {
            const timer = setTimeout(() => {
                setShow(false);
            }, 300);
            return () => clearTimeout(timer);
        }, [show]);
        if (!show) {
            return null;
        }
        return <Preloader />;
    };

    return (
        <Helmet title="Quiz Manager">
            <Breadcrumb page={page} />
            <PageTitle
                title={'Quiz Manager'}
            />
            <Card cardClass={'card-body border-left-3 border-left-primary navbar-shadow mb-4 p-0'}>
                <div className="d-flex flex-wrap2 align-items-center mb-headings">
                    <Filter titleFilter={'Filters'} dataFilter={dataFilter} />
                    <form className="flex search-form ml-3 search-form--light" onSubmit={handleSubmit(onSubmit)}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            {...register('search')}
                        />
                        <button className="btn" type="submit">
                            <i className="material-icons">search</i>
                        </button>
                    </form>
                </div>
                <form className="d-flex flex-column flex-sm-row align-items-sm-center" style={{ whiteSpace: 'nowrap' }}>
                    {course.data &&
                        <small className="flex text-muted text-uppercase mr-3 mb-2 mb-sm-0">
                            Displaying {course.data.length} out of {course.total} courses
                        </small>
                    }
                </form>
            </Card>
            {course.data && course.data.length > 0 ?
                (
                    <>
                        <div className="card-columns">
                            {course.data.map((item, key) => {
                                const pathEditUrl = '/instructor-quiz-manager/quiz-edit/' + item.slug;
                                const pathReviewUrl = '/instructor-quiz-manager/quiz-review/' + item.slug;
                                return (
                                    <Card
                                        cardClass={'card-sm'}
                                        classBody={'media'}
                                        classFooter={'text-center'}
                                        footer={
                                            <>
                                                <Link to={pathReviewUrl} className="btn btn-white btn-sm float-left">
                                                    <i className="material-icons btn__icon--left">playlist_add_check</i> Review{' '}
                                                    <span className="badge badge-dark ml-2">{item.inReview}</span>
                                                </Link>
                                                <Link to={pathEditUrl} className="btn btn-default btn-sm float-right">
                                                    <i className="material-icons btn__icon--left">edit</i> Edit
                                                </Link>
                                                <div className="clearfix" />
                                            </>
                                        }
                                        key={key}
                                    >
                                        <>
                                            <div className="media-left">
                                                <Link to={pathReviewUrl} className="avatar avatar-lg avatar-4by3">
                                                    <img
                                                        src={item.image_thumbnail}
                                                        alt="Card image cap"
                                                        className="avatar-img rounded"
                                                    />
                                                </Link>
                                            </div>
                                            <div className="media-body">
                                                <h4 className="card-title mb-0 title-display-inline">
                                                    <Link to={pathReviewUrl}>{item.name}</Link>
                                                </h4>
                                                <small className="mr-2 badge badge-primary ">{item.category_name}</small>
                                            </div>
                                        </>
                                    </Card>
                                );
                            })}
                        </div>
                        <div className="col-12">
                            <Pagination links={course.links} handleSubmitUrl={handleSubmitUrl} currentPage={course.current_page} />
                        </div>
                    </>
                ) : (
                    <>
                        <MessagePreload />
                        <h2 className="text-center text-capitalize">The quiz you are looking for does not currently exist!</h2>
                    </>
                )
            }
        </Helmet>
    );
}

import React, { useEffect, useState } from 'react';
import Alert from '../Alert';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Link, useLocation } from 'react-router-dom';
import Card from '../Card';
import { destroyItem, fetchCart } from '~/features/Cart/cartSlice';

function MiniCart(props) {
    const pathname = useLocation().pathname;

    const dataState = useSelector((state) => state.cart);

    const [dataItems, setDataItems] = useState('');

    const dispatch = useDispatch();

    const deleteItem = async (data) => {
        const response = await dispatch(destroyItem({ course_id: data }));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            const response = await dispatch(fetchCart());
            const responseData = unwrapResult(response);
        } else {
            console.log('Error! An error occurred. Please try again later!');
        }
    };

    useEffect(() => {
        setDataItems(dataState.current);
    }, [dataState]);

    return (
        <>
            <button
                className="nav-link btn-flush dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                data-dropdown-disable-document-scroll
                data-caret="false"
            >
                <i className="bx bx-cart-alt font-size-24pt"></i>
                <span className="badge badge-notifications badge-danger">{dataItems ? dataItems.length : ''}</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
                <div data-perfect-scrollbar className="position-relative">
                    <Card title={'Shopping Cart'} classHeader={'d-flex align-items-center'} cardClass={'mb-0'} classBody={'p-0 overflow-auto scroll'}>
                        <div className="list-group list-group-flush mb-0" style={{ maxHeight: '270px' }}>
                            <ul className="list-group list-group-fit mb-0" style={{ zIndex: 'initial' }}>
                                {dataItems && dataItems.length ? (
                                    dataItems.map((item, key) => {
                                        const patch_url = '/student-view-course/' + item.slug;
                                        return (
                                            <li key={key} className="list-group-item">
                                                <div className="d-flex align-items-center">
                                                    <Link to={patch_url} className="avatar avatar-4by3 avatar-sm mr-3">
                                                        <img
                                                            src={item.image_thumbnail}
                                                            alt="course"
                                                            className="avatar-img rounded"
                                                        />
                                                    </Link>
                                                    <div className="flex">
                                                        <Link to={patch_url} className="text-body">
                                                            <strong>{item.name}</strong>
                                                        </Link>
                                                    </div>
                                                    <span className="text-muted pb-4" role="button" onClick={() => deleteItem(item.id)}>
                                                        <i className="material-icons font-size-24pt">close</i>
                                                    </span>
                                                </div>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <Alert
                                        display={true}
                                        message="You have no items in your shopping cart"
                                        alertClass={'warning text-center text-capitalize font-weight-bold'}
                                    ></Alert>
                                )}
                            </ul>
                        </div>
                    </Card>
                    <div
                        className={
                            'card-footer bg-white d-flex justify-content-center ' +
                            (pathname === '/my-cart' ? 'd-none' : '')
                        }
                    >
                        <Link className="btn btn-outline-primary btn-md" to="/my-cart">
                            Go to cart
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MiniCart;

import StorageKeys from '../constants/storage-keys';
import axiosClient from './axiosClient';

const categoriesApi = {
    async index(data) {
        let url = 'api/categories/index';
        const response = await axiosClient.get(url);
        return response.data;
    },
};

export default categoriesApi;

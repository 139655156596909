import userReducer from '~/features/Auth/userSlice';
import cartReducer from '~/features/Cart/cartSlice';
import coursesReducer from '~/features/ManageCourses/coursesManageSlice';
import categoriesReducer from '~/features/Categories/categoriesSlice';

const { configureStore } = require('@reduxjs/toolkit');

const rootReducer = {
    user: userReducer,
    cart: cartReducer,
    courses: coursesReducer,
    categories: categoriesReducer,
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;

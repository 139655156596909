import React from 'react';
import { Link } from 'react-router-dom';
import Filter from '../filter/Filter';

function PageTitle(props) {
    const title = props.title ? props.title : '';
    const button = props.button ? props.button : '';
    const buttonLink = props.buttonLink ? props.buttonLink : '';
    const dataFilter = props.dataFilter ? props.dataFilter : '';
    const classDropdown = props.classDropdown ? props.classDropdown : '';
    const submit = props.submit ? props.submit : false;

    return (
        <div>
            {button || dataFilter ? (
                <div className="d-flex flex-row flex-wrap mb-headings align-items-start align-items-sm-center">
                    <div className="flex mb-2 mb-sm-0">
                        <h1 className="h2">{title}</h1>
                    </div>
                    {buttonLink && (
                        <Link to={buttonLink} className={dataFilter ? 'btn btn-primary mr-2' : 'btn btn-primary'}>
                            {button}
                        </Link>
                    )}

                    {dataFilter && <Filter dataFilter={dataFilter} classDropdown={classDropdown} />}

                    {submit && (
                        <div className="page-nav__content" onClick={props.handleSubmit}>
                            <button className="btn btn-primary text-capitalize">{button}</button>
                        </div>
                    )}
                </div>
            ) : (
                <h1 className="h2 text-capitalize">
                    {title}
                </h1>
            )}
        </div>
    );
}

export default PageTitle;

import StorageKeys from '../constants/storage-keys';
import axiosClient from './axiosClient';

const coursesManageApi = {
    async show(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);

        let url = 'api/coursesmanager/show';

        if (data) url = url + data;

        const response = await axiosClient.post(
            url,
            {},
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async get(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/coursesmanager/get';
        const response = await axiosClient.post(url, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    },

    async destroyCourse(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/coursesmanager/destroycourse';
        const response = await axiosClient.post(
            url,
            { id: data },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async destroy(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/coursesmanager/destroylesson';
        const response = await axiosClient.post(
            url,
            { id: data },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async updateLesson(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/coursesmanager/updatelesson';

        let formdata = new FormData();
        if (data.id) {
            formdata.append('id', data.id);
        }
        if (data.image[0]) {
            formdata.append('image_thumbnail', data.image[0]);
        }
        if (data.name) {
            formdata.append('name', data.name);
        }
        if (data.description) {
            formdata.append('description', data.description);
        }
        if (data.video) {
            formdata.append('video', data.video);
        }

        if (data.courses_id) {
            formdata.append('courses_id', data.courses_id);
        }

        formdata.append('index_value', data.index_value);

        const response = await axiosClient.post(url, formdata, {
            headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    },

    async reIndexLesson(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/coursesmanager/reindexlesson';

        const response = await axiosClient.post(
            url,
            { indexed: data },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );

        return response.data;
    },

    async updateCourse(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/coursesmanager/updatecourse';

        let formdata = new FormData();
        if (data.id) {
            formdata.append('id', data.id);
        }
        if (data.image[0]) {
            formdata.append('image_thumbnail', data.image[0]);
        }
        if (data.name) {
            formdata.append('name', data.name);
        }
        if (data.description) {
            formdata.append('description', data.description);
        }
        if (data.video) {
            formdata.append('video', data.video);
        }

        if (data.price) {
            formdata.append('price', data.price);
        }

        if (data.category) {
            formdata.append('category_id', data.category);
        }

        const response = await axiosClient.post(url, formdata, {
            headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    },
};

export default coursesManageApi;

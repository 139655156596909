export const inputLogin = [
    {
        label: 'Your email address:',
        type: 'text',
        name: 'email',
        placeholder: 'Email',
        icon: 'far fa-envelope',
    },
    {
        label: 'Your password:',
        type: 'password',
        name: 'password',
        placeholder: 'Your email address',
        icon: 'fa fa-key',
    },
];

export const inputSignup = [
    {
        label: 'Name:',
        type: 'text',
        name: 'name',
        placeholder: 'Name',
        icon: 'far fa-user',
    },
    {
        label: 'Email:',
        type: 'text',
        name: 'email',
        placeholder: 'Your email address',
        icon: 'far fa-envelope',
    },
    {
        label: 'Password:',
        type: 'password',
        name: 'password',
        placeholder: 'Password',
        icon: 'fa fa-key',
    },
    {
        label: 'Confirm password:',
        type: 'password',
        name: 'confirmPassword',
        placeholder: 'Confirm password',
        icon: 'fa fa-key',
    },
];

export const inputResetPassword = [
    {
        label: 'New Password:',
        type: 'password',
        name: 'password',
        placeholder: 'Password',
        icon: 'fa fa-key',
    },
    {
        label: 'Confirm password:',
        type: 'password',
        name: 'confirmPassword',
        placeholder: 'Confirm password',
        icon: 'fa fa-key',
    },
];

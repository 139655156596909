import Breadcrumb from '~/components/Breadcrumb';
import Card from '~/components/Card';
import Helmet from '~/components/Helmet';
import Filter from '~/components/filter/Filter';
import Pagination from '~/components/Pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { show, destroyCourse } from '~/features/ManageCourses/coursesManageSlice';
import { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAuth } from '~/routes/AuthProvider';
import { useState } from 'react';
import PageTitle from '~/components/page/PageTitle';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useForm } from 'react-hook-form';
import Preloader from '~/components/Preloader';

function CourseManager(props) {
    const dispatch = useDispatch();

    const [fetch, setFetch] = useState(false);

    const { register, handleSubmit } = useForm();

    const location = useLocation();

    const navigate = useNavigate();

    const auth = useAuth();

    const [courses, setCourses] = useState('');

    const page = [{ title: 'Manage Courses', active: true }];

    const dataState = useSelector((state) => state.categories);

    const dataFilter = [
        {
            title: 'categories',
            options: dataState.current,
        },
    ];

    const onSubmit = (data) => {
        const params = new URLSearchParams(location.search);

        params.set('search', data.search);

        params.set('page', 1);

        navigate('?' + params.toString(), { replace: true });
    };

    useEffect(() => {
        getData(location.search);
    }, [fetch, location]);

    const swalCustomButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mr-2',
            denyButton: 'btn btn-danger mr-2',
        },
        buttonsStyling: false,
    });

    const getData = async (dataSearch) => {
        if (auth.token) {
            let isInstructor = false;
            auth.user.roles.map((data, key) => {
                if (data.name == 'Instructor') {
                    isInstructor = true;
                }
            });
            if (isInstructor) {
                const response = await dispatch(show(dataSearch));
                const responseData = unwrapResult(response);
                if (responseData.success) {
                    setCourses(responseData.data);
                }
            }
        }
    };

    const deleteCourse = (data) => {
        swalCustomButtons
            .fire({
                title: 'Do you want to delete the course?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const response = await dispatch(destroyCourse(data));
                    const responseData = unwrapResult(response);
                    if (responseData.success) {
                        setFetch(!fetch);
                        navigate('/instructor-courses-manager', {replace: true})
                    }
                }
            });
    };

    const handleSubmitUrl = (url) => {
        let paramString = url.split('=')[1];

        const params = new URLSearchParams(location.search);

        params.set('page', paramString);

        navigate('?' + params.toString(), { replace: true });
    };

    const MessagePreload = () => {
        const [show, setShow] = useState(true);
        useEffect(() => {
            const timer = setTimeout(() => {
                setShow(false);
            }, 300);
            return () => clearTimeout(timer);
        }, [show]);
        if (!show) {
            return null;
        }
        return <Preloader />;
    };

    return (
        <Helmet title="Courses Manager">
            <Breadcrumb page={page} />
            <PageTitle
                title={'Courses Manager'}
                page={'Courses Manager'}
                subscription={false}
                button={'Add Course'}
                buttonLink={'course-add'}
            />
            <Card cardClass={'card-body border-left-3 border-left-primary navbar-shadow mb-4 p-0'}>
                <div className="d-flex flex-wrap2 align-items-center mb-headings">
                    <Filter titleFilter={'Filters'} dataFilter={dataFilter} />
                    <form className="flex search-form ml-3 search-form--light" onSubmit={handleSubmit(onSubmit)}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search courses"
                            {...register('search')}
                        />
                        <button className="btn" type="submit">
                            <i className="material-icons">search</i>
                        </button>
                    </form>
                </div>
                <form className="d-flex flex-column flex-sm-row align-items-sm-center" style={{ whiteSpace: 'nowrap' }}>
                    {courses.data &&
                        <small className="flex text-muted text-uppercase mr-3 mb-2 mb-sm-0">
                            Displaying {courses.data.length} out of {courses.total} courses
                        </small>
                    }
                </form>
            </Card>
            {courses && courses.data.length > 0 ?
                (
                    <div className="row">
                        {courses.data.map((item, key) => {
                            const path_url = '/instructor-courses-manager/' + item.slug;
                            return (
                                <div className="col-md-6 col-lg-6 col-xl-4" key={key}>
                                    <Card>
                                        <div className="d-flex flex-column flex-sm-row">
                                            <Link
                                                to={path_url}
                                                className="avatar h-md-100 w-64 mb-3 w-xs-plus-down-100 mr-sm-3"
                                            >
                                                <img
                                                    src={item.image_thumbnail}
                                                    alt="card course cap"
                                                    className="avatar-img rounded border p-1"
                                                    style={{ minWidth: '70px', minHeight: '46px', container: 'cover' }}
                                                />
                                            </Link>
                                            <div className="flex" style={{ minWidth: '200px' }}>
                                                <h4 className="card-title mb-1 title-display-inline">
                                                    <Link to={path_url}>{item.name}</Link>
                                                </h4>
                                                <p
                                                    className="text-black-70 description-display-inline"
                                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                                />
                                                <div className="d-flex align-items-end">
                                                    <div className="d-flex flex flex-column mr-3">
                                                        <div className="d-flex align-items-center py-1">
                                                            <small className="mr-2 text-uppercase badge badge-primary ">
                                                                {item.category_name}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <Link to={path_url} className="btn btn-sm btn-white">
                                                            Edit
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card__options dropdown right-0 pr-0 pr-lg-2">
                                            <a className="dropdown text-muted" data-toggle="dropdown">
                                                <i className="material-icons" role="button">more_vert</i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <Link className="dropdown-item" to={path_url}>
                                                    Edit course
                                                </Link>
                                                <span
                                                    className="dropdown-item text-danger"
                                                    role="button"
                                                    onClick={() => {
                                                        deleteCourse(item.id);
                                                    }}
                                                >
                                                    Delete course
                                                </span>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            )
                        })}
                        <div className="col-12">
                            <Pagination
                                links={courses.links}
                                handleSubmitUrl={handleSubmitUrl}
                                currentPage={courses.current_page}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <MessagePreload />
                        <h2 className="text-center my-5 col-md-12 text-capitalize">The course you are looking for does not currently exist!</h2>
                    </>
                )
            }
        </Helmet>
    );
}
export default CourseManager;

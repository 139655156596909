import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Helmet from '~/components/Helmet';
import { useDispatch } from 'react-redux';
import { getQuestions, updateQuizResult } from '~/features/Quiz/quizSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import QuizGroupCard from '~/components/student/QuizGroupCard';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { RedirectHome } from '~/helpers/Hook';

function TakeQuiz(props) {
    const params = useParams();
    const [quiz, setQuiz] = useState('');
    const [index, setIndex] = useState(0);
    const [delayTime, setDelayTime] = useState('');
    const [result, setResult] = useState({ corect: 0, wrong: 0, left: 0, answers: '', point: 0, total: 0 });
    const [date, setDate] = useState({ date: Date.now() });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 3000,
    });

    const fetchQuestions = async (data) => {
        const response = await dispatch(getQuestions(data));
        const responseData = unwrapResult(response);
        setQuiz(responseData.data.questions);
        setDelayTime(responseData.data.time);
    };

    const handleClickLeft = () => {
        setResult({
            ...result,
            left: result.left + 1,
            answers: [...result.answers, { questions: quiz[index].id, corect: false }],
            total: result.total + quiz[index].point,
        });
        setIndex(index + 1);
    };
    const handleClickSubmit = (val) => {
        const answerQuiz = quiz[index].answers;
        let corect = false;
        answerQuiz.forEach(function (answer, answerKey) {
            if (val == answerKey) {
                if (answer.result) {
                    corect = true;
                }
            }
        });
        if (corect) {
            setResult({
                ...result,
                corect: result.corect + 1,
                answers: [...result.answers, { questions: quiz[index].id, answers: val, corect: corect }],
                point: result.point + quiz[index].point,
                total: result.total + quiz[index].point,
            });
            setIndex(index + 1);
        } else {
            setResult({
                ...result,
                wrong: result.wrong + 1,
                answers: [...result.answers, { questions: quiz[index].id, answers: val, corect: corect }],
                total: result.total + quiz[index].point,
            });
            setIndex(index + 1);
        }
        console.log(result);
    };

    //------------------------------------------------------------
    const updateResult = async (data) => {
        const response = await dispatch(updateQuizResult(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            return true;
        }
    };

    useEffect(() => {
        if (params.slug && params.quizid) {
            const data = {
                slug: params.slug,
                quiz_id: params.quizid,
            };
            fetchQuestions(data);
        }
        if (quiz && index >= quiz.length) {
            const data = {
                quiz_id: params.quizid,
                result: result,
                point: 100,
            };
            const check = updateResult(data);

            if (check) {
                swalWithBootstrapButtons
                    .fire({
                        title: 'Good job!',
                        icon: 'success',
                    })
                navigate(`/student-quiz-results/${params.slug}/${params.quizid}`);
            }
        }
    }, [index]);

    const Completed = () => <span className="h3 text-primary ml-4 mr-4">You are good to go!</span>;

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Completed />;
        } else {
            return (
                <>
                    <div className="sidebar-heading">Time left</div>
                    <div className="countdown sidebar-p-x">
                        <p className="pl-1 pr-1">
                            <span className="h4 text-primary">{hours}</span> hrs
                            <span className="h4 text-primary"> {minutes}</span> min
                            <span className="h4 text-primary"> {seconds}</span> sec
                        </p>
                    </div>
                </>
            );
        }
    };
    return (
        <Helmet title="Do a quiz">
            <div className="container-fluid page__container">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to={RedirectHome()}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Quiz</li>
                </ol>
                <div className="row">
                    {quiz && (
                        <QuizGroupCard
                            quiz={quiz[index]}
                            total={quiz.length}
                            index={index}
                            result={result}
                            handleClickLeft={() => handleClickLeft()}
                            handleClickSubmit={handleClickSubmit}
                        />
                    )}
                    <div className="col-4 d-none d-lg-block" style={{ backgroundColor: '#fff' }}>
                        <div className="sidebar sidebar-right">
                            <div className={'sidebar-p-y '}>
                                {delayTime && <Countdown date={date.date + parseInt(delayTime, 10) * 1000} renderer={renderer} />}
                                <div className="sidebar-heading">Pending</div>
                                <ul className="list-group list-group-fit custome-scroll-bar">
                                    {quiz &&
                                        quiz.map((data, key) => {
                                            return (
                                                <li
                                                    className={'list-group-item' + (index == key ? ' active' : '')}
                                                    key={key}
                                                >
                                                    <span className="media align-items-center">
                                                        <span className="media-left">
                                                            <span className="btn btn-white btn-circle">
                                                                #{key + 1}
                                                            </span>
                                                        </span>
                                                        <span className="media-body">{data.title}</span>
                                                    </span>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Helmet>
    );
}

export default TakeQuiz;

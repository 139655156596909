import FormQuestion from '~/components/quizManager/FormQuestion';
import { schemaAddQuestion, schemaEditQuestion } from '~/helpers/validationSchema';

export default function QuizQuestionAdd(props) {

    const question = props.data ? props.data : null;

    const quizId = props.quizId ? props.quizId : null;

    return (
        <>
            {props.display && (
                <div id="overlay">
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '700px' }}>
                        <div className="modal-content">
                            <div className="modal-header bg-primary">
                                <h4 className="modal-title text-white">{props.data ? 'Edit Question' : 'Add Question'}</h4>
                                <button
                                    type="button"
                                    className="close text-white"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        props.handlerClickPopup(false);
                                        props.handlerClickSetData(null);
                                    }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {question && (
                                <FormQuestion
                                    quizId={quizId}
                                    id={question.id}
                                    name={question.title}
                                    answers={question.answers}
                                    index={question.index_value}
                                    point={question.point}
                                    handlerClickPopup={() => props.handlerClickPopup(false)}
                                    handlerClickSetData={() => props.handlerClickSetData(null)}
                                    action="update"
                                    schema={schemaEditQuestion}
                                    setFetch={() => props.setFetch()}
                                />
                            )}

                            {!question && (
                                <FormQuestion
                                    quizId={quizId}
                                    handlerClickPopup={() => props.handlerClickPopup(false)}
                                    handlerClickSetData={() => props.handlerClickSetData(null)}
                                    action="add"
                                    index={props.index}
                                    schema={schemaAddQuestion}
                                    setFetch={() => props.setFetch()}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

import StorageKeys from '../constants/storage-keys';
import axiosClient from './axiosClient';

const coursesApi = {
    async getAllCourses(data) {
        let url = 'api/courses';
        if (data) url = url + data;
        const response = await axiosClient.post(url);
        return response.data;
    },

    async getLandingCourses(data) {
        let url = 'api/getlandingcourses';
        if (data) url = url + data;
        const response = await axiosClient.get(url);
        return response.data;
    },

    async getCoursesGuest(data) {
        let url = 'api/getCoursesGuest';

        if (data) url = url + data;

        const response = await axiosClient.get(url);

        return response.data;
    },

    async getCourse(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/getcourse';
        const response = await axiosClient.post(url, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    },
    async getMyCourses(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);

        let url = 'api/mycourses';

        if (data) url = url + data;

        const response = await axiosClient.post(
            url,
            {},
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },

    async lessonCompleted(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/lesson-completed';
        const response = await axiosClient.post(
            url,
            { lesson_id: data },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
        );
        return response.data;
    },
    async resetCourse(data) {
        const accessToken = localStorage.getItem(StorageKeys.access);
        const url = 'api/coursesmanager/restartcourse';
        const response = await axiosClient.post(url, { id: data }, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    },
};

export default coursesApi;

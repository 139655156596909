import React, { useEffect, useState } from 'react';
import { formatTime } from '~/utils/numberWithCommas';

function LessonItems(props) {
    const [next, setNext] = useState(0);
   
    const lesson = props.lesson ? props.lesson : [];
   
    const active = props.active ? props.active : '';
  
    useEffect(() => {
        lesson.map((data, index) => {
            if (data.status) {
                setNext(index + 1);
            }
        });
    }, [lesson]);
  
    return (
        <ul className="card list-group list-group-fit h-100 overflow-auto scroll">
            {lesson &&
                lesson.map((data, key) => {
                    return (
                        <li className={'list-group-item' + (active === key + 1 ? ' active ' : ' ')} key={key}>
                            <div className="media">
                                <div className="media-left">
                                    <div className="text-muted">{key + 1}.</div>
                                </div>
                                {data.video && (
                                    <>
                                        {next < key ? (
                                            <>
                                                <div className="media-body">
                                                    <small className="text-muted-light">{data.name}</small>
                                                </div>
                                                <div className="media-right">
                                                    <small className="text-muted-light">
                                                        <i className="material-icons" style={{ fontSize: '14px' }}>
                                                            lock
                                                        </i>
                                                    </small>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="media-body">
                                                    <a
                                                        href="#"
                                                        className={active === key + 1 ? 'text-white ' : ' '}
                                                        onClick={() => props.handelClick(data.video, data.id, key + 1)}
                                                    >
                                                        {data.name}
                                                    </a>
                                                </div>
                                                {next === key ? (
                                                    <div className="media-right">
                                                        <small className="btn-primary">
                                                            <i className="material-icons" style={{ fontSize: '14px' }}>
                                                                play_circle_outline
                                                            </i>
                                                        </small>
                                                    </div>
                                                ) : (
                                                    <div className="media-right">
                                                        <small className="btn-success">
                                                            <i className="material-icons" style={{ fontSize: '14px' }}>
                                                                check
                                                            </i>
                                                        </small>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                {!data.video && (
                                    <>
                                        <div className="media-body">
                                            <small className="text-muted-light">{data.name}</small>
                                        </div>
                                        <div className="media-right">
                                            <small className="text-muted-light">
                                                <i className="material-icons" style={{ fontSize: '14px' }}>
                                                    lock
                                                </i>
                                            </small>
                                        </div>
                                    </>
                                )}
                            </div>
                        </li>
                    );
                })}
        </ul>
    );
}

export default LessonItems;

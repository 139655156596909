import React from 'react';

import { useNavigate, Outlet } from 'react-router-dom';

import { useAuth } from '~/routes/AuthProvider';

const RequiredAuth = ({ children }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!auth.user) {
            navigate('/landing', { replace: true });
        }
    }, []);

    return children ? children : <Outlet />;
};

export default RequiredAuth;

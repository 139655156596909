import React from 'react';

function Pagination(props) {
    const links = props.links ? props.links : '';
    const currentPage = props.currentPage ? props.currentPage : '';
    const handleSubmitUrl = props.handleSubmitUrl;

    const renderLinks = (data, label, url, active) => {
        if (data == '&laquo; Previous') {
            return (
                <li className={`page-item ${!url ? ('disabled') : ('')}`}>
                    <a className="page-link py-0" type="button" onClick={() => handleSubmitUrl(url)}>
                        <span aria-hidden="true" className="material-icons py-1">
                            chevron_left
                        </span>
                        <span>Prev</span>
                    </a>
                </li>)
        } else if (data < currentPage + 2 && data > currentPage - 2) {
            return (
                <li className={`page-item ${active ? ('active') : ('')}`}>
                    <a className="page-link" type="button" onClick={() => handleSubmitUrl(url)}>
                        {label}
                    </a>
                </li>)
        } else if (data == currentPage + 2) {
            return (
                <li className="page-item">
                    <a className="page-link">
                        ...
                    </a>
                </li>
            )
        } else if (data == 'Next &raquo;') {
            return (
                <li className={`page-item ${!url ? ('disabled') : ('')}`}>
                    <a className="page-link py-0" type="button" onClick={() => handleSubmitUrl(url)}>
                        <span>Next</span>
                        <span aria-hidden="true" className="material-icons py-1">
                            chevron_right
                        </span>
                    </a>
                </li>
            );
        }
    }

    return (
        <ul className="pagination justify-content-center pagination-sm">
            {links && links.map((data, key) => {
                return <div key={key} >{renderLinks(data.label, data.label, data.url, data.active)}</div>;
            })}
        </ul>
    );
}

export default Pagination;

import React, { useState } from 'react';
import Helmet from '~/components/Helmet';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaRegister } from '~/helpers/validationSchema';
import Input from '~/components/Input';
import { useDispatch } from 'react-redux';
import { signup } from '~/features/Auth/userSlice';
import { useNavigate } from 'react-router-dom';
import AuthHeader from '~/components/auth/AuthHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { inputSignup } from '~/assets/data/dataInput';
import { useAuth } from '~/routes/AuthProvider';
import { unwrapResult } from '@reduxjs/toolkit';
import Button from '~/components/Button';
import Alert from '~/components/Alert';
import LoginGoogle from '~/components/auth/LoginGoogle';

function SignUp() {
    const [loading, setLoading] = useState(false);

    const [message, setMessage] = useState({ message: '', success: true });

    const dispatch = useDispatch();

    const location = useLocation();

    let navigate = useNavigate();

    const auth = useAuth();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        toast: true,
        position: 'top-right',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 3000,
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaRegister),
    });

    const handlerRedirect = () => {
        const params = new URLSearchParams(location.search);
        const redirectTo = params.get('redirect');
        navigate(redirectTo == null ? "/" : `/student-view-course/${redirectTo}`, { replace: true });
    }

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const response = await dispatch(signup(data));
            const responseData = unwrapResult(response);
            if (responseData.success) {
                setLoading(false);
                swalWithBootstrapButtons
                    .fire({
                        title: 'Register Successfully!',
                        icon: 'success',
                    })
                auth.signin(responseData.data);
                handlerRedirect()
            } else {
                setLoading(false);
                setMessage({
                    message: 'User already exists with this email.',
                    success: false,
                });
            }
        } catch (error) {
            setLoading(false);
            setMessage({
                message: 'Something Went Wrong!',
                success: false,
            });
        }
    };

    return (
        <Helmet title="Register">
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center min-vh-100">
                <div className="col-sm-8 col-md-5 col-lg-4">
                    <AuthHeader title="Sign Up" subtitle="Create a new account" description={"Already signed up?"} url={"login"} urlTitle={"Login"} />
                </div>
                <div className="col-sm-8 col-md-5 col-lg-4" style={{ minWidth: '300px' }}>
                    <LoginGoogle isLoginSignUp={true} />
                    <div className="card navbar-shadow">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    {inputSignup.map((data, index) => {
                                        return (
                                            <div key={index}>
                                                <Input
                                                    label={data.label}
                                                    type={data.type}
                                                    name={data.name}
                                                    placeholder={data.placeholder}
                                                    register={register}
                                                    icon={data.icon}
                                                    valid={errors.hasOwnProperty(data.name) ? 'is-invalid' : ''}
                                                    message={errors[data.name]?.message}
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className="form-group">
                                        <label className="form-label">Role:</label>
                                        <div className="input-group input-group-merge">
                                            <select className="form-control" name="role" {...register('role')}>
                                                <option defaultValue value="student">
                                                    Student
                                                </option>
                                                <option value="instructor">Instructor</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group text-left mb-0">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                id="terms"
                                                type="checkbox"
                                                name="acceptTerms"
                                                className={`form-check-input custom-control-input ${errors.acceptTerms ? 'is-invalid' : ''
                                                    }`}
                                                {...register('acceptTerms')}
                                            />
                                            <label htmlFor="terms" className="custom-control-label text-black-70">
                                                I agree to the{' '}
                                                <span className="text-black-70" style={{ textDecoration: 'underline' }}>
                                                    {' '}
                                                    Terms of Use{' '}
                                                </span>
                                            </label>
                                            <div className="invalid-feedback">{errors.acceptTerms?.message}</div>
                                        </div>
                                    </div>
                                    <br />
                                    <Alert
                                        display={message.message !== ''}
                                        message={message.message}
                                        alertClass={message.success ? 'success' : 'danger'}
                                    />
                                    <div className='w-50 mx-auto'>
                                        <div className="form-group text-center mb-0">
                                            <Button loading={loading}>Sign Up</Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Helmet>
    );
}
export default SignUp;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export const AuthContext = React.createContext(null);

export const useAuth = () => {
    return React.useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const dataState = useSelector((state) => state.user);
    const [user, setUser] = useState(dataState.current);
    const [token, setToken] = useState(localStorage.getItem('access_token'));

    const signin = (newUser, callback) => {
        setUser(newUser);
        setToken(localStorage.getItem('access_token'));
        if (callback) {
            callback();
        }
    };

    const singout = (callback) => {
        setUser(null);
        setToken('');
        if (callback) {
            callback();
        }
    };

    const value = { user, token, signin, singout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Carousel from 'better-react-carousel';
import Slider from 'react-slick';
import { unwrapResult } from '@reduxjs/toolkit';
import { getLandingCourses } from '~/features/Courses/courseSlice';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import HeaderLanding from './HeaderLanding';
import FooterLanding from './FooterLanding';
import { Card } from 'react-bootstrap';
import Pagination from '~/components/Pagination';
import Preloader from '~/components/Preloader';

function LandingPage() {
    const [course, setCourses] = useState('');

    const [courseBanner, setCourseBanner] = useState('');

    const [search, setSearch] = useState('');

    const navigate = useNavigate();

    const location = useLocation();

    const searchString = location.search.split('=')[1];

    const component = [
        {
            title: 'Recommended for you',
            data: course ? course.slice(8, 12) : [],
        },
        {
            title: 'Students are viewing',
            data: course ? course.slice(0, 8) : [],
        },
    ];

    const dispatch = useDispatch();

    const fetchNewCourses = async (data) => {
        const response = await dispatch(getLandingCourses(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            setCourseBanner(responseData.data.data.slice(1, 6));

            setCourses(responseData.data.data);
        }
    };

    const searchCourses = async (data) => {
        const response = await dispatch(getLandingCourses(data));
        const responseData = unwrapResult(response);
        if (responseData.success) {
            setSearch(responseData.data);
        }
    };

    const handleSubmitUrl = (url) => {
        let paramString = url.split('=')[1];

        const params = new URLSearchParams(location.search);

        params.set('page', paramString);

        navigate('?' + params.toString(), { replace: true });
    };

    useEffect(() => {
        fetchNewCourses();
        searchCourses(location.search);
    }, [location]);

    $('.mdk-header__content').on('click', '.search-toggle', function (e) {
        var selector = $(this).data('selector');

        $(selector).toggleClass('show').find('.search-input').trigger();
        $(this).toggleClass('active');

        e.preventDefault();
    });

    const settings = {
        autoplay: true,
        autoplaySpeed: 4500,
        infinite: true,
        pauseOnFocus: true,
        pauseOnHover: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            {!course && <Preloader />}
            <HeaderLanding />
            <div className="container-fluid">
                <div className="row bg-primary mt-3">
                    {courseBanner && (
                        <div className="col-md-12">
                            <Slider {...settings}>
                                {courseBanner.map((item, index) => {
                                    const path = `/landing/${item.slug}`;
                                    return (
                                        <div className="row d-flex bg-primary m-0" key={index}>
                                            <div className="col-lg-8">
                                                <div className="d-flex flex-column justify-content-end p-2 p-lg-5">
                                                    <h3 className="text-light">{item.name}</h3>
                                                    <p
                                                        className="text-light description-display-inline"
                                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                                    />
                                                    <Link to={path} className="btn btn-outline-light" style={{ width: "180px" }}>
                                                        Learn today
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 w-100">
                                                <img
                                                    src={item.image_thumbnail}
                                                    alt="banner cap"
                                                    className="w-100 py-2 py-md-3 py-lg-5 px-2 px-lg-4"
                                                    style={{ maxHeight: '350px', objectFit: 'cover' }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    )}
                </div>
                {!location.search &&
                    component &&
                    component.map((item, index) => {
                        return (
                            <div className="row mt-4" key={index}>
                                <div className="col-md-10 container-fluid px-2">
                                    <h2 className="font-weight-bolder font-size-32pt">{item.title}</h2>
                                    <div className="row">
                                        {item.data.map((item, index) => {
                                            const path = `/landing/${item.slug}`;
                                            return (
                                                <div className="col-md-6 col-lg-3 p-2" key={index}>
                                                    <div className="card rounded-xl">
                                                        <Link to={path}>
                                                            <img
                                                                src={item.image_thumbnail}
                                                                alt="course"
                                                                className="card-img-top rounded-xl-top bg-primary"
                                                            />
                                                        </Link>
                                                        <div className="card-body">
                                                            <h5 className="card-title text-capitalize title-display-inline">
                                                                {item.name}
                                                            </h5>
                                                            <Link to={path} className="btn btn-primary rounded-lg">
                                                                Learn more
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                {location.search && (
                    <>
                        <div className="row mt-4">
                            <div className="col-md-10 container-fluid px-2">
                                {search &&
                                    <h2 className="font-weight-bolder font-size-32pt">{search.data.length} Results for "{searchString}"</h2>
                                }
                                <div className="row">
                                    {search &&
                                        search.data.map((item, index) => {
                                            const path = `/landing/${item.slug}`;
                                            return (
                                                <div className="col-md-6 col-lg-3 p-2" key={index}>
                                                    <div className="card rounded-xl">
                                                        <Link to={path}>
                                                            <img
                                                                src={item.image_thumbnail}
                                                                alt="course"
                                                                className="card-img-top rounded-xl-top bg-primary"
                                                            />
                                                        </Link>
                                                        <div className="card-body">
                                                            <h5 className="card-title text-capitalize title-display-inline">
                                                                {item.name}
                                                            </h5>
                                                            <Link to={path} className="btn btn-primary rounded-lg">
                                                                Learn more
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                        {search.data && search.data.length === 0 && (
                            <Card>
                                <div className="row">
                                    <div
                                        className="alert alert-warning col-12 text-center text-capitalize"
                                        role="alert"
                                    >
                                        We can't find courses matching the selection.
                                    </div>
                                </div>
                            </Card>
                        )}
                        <Pagination
                            links={search.links}
                            handleSubmitUrl={handleSubmitUrl}
                            currentPage={search.current_page}
                        />
                    </>
                )}

                <div className="row mb-5 mb-lg-0">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                        <h2 className="mt-4 mb-5 mb-4 font-size-32pt font-weight-bolder">Related Courses</h2>
                        <div className="d-block mx-auto">
                            {course && (
                                <Carousel
                                    cols={4}
                                    rows={1}
                                    gap={10}
                                    loop
                                    responsiveLayout={[
                                        { breakpoint: 1280, cols: 2, rows: 1, gap: 10, loop: true },
                                        { breakpoint: 992, cols: 1, rows: 1, gap: 10, loop: true },
                                        { breakpoint: 768, cols: 1, rows: 1, gap: 57, loop: true, autoplay: 4500 },
                                        { breakpoint: 576, cols: 1, rows: 1, gap: 52, loop: true, autoplay: 4500 },
                                        { breakpoint: 500, cols: 1, rows: 1, gap: 37, loop: true, autoplay: 4500 },
                                        { breakpoint: 400, cols: 1, rows: 1, gap: 32, loop: true, autoplay: 4500 },
                                    ]}
                                    mobileBreakpoint={768}
                                >
                                    {course.map((data, key) => {
                                        const path = `/landing/${data.slug}`;
                                        return (
                                            <Carousel.Item key={key}>
                                                <Link className="text-decoration-none text-white" to={path}>
                                                    <div className="slide-item h-50 d-flex flex-row flex-wrap align-items-center">
                                                        <img
                                                            src={data.image_thumbnail}
                                                            className="bg-primary"
                                                            alt="slide"
                                                            style={{
                                                                height: '233px',
                                                                width: '100%',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                        <div className="slide-footer d-none d-lg-block w-100 position-relative">
                                                            <p className="title-display-inline text-center text-uppercase mb-0">
                                                                {data.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Carousel.Item>
                                        );
                                    })}
                                </Carousel>
                            )}
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div>
            <FooterLanding />
        </>
    );
}
export default LandingPage;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import coursesManageApi from '~/api/coursesManageApi';

export const show = createAsyncThunk('coursesManage/show', async (payload) => {
    try {
        const response = await coursesManageApi.show(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const get = createAsyncThunk('coursesManage/get', async (payload) => {
    try {
        const response = await coursesManageApi.get(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const destroyCourse = createAsyncThunk('coursesManage/destroy-course', async (payload) => {
    try {
        const response = await coursesManageApi.destroyCourse(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const destroy = createAsyncThunk('coursesManage/destroy', async (payload) => {
    try {
        const response = await coursesManageApi.destroy(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const updateLesson = createAsyncThunk('coursesManage/updatelesson', async (payload) => {
    try {
        const response = await coursesManageApi.updateLesson(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const reIndexLesson = createAsyncThunk('coursesManage/reindex', async (payload) => {
    try {
        const response = await coursesManageApi.reIndexLesson(payload);
        return response;
    } catch (e) {
        return e;
    }
});

export const updateCourse = createAsyncThunk('coursesManage/updatecourse', async (payload) => {
    try {
        const response = await coursesManageApi.updateCourse(payload);
        return response;
    } catch (e) {
        return e;
    }
});

const coursesManageSlice = createSlice({
    name: 'coursesManage',
    initialState: {
        current: null,
        settings: {},
        loading: false,
    },
    reducers: {},
    extraReducers: {
        [reIndexLesson.pending]: (state) => {
            state.loading = true;
        },
        [reIndexLesson.fulfilled]: (state) => {
            state.loading = false;
        },
        [reIndexLesson.rejected]: (state) => {
            state.loading = false;
        },
    },
});

const { actions, reducer } = coursesManageSlice;

export default reducer;

export const dataTransactions = [
    {
        courses: "Learn Angular Fundamentals",
        invoice: "#8737",
        price: "89",
        date: "15 Nov 2018",
        img: require('../../assets/images/courses/github.png'),
    },
    {
        courses: "Introduction to TypeScript",
        invoice: "#8736",
        price: "89",
        date: "14 Nov 2018",
        img: require('../../assets/images/courses/vuejs.png'),
    },
    {
        courses: "Angular Unit Testing",
        invoice: "#8735",
        price: "89",
        date: "13 Nov 2018",
        img: require('../../assets/images/courses/vuejs.png'),
    },
    {
        courses: "Angular Routing In-Depth",
        invoice: "#8734",
        price: "89",
        date: "12 Nov 2018",
        img: require('../../assets/images/courses/gulp.png'),
    },
]
export const dataSales = [
    {
        courses: "Basics of HTML",
        earning: "15"
    },
    {
        courses: "Angular in Steps",
        earning: "50"
    },
    {
        courses: "Bootstrap Foundations",
        earning: "14"
    },
    {
        courses: "GitHub Basics",
        earning: "10"
    },
]
export const dataSoftFilter = [
    {
        text: 'course',
        sort: 'course',
    },
    {
        text: 'document',
        sort: 'document',
    },
    {
        text: 'amount',
        sort: 'amount',
    },
    {
        text: 'date',
        sort: 'date',
    },
]
export const dataMessages = [
    {
        nameStudent: "Laza Bogdan",
        imageStudent: require('../../assets/images/people/50/no-data.png'),
        onCourses: "Learn Angular Fundamentals",
        message: "How can I load Charts on a page?",
        messageDate: "15 Nov 2018",
        nameInstructor: "Frontend Master",
        imageInstructor: require('../../assets/images/people/50/no-data.png'),
        messageDateInstructor: "15 Nov 2018",
        messageInstructor: "Hi Bogdan, Thank you for purchasing our course! Please have a look at the charts library documentation here and follow the instructions.",

    }
]